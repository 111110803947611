import React from "react"
import { PresenceType } from "../../backendServices/BackendServices"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { IconDoNotDisturb } from "../../ui/Icons"
import { EventType, usePresenceState } from "../../ui/PresenceIndicator"
import ToggleSwitch from "../../ui/ToggleSwitch"
import { SettingsAreaRow } from "../settings/SettingsTab"

interface DoNotDisturbToggleProps {}

export const DoNotDisturbToggle: React.FC<DoNotDisturbToggleProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const myPresence = usePresenceState()

    return <SettingsAreaRow {...props}>
        {IconDoNotDisturb({ fill: branding.sideIconBar.sideIconColorDark })}
        <p>{strings.communicationArea.silentChat}</p>
        <ToggleSwitch
            activeColor={branding.dangerButtonColor}
            isEnabled={myPresence.getMyPresence() === PresenceType.DONOTDISTURB}
            onClick={() => {
                myPresence.updateMyPresence(EventType.DONOTDISTURB_TOGGLE, myPresence.getMyPresence() !== PresenceType.DONOTDISTURB ? PresenceType.DONOTDISTURB : PresenceType.AVAILABLE);
                localStorage.setItem("presenceBeforeRefresh", myPresence.getMyPresence() !== PresenceType.DONOTDISTURB ? PresenceType.DONOTDISTURB : PresenceType.AVAILABLE)
            }} />
    </SettingsAreaRow >
}