import styled from "styled-components"
import React from "react"
import branding from "../branding/branding"
import { CategoryBadges } from "../backendServices/Types"

export const BadgeContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3px;
    margin-top: 10px;
`

interface BadgeProps {
    borderColor: string
    textColor: string
    backgroundColor: string
}
export const Badge = styled.div<BadgeProps>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 200px;
    font-size: 0.6rem;
    border: 1px solid;
    border-radius: 5px;
    margin-top: 3px;
    margin-right: 3px;
    padding-right: 3px;
    padding-left: 3px;
    border-color: ${(props) => (props.borderColor ? props.borderColor : "#000000")};
    color: ${(props) => (props.textColor ? props.textColor : "#000000")};
    background-color: ${(props) => (props.backgroundColor ? props.backgroundColor : "#FFFFFF")};
`

interface BadgesProps {
    topics?: string[]
    categoryBadges?: CategoryBadges[]
    otherBadges?: string[]
}
const BadgesContainer: React.FC<BadgesProps> = (props) => {
    const topicBadges = branding.topicBadges
    const categoryBadgesIds = branding.categoryBadges
    const categoryBadges = props.categoryBadges
    const topics = props.topics
    if (
        (topicBadges !== undefined && topicBadges.length > 0 && topics !== undefined && topics.length > 0) ||
        (categoryBadgesIds !== undefined && categoryBadgesIds.length > 0 && categoryBadges && categoryBadges.length > 0) ||
        (props.otherBadges !== undefined && props.otherBadges?.length! > 0)
    ) {
        return (
            <BadgeContainer>
                {topicBadges &&
                    topicBadges.length > 0 &&
                    topicBadges
                        .sort((topicBadge1, topicBadge2) => {
                            return topicBadge1.badgeText > topicBadge2.badgeText ? 1 : -1
                        })
                        .map((topicBadge) => {
                            return (
                                topics &&
                                topics.map((topicName: string) => {
                                    if (topicBadge.topicName === topicName) {
                                        return (
                                            <Badge
                                                key={topicName}
                                                textColor={branding.mainInfoColor ?? "black"}
                                                backgroundColor={topicBadge.backgroundColor}
                                                borderColor={branding.mainInfoColor ?? "black"}
                                            >
                                                {topicBadge.badgeText}
                                            </Badge>
                                        )
                                    }
                                    return null
                                })
                            )
                        })}
                {categoryBadges &&
                    categoryBadges.length > 0 &&
                    categoryBadges.map((categoryBadge) => {
                        if (categoryBadgesIds.indexOf(categoryBadge.id) > -1) {
                            return (
                                <Badge
                                    key={categoryBadge.id}
                                    textColor={categoryBadge.colorText ?? "black"}
                                    backgroundColor={categoryBadge.colorFill ?? "#fff"}
                                    borderColor={categoryBadge.colorBorder ?? "black"}
                                >
                                    {categoryBadge.badgeName}
                                </Badge>
                            )
                        }
                        return null
                    })}
                {props.otherBadges &&
                    props.otherBadges.map((badge) => {
                        return (
                            <Badge
                                key={badge}
                                textColor={branding.mainInfoColor ?? "black"}
                                backgroundColor="#fff"
                                borderColor={branding.mainInfoColor ?? "black"}
                            >
                                {badge}
                            </Badge>
                        )
                    })}
            </BadgeContainer>
        )
    } else {
        return null
    }
}

export default BadgesContainer
