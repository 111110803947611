import { createState, State, useState } from '@hookstate/core';
import { API, graphqlOperation } from 'aws-amplify';
import Observable from 'zen-observable-ts'
import { onNotificationCreatedByLambda } from '../../graphql/ownSubscriptions';
import { ConversationType, NotificationType, OnNotificationCreatedByLambdaSubscription } from '../../API';
import { useAppState } from '../../globalStates/AppState';
import { ChatConversationParam } from '../../communicationArea/ChatPage';
import { useLanguageState } from '../../globalStates/LanguageState';
import branding from '../../branding/branding';
import { ScheduleListType } from '../../communicationArea/ScheduleTab';
import { NetworkingListType } from '../../communicationArea/CommunicationArea';
import { fetchConversationNameAndType, fetchUserName, loadEventDateDetails, RestrictedAreaTypes } from '../../backendServices/BackendServices';
import { useRef } from 'react';
import { buildDetailLink } from '../../contentArea/detailPages/DetailNavLink';
import { useHistory } from 'react-router-dom';
import { isExplorationOrPostEventPhase } from "../../utils/EventPhaseChecker";
import { useUserRestrictedAreaAccess } from '../../globalStates/UserRestrictedAreaAccess';



export interface Notification {
    userId: string
    type: NotificationType
    title: string
    text: string
    userName: string
    organizationName?: string
    restrictedAreaId?: string
    restrictedAreaType?: string
    onClick: () => void
    onClose?: () => void
}

interface PlainTextNotificationContent {
    type: string
    text: string
    title: string
}

interface NetworkingNotificationContent {
    userId: string
    userName: string
    pictureUrl: string
    message: string
    action: string
}

interface ChatMessageNotificationContent {
    msgId: string
    msgContent: string
    msgCreatedAt: Date
    convId: string
    authorId: string
}

interface CalendarEntryNotificationContent {
    type: string
    senderName: string
    start: string
    end: string
    title: string
    description: string
}

interface BackofficeNotificationContent {
    userId: string
    userName: string
    message: string
    action: string
    organizationName: string
    organizationId: string
    restrictedAreaId: string
    restrictedAreaType: string
    restrictedAreaName: string
    restrictedAreaNameDe: string
}

enum CalendarEntryNotificationType {
    MEETING_REQUEST_DELETED = "meetingrequestdeleted",
    MEETING_REQUEST = "meetingrequest",
    MEETING_REQUEST_PARTICIPATION_DELETED = "meetingrequestparticipationdeleted",
    MEETING_REQUEST_PARTICIPATION_ACCEPTED = "meetingrequestparticipationaccepted",
    MEETING_REQUEST_PARTICIPATION_DECLINED = "meetingrequestparticipationdeclined",
    MEETING_REQUEST_UPDATED = "meetingrequestupdated"
}

interface StateValues {
    notifications: Notification[]
    mutedChatConversationIds: string[]
    userProfileId: string
}

interface NotificationCreatedSubscription {
    value: {
        data: OnNotificationCreatedByLambdaSubscription
    }
}

const getStartValues = (): StateValues => {
    return {
        notifications: [],
        mutedChatConversationIds: [],
        userProfileId: ""
    }
}


const audio = new Audio("/chatChime2.mp3");
let onNotificationCreatedHandle: any
let onSystemNotificationCreatedHandle: any
const useWrapState = (stateValues: State<StateValues>) => {
    const audioRef = useRef(audio) // Audio Ref is strange

    const appState = useAppState()
    const history = useHistory()
    const languageState = useLanguageState()
    const appStrings = languageState.getStrings()
    const userAccessState = useUserRestrictedAreaAccess();

    const addNotification = (notification: Notification) => {
        state.set(prevState => {
            prevState.notifications = prevState.notifications.concat([notification])
            return prevState
        })
    }

    const isMutedChat = (msgNotification: ChatMessageNotificationContent) => {
        return state.mutedChatConversationIds.value.includes(msgNotification.convId)
    }

    const handlePlainTextNotification = (content: PlainTextNotificationContent) => {
        return [content.title, content.text]
    }

    const handleNetworkingNotification = (content: NetworkingNotificationContent) => {
        var title = ""
        var userName = ""
        var text = ""


        switch (content.action) {
            case "REQUEST": {
                title = appStrings.notification.connectRequestTitle
                userName = content.userName
                text = replaceNetworkingNotificationPlaceholderText(content, appStrings.notification.connectRequestIncomingTextTemplate)
                break
            }
            case "CONNECT": {
                title = appStrings.notification.connectRequestTitle
                userName = content.userName
                text = replaceNetworkingNotificationPlaceholderText(content, appStrings.notification.connectRequestAcceptedTextTemplate)
                break
            }
            default:
                return []
        }

        return [title, userName, text]
    }

    const handleChatMessageNotification = (msgNotification: ChatMessageNotificationContent, convType: ConversationType, authorName: string, convName?: string) => {
        if (msgNotification.authorId === state.userProfileId.value || isMutedChat(msgNotification)) {
            return []
        }
        /*if (convType === ConversationType.GROUP) {
            return [`${authorName}@${convName ?? appStrings.chatBranding.groupChat}`, msgNotification.msgContent]
        }
        return [authorName, msgNotification.msgContent]*/

        return [appStrings.notification.newMessageTitle, authorName + ": " + msgNotification.msgContent]
    }

    const onChatMessageNotificationClick = (msgNotification: ChatMessageNotificationContent, convType: ConversationType) => {
        const param = ChatConversationParam.conversationByConversationId(convType, msgNotification.convId)
        appState.setShowChatsTab(param)
    }

    const onNetworkingNotificationClick = (notificationAction: string) => {
        switch (notificationAction) {
            case "REQUEST": {
                appState.setShowPeopleTab(NetworkingListType.REQUESTS)
                break
            }
            case "CONNECT": {
                appState.setShowPeopleTab(NetworkingListType.CONTACTS)
                break
            }
            default:
                return []
        }
    }

    const replaceCalendarEntryNotificationPlaceholderText = (content: CalendarEntryNotificationContent, template: string) => {
        var contentString = template.split('{$meetingTitle}').join(content.title)
        contentString = contentString.split('{$senderName}').join(content.senderName)

        return [contentString, content.senderName]
    }

    const replaceNetworkingNotificationPlaceholderText = (content: NetworkingNotificationContent, template: string) => {
        var contentString = template.split('{$senderName}').join(content.userName)

        return contentString
    }

    const replaceBackofficeNotificationPlaceholderText = async (content: BackofficeNotificationContent, template: string) => {
        var contentString = template.split('{$userName}').join(content.userName)

        if (content.restrictedAreaType === RestrictedAreaTypes.VirtualCafe.value) {
            const virtualCafe = appStrings.meetingRoomGroups.find(meetingRoomGroup => meetingRoomGroup.id === content.restrictedAreaId)
            if (virtualCafe) {
                contentString = contentString.split('{$restrictedAreaName}').join(virtualCafe.title)
            } else {
                return undefined
            }
        } else if (content.restrictedAreaType === RestrictedAreaTypes.PrivateEvent.value) {
            try {
                const eventDateResp = await loadEventDateDetails({ "id": content.restrictedAreaId })
                const eventDateName = eventDateResp.content.name
                if (eventDateName) {
                    contentString = contentString.split('{$restrictedAreaName}').join(eventDateName)
                } else {
                    return undefined
                }
            } catch (error) {
                return undefined
            }
        }
        else if (content.restrictedAreaType === RestrictedAreaTypes.ConferenceRoom.value) {
            const conferenceRoomTitle = languageState.getLanguage() === "en" ? content.restrictedAreaName : content.restrictedAreaNameDe;
            if (conferenceRoomTitle) {
                contentString = contentString.split('{$restrictedAreaName}').join(conferenceRoomTitle)
            } else {
                return undefined
            }
        }

        return contentString
    }

    const updateUserAccess = (content: BackofficeNotificationContent): void => {
        switch (content.restrictedAreaType) {
            case RestrictedAreaTypes.VirtualCafe.value: {
                userAccessState.fetchUserAccessForAllVirtualCafes()
                break
            }
            case (RestrictedAreaTypes.RoundTable.value): {
                userAccessState.fetchUserAccessForAllEventDates()
                break
            }
            case (RestrictedAreaTypes.PrivateEvent.value): {
                userAccessState.fetchUserAccessForAllEventDates()
                break
            }
            case RestrictedAreaTypes.ConferenceRoom.value: {
                userAccessState.fetchUserAccessForAllConferenceRooms()
                break
            }
            default: {
                userAccessState.fetchUserAccessForAllRestrictedAreaTypes()
                break
            }
        }
    }

    const handleBackofficeNotification = async (content: BackofficeNotificationContent) => {

        var title = ""
        var userName = ""
        var text = undefined
        var organizationName = ""
        var restrictedAreaId = content.restrictedAreaId
        var restrictedAreaType = content.restrictedAreaType

        switch (content.action) {
            case "request": {
                title = appStrings.notification.accessRequestTitle
                userName = content.userName
                text = await replaceBackofficeNotificationPlaceholderText(content, appStrings.notification.accessRequestText)
                break
            }
            case "accept": {
                title = appStrings.notification.accessAcceptedTitle
                organizationName = content.organizationName
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, appStrings.notification.accessAcceptedText)
                break
            }
            case "decline": {
                title = appStrings.notification.accessDeclinedTitle
                organizationName = content.organizationName
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, appStrings.notification.accessDeclinedText)
                break
            }
            case "delete": {
                title = appStrings.notification.accessDeletedTitle
                organizationName = content.organizationName
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, appStrings.notification.accessDeletedText)
                break
            }
            case "added": {
                title = appStrings.notification.accessAddedTitle
                userName = content.userName
                updateUserAccess(content)
                text = await replaceBackofficeNotificationPlaceholderText(content, appStrings.notification.accessAddedText)
                break
            }
            default:
                return []
        }
        return [title, userName, text, organizationName, restrictedAreaId, restrictedAreaType]
    }

    const onBackofficeNotificationClick = (content: BackofficeNotificationContent) => {
        if (content.action === "request") {
            if (content.restrictedAreaId && content.restrictedAreaType) {
                history.push(`${buildDetailLink(content.organizationId, content.organizationName, "organization")}/backoffice?restrictedAreaId=${content.restrictedAreaId}&restrictedAreaType=${content.restrictedAreaType}`)
            }
        } else if (content.action === "added" || content.action === "accept") {
            if (!isExplorationOrPostEventPhase) {
                if (content.restrictedAreaType === RestrictedAreaTypes.VirtualCafe.value) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "virtualCafeMeetingGroup")}`)
                } else if ( (content.restrictedAreaType === RestrictedAreaTypes.RoundTable.value) || (content.restrictedAreaType === RestrictedAreaTypes.PrivateEvent.value) ) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "eventdate")}`)
                }else if(content.restrictedAreaType === RestrictedAreaTypes.ConferenceRoom.value) {
                    history.push(`${buildDetailLink(content.restrictedAreaId, "", "conferenceRoom")}`)
                }
            }
            else {
                history.push("/meetings")
            }
        }
    }


    const handleCalendarEntryNotification = (content: CalendarEntryNotificationContent) => {
        var title = ""
        var text = ""
        var userName = ""
        switch (content.type) {
            case CalendarEntryNotificationType.MEETING_REQUEST:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestTextTemplate)[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_DELETED:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestDeletedTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestDeletedTextTemplate)[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_DELETED:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationDeletedTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationDeclinedTextTemplate)[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_ACCEPTED:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationAcceptedTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationAcceptedTextTemplate)[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_PARTICIPATION_DECLINED:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationDeclinedTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestParticipationDeclinedTextTemplate)[1]
                break
            case CalendarEntryNotificationType.MEETING_REQUEST_UPDATED:
                title = appStrings.notification.meetingRequestTitle
                text = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestUpdatedTextTemplate)[0]
                userName = replaceCalendarEntryNotificationPlaceholderText(content, appStrings.notification.meetingRequestUpdatedTextTemplate)[1]
                break
            default:
                return []
        }

        return [title, text, userName]
    }

    const onCalendarEntryNotificationClick = (contentType: CalendarEntryNotificationType) => {
        switch (contentType) {
            case CalendarEntryNotificationType.MEETING_REQUEST:
                appState.setShowScheduleTab(ScheduleListType.PENDING)
                break
            default:
                appState.setShowScheduleTab(ScheduleListType.CONFIRMED)
                break
        }
    }

    return ({
        init: (userId: string) => {
            state.set(prevState => {
                prevState.userProfileId = userId
                return prevState
            })
            if (onNotificationCreatedHandle === undefined) {
                onNotificationCreatedHandle = (API.graphql(graphqlOperation(onNotificationCreatedByLambda, { userId: userId })) as Observable<NotificationCreatedSubscription>).subscribe({
                    next: handleNotificationReceived
                })
            }
            // HINT: Resolvers are currently using the UserPool as the sotName since SOT is not available for the CognitoUsers.
            // TODO: Remove this hint when the TODOs in the relevant Resolvers are solved.
            // Relevant resolvers:
            //  - Subscription.onNotificationCreated.req
            //  - Query.notificationsByUserIdAndDisplayGroupSorted.res
            if (onSystemNotificationCreatedHandle === undefined) {
                onSystemNotificationCreatedHandle = (API.graphql(graphqlOperation(onNotificationCreatedByLambda, { userId: branding.configuration.sotName })) as Observable<NotificationCreatedSubscription>).subscribe({
                    next: handleNotificationReceived
                })
            }
            async function handleNotificationReceived(resp: NotificationCreatedSubscription) {
                const notification = resp.value.data.onNotificationCreated
                if (!notification) {
                    return
                }
                const content = JSON.parse(notification.content)
                switch (notification.type) {
                    case NotificationType.PLAINTEXT: {
                        const [title, text] = handlePlainTextNotification(content)
                        addNotification({
                            userId,
                            title,
                            text,
                            type: notification.type,
                            userName: "",
                            onClick: () => { },
                        })
                        break
                    }
                    case NotificationType.NETWORKING: {
                        const [title, userName, text] = handleNetworkingNotification(content)
                        addNotification({
                            userId,
                            title,
                            text,
                            type: notification.type,
                            userName: userName,
                            onClick: () => onNetworkingNotificationClick(content.action),
                        })
                        break
                    }
                    case NotificationType.MESSAGE: {
                        const { convType, convName } = await fetchConversationNameAndType(content.convId)
                        const { userName, userPictureUrl } = await fetchUserName(content.authorId) // eslint-disable-line @typescript-eslint/no-unused-vars
                        const messageContent = { ...content, msgCreatedAt: new Date(content.msgCreatedAt) }
                        if (!userName) break
                        const [title, text] = handleChatMessageNotification(messageContent, convType, userName, convName)
                        if (title && text) { // TODO show picture in notification
                            audioRef.current.play()
                            addNotification({
                                userId,
                                title,
                                text,
                                type: notification.type,
                                userName: userName,
                                onClick: () => onChatMessageNotificationClick(messageContent, convType)
                            })
                        }
                        break
                    }
                    case NotificationType.CALENDARENTRY: {
                        const [notificationTitle, notificationText, userName] = handleCalendarEntryNotification(content)
                        if (notificationTitle && notificationTitle) {
                            addNotification({
                                userId: userId,
                                type: notification.type,
                                title: notificationTitle,
                                text: notificationText,
                                userName: userName,
                                onClick: () => onCalendarEntryNotificationClick(content.type)
                            })
                        }
                        break
                    }
                    case NotificationType.BACKOFFICE: {
                        const [title, userName, text, organizationName, restrictedAreaId, restrictedAreaType] = await handleBackofficeNotification(content)
                        if (text && title && userName) {
                            addNotification({
                                userId: userId,
                                title: title,
                                text: text,
                                type: notification.type,
                                userName: userName,
                                organizationName: organizationName,
                                restrictedAreaId: restrictedAreaId,
                                restrictedAreaType: restrictedAreaType,
                                onClick: () => onBackofficeNotificationClick(content),
                            })
                        }
                        break
                    }
                    default:
                        return
                }
            }
        },
        getNotifications: () => {
            return stateValues.value.notifications
        },
        removeNotification: (notification: Notification) => {
            state.set(prevState => {
                const indexToRemove = prevState.notifications.indexOf(notification)
                if (indexToRemove > -1) {
                    prevState.notifications = [...prevState.notifications.slice(0, indexToRemove), ...prevState.notifications.slice(indexToRemove + 1)]
                }
                return prevState
            })
        },
        addMutedConversationId: (conversationId: string) => {
            state.set(prevState => {
                if (!prevState.mutedChatConversationIds.includes(conversationId)) {
                    prevState.mutedChatConversationIds = prevState.mutedChatConversationIds.concat([conversationId])
                }
                return prevState
            })
        },
        removeMutedConversationId: (conversationId: string) => {
            state.set(prevState => {
                const indexToRemove = prevState.mutedChatConversationIds.indexOf(conversationId)
                if (indexToRemove > -1) {
                    prevState.mutedChatConversationIds = [...prevState.mutedChatConversationIds.slice(0, indexToRemove), ...prevState.mutedChatConversationIds.slice(indexToRemove + 1)]
                }
                return prevState
            })
        },
        unsubscribe: () => {
            onNotificationCreatedHandle?.unsubscribe()
            onSystemNotificationCreatedHandle?.unsubscribe()

            onNotificationCreatedHandle = undefined
            onSystemNotificationCreatedHandle = undefined
        }
    })
}
const state = createState<StateValues>(getStartValues())
export const useNotificationContext = () => useWrapState(useState(state))
