import { EntityType } from "../../backendServices/Types"
import { BasisPremiumType } from "../../branding/BasisPremiumBranding"
import { IconMeetingSchedule, IconCall, IconShare, IconCameraJoin } from "../../ui/Icons"
import branding from "../../branding/branding"

export type ActionType = "EXPO" | "MEETING" | "INTEREST" | "RECOMMEND" | "BOOKMARK"
export type LayoutType = "tile-layout" | "list-layout" | "lobby-layout"

export const getActionsConfig = (
    strings: any,
    layout: LayoutType,
    entityType: EntityType,
    exhibitorHasContacts: boolean | undefined,
    disableFavorites: boolean | undefined,
    basisPremiumType: BasisPremiumType | undefined,
    strokeColor: string,
    fillColor: string) => {

    return [
        {
            actionType: "EXPO" as ActionType,
            tooltip: strings.contactEntry.startCallHint,
            icon: {
                "tile-layout": IconCameraJoin({ fill: branding.sideIconBar.sideIconColorDark }),
                "list-layout": IconCameraJoin({ fill: branding.exhibitorsPageContent.listLayoutActionsIconColor ?? branding.sideIconBar.sideIconColorDark })
            },
            displayCondition: layout === "list-layout" && entityType === "organization" && branding.exhibitorsPageContent.iconsVisibleOnTiles.includes("VIDEO_CALL"),
            strokeColor: strokeColor
        },
        {
            actionType: "MEETING" as ActionType,
            tooltip: strings.contactEntry.requestMeetingHint,
            icon: {
                "tile-layout": IconMeetingSchedule({ stroke: strokeColor, fill: fillColor }),
                "list-layout": IconMeetingSchedule({ fill: branding.exhibitorsPageContent.listLayoutActionsIconColor ?? branding.sideIconBar.sideIconColorDark }),
                "lobby-layout": IconMeetingSchedule({ stroke: "white" })
            },
            displayCondition: entityType === "organization"
                && exhibitorHasContacts
                && basisPremiumType
                && branding.exhibitorsPageContent.packagesWithMeetingsEnabled.includes(basisPremiumType)
                && branding.exhibitorsPageContent.iconsVisibleOnTiles.includes("SCHEDULE_MEETING"),
            strokeColor: strokeColor
        },
        {
            actionType: "INTEREST" as ActionType,
            tooltip: strings.organizationDetailPageContent.registerInterest,
            icon: {
                "tile-layout": IconCall({ stroke: strokeColor, fill: fillColor }),
                "list-layout": IconCall({ fill: branding.exhibitorsPageContent.listLayoutActionsIconColor ?? branding.sideIconBar.sideIconColorDark, stroke: branding.exhibitorsPageContent.listLayoutActionsIconColor ?? branding.sideIconBar.sideIconColorDark }),
                "lobby-layout": IconCall({ stroke: "#FFF", fill: "#FFF" })
            },
            displayCondition: entityType === "organization" && branding.exhibitorsPageContent.iconsVisibleOnTiles.includes("REGISTER_INTEREST"),
            strokeColor: strokeColor
        },
        {
            actionType: "RECOMMEND" as ActionType,
            tooltip: strings.contactEntry.shareHint,
            icon: {
                "tile-layout": IconShare({ stroke: strokeColor, fill: fillColor }),
                "list-layout": IconShare({ fill: branding.exhibitorsPageContent.listLayoutActionsIconColor ?? branding.sideIconBar.sideIconColorDark }),
                "lobby-layout": IconShare({ stroke: "#FFF", fill: "#FFF" })
            },
            displayCondition: entityType === "organization" && branding.exhibitorsPageContent.iconsVisibleOnTiles.includes("RECOMMEND_EXHIBITOR"),
            strokeColor: strokeColor
        },
        {
            actionType: "BOOKMARK" as ActionType,
            tooltip: strings.organizationDetailPageContent.bookmarkPerson,
            icon: null,
            displayCondition: !disableFavorites && (entityType !== "organization" || branding.exhibitorsPageContent.iconsVisibleOnTiles.includes("BOOKMARK_EXHIBITOR")),
            strokeColor: strokeColor
        }
    ]
}