import React, { useEffect, useState } from "react"
import { DataPrivacyDoc } from "../../backendServices/BackendServices"
import branding from "../../branding/branding"
import { LoginType } from "./LoginRegistrationSiteBranding"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import TicketCodeComponent from "./TicketCodeComponent"


let loginModule: LoginModule | undefined
async function loadLoginModule() {
    switch (branding.loginRegistrationSite.loginType) {
        case LoginType.SSO_KOELNMESSE:
            loginModule = (await import("./ssoKoelnmesse/LoginWithSSOKoelnmesse")).default
            break
        case LoginType.PASSWORD:
            loginModule = (await import("./password/LoginWithPassword")).default
            break
        default:
            loginModule = (await import("./token/LoginWithToken")).default
            break
    }
}
export interface LoginModule {
    component: (props: { dataPrivacyDoc: DataPrivacyDoc }) => JSX.Element
    isSessionValid: () => Promise<Boolean>
    logout: () => Promise<void>
}

interface LoginModuleComponentProps {
    dataPrivacyDoc: DataPrivacyDoc
}
function LoginModuleComponent(props: LoginModuleComponentProps) {
    const [loginModuleState, setLoginModuleState] = useState<LoginModule | undefined>()
    const loggedInState = useLoggedInState()

    useEffect(() => {
        if (!loginModuleState)
            loadLoginModule().then(() => {
                setLoginModuleState(loginModule)
            })
    }, []) // eslint-disable-line

    if (loginModuleState && !loggedInState.isLoggedIn) return <loginModuleState.component {...props} />
    else if (!loggedInState.sessionAndTicketValid) return <TicketCodeComponent />
    else return <div></div>
}

export default LoginModuleComponent

export async function isSessionValid() {
    if (!loginModule) await loadLoginModule()
    return await loginModule!.isSessionValid()
}

export async function logout() {
    if (loginModule) return await loginModule!.logout()
}
