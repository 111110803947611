import { useState, createState, State } from "@hookstate/core"
import { SessionUserMap, listUsersInAllLoungesOrCalls } from "../backendServices/BackendServices";
interface HandleMap {
    cnt: number,
    subscription?: number
}
const POLLING_INTERVAL = 1 * 60 * 1000;


const subscriptions: HandleMap = { cnt: 0 }

interface LoungeUserState {
    lounges?: SessionUserMap
}

function setLoungeUsers(state: State<LoungeUserState>, map: SessionUserMap) {
    state.set(newState => {
        newState.lounges = map;
        return newState;
    });
}


export const useWrapState = ((state: State<LoungeUserState>) => {
    return {
        subscribe: (specificCallParam?: string) => {
            if (subscriptions.cnt === 0) {
                subscriptions.subscription = window.setInterval(async () => {
                    setLoungeUsers(state, await listUsersInAllLoungesOrCalls(specificCallParam))
                }, POLLING_INTERVAL);
                (async () => {
                    setLoungeUsers(state, await listUsersInAllLoungesOrCalls(specificCallParam))
                })()
            }
            subscriptions.cnt = subscriptions.cnt + 1
        },
        unsubscribe: () => {
            if (subscriptions.cnt > 0) {
                subscriptions.cnt = subscriptions.cnt - 1
            }
            if (subscriptions.cnt === 0 && subscriptions.subscription) {
                clearInterval(subscriptions.subscription)
                delete subscriptions.subscription
            }
        },
        getUsersInRoomOrLounge: (roomOrLounge: string) => {
            return state.get().lounges ? state.get().lounges![roomOrLounge] || [] : []
        }
    }
})

const state = createState({} as LoungeUserState)
export const useOnlineUsers = () => useWrapState(useState(state))
