import React from "react"
import styled from "styled-components"
import { ContactItem } from "../backendServices/BackendServices"
import { ModalType, Person, SotUser } from "../backendServices/Types"
import PersonComponent from "./carousels/components/PersonComponent"

const TileWrapper = styled.div`
    width: 100%;
    background-color: #fff;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: auto auto auto auto auto;
    grid-gap: 10px;
    /* padding: 5px; */
    /* margin: 10 auto; */
    /* padding: 0 15px 25px 25px; */

    @media (max-width: 2200px) {
        grid-template-columns: auto auto auto auto;
    }

    @media (max-width: 1300px) {
        grid-template-columns: auto auto auto;
    }

    @media (max-height: 1000px) {
        padding-bottom: 40px;
    }

    @media (max-height: 600px) {
        padding-bottom: 65px;
    }
`

const BottomSpacing = styled.div`
    width: 95%;
    background-color: #fff;
    z-index: 2;
    height: 25px;
    position: relative;
    bottom: 0;
    left: 0;
    opacity: 1;
`
interface SpeakersTilesLayoutProps {
    speakersList: Person[] | ContactItem[] | SotUser[]
    componentType: "person" | "contact-item" | "user"
    setTargetId: (target: string) => void
    setShowRecommendPersonModal: (toggled: boolean) => void
    showBookmarkEventsModal?: boolean
    setShowBookmarkEventsModal?: (toggled: boolean) => void
    setLink: (link: string) => void
    callback?: (param: { modalType?: ModalType }) => void
    setSelectedUser?: (value: any) => void
    showContextMenuOnHover?: boolean
    customBookmarkFunction?: (id: string) => void
}

const SpeakersTilesLayout: React.FunctionComponent<SpeakersTilesLayoutProps> = (props: SpeakersTilesLayoutProps) => {
    if (!props.speakersList) return null

    const items =
        props.componentType === "person"
            ? (props.speakersList as Person[]).map((person) => {
                  return (
                      <PersonComponent
                          key={person.id}
                          {...person}
                          type={"person"}
                          customBookmarkFunction={props.customBookmarkFunction}
                      />
                  )
              })
            : props.componentType === "contact-item"
            ? (props.speakersList as ContactItem[]).map((user) => {
                  return <PersonComponent key={user.sotUser.id} userId={user.sotUser.id} {...user.sotUser} type={"sotuser"} />
              })
            : (props.speakersList as SotUser[]).map((user) => {
                  return (
                      <PersonComponent
                          key={user.id}
                          {...user}
                          type={"sotuser"}
                          user={user}
                          callback={props.callback}
                          setSelectedUser={props.setSelectedUser}
                          showContextMenuOnHover={props.showContextMenuOnHover}
                          customBookmarkFunction={props.customBookmarkFunction}
                      />
                  )
              })

    return (
        <div>
            <TileWrapper>{items}</TileWrapper>
            <BottomSpacing />
        </div>
    )
}

export default SpeakersTilesLayout
