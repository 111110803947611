import React from "react"
import { Product } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../WindowDimensionsHook"
import branding from "../../branding/branding"
import { IconProduct } from "../Icons"
import { productsPageRoute } from "../../navigationArea/RoutePaths"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import TileRow from "../TileRow"
import VizSensor from "react-visibility-sensor"
import { ProductComponent } from "./components/ProductComponent"

interface ProductCarouselProps {
    title?: string
    linkText?: string
    link?: string
    onBeforeLinkClick?: () => void
    products: Product[]
}
export function ProductCarousel(props: ProductCarouselProps) {
    const { products } = props
    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    // Let's take props over defaults
    const title = props.title ?? strings.navigationArea.productsItemTitle
    const icon = <IconProduct fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} width={"23px"} height={"23px"}/>
    const linkText = props.linkText ?? strings.receptionPage.productsNavLinkText
    const link = props.link ?? productsPageRoute

    let content
    if (products.length === 0) {
        content = (
            <EmptyTile
                header={title}
                buttonNavLink={link}
                buttonMessage={linkText}
                hideButton={true}
                bgColor="#F2F2F2"
            />
        )
    } else {
        const widthHeight = isMobile ? { width: 95, height: 95 } : { width: 500, height: 280 }
        content = products.map((product, index) => (
            <VizSensor key={index} partialVisibility={true}>
                {({ isVisible }) => (isVisible ? <ProductComponent product={product} /> : <div style={widthHeight}></div>)}
            </VizSensor>
        ))
    }

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkText={linkText}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            height={isMobile ? "95px" : "280px"}
            scrollBar={products && products.length > 0}
            isMyFairPage
            hideShadows={isMobile}
            childWidth={isMobile ? 95 : 500}
            childCount={products.length ?? 0}
        >
            {content}
        </TileRow>
    )
}
