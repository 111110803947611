import React from "react"
import { Exhibitor } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { showfloorPageRoute } from "../../navigationArea/RoutePaths"
import { IconOrganization } from "../Icons"
import TileRow from "../TileRow"
import VizSensor from "react-visibility-sensor"
import useWindowDimensions from "../WindowDimensionsHook"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import { OrganizationComponent } from "./components/OrganizationComponent"

interface OrganizationCarouselProps {
    title?: string
    linkText?: string
    link?: string
    onBeforeLinkClick?: () => void
    organizations: Exhibitor[]
}
export function OrganizationCarousel(props: OrganizationCarouselProps) {
    const { organizations } = props

    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    const title = props.title ?? strings.receptionPage.exhibitors
    const icon = <IconOrganization fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />
    const linkText = props.linkText ?? strings.receptionPage.showFloorNavLinkText
    const link = props.link ?? showfloorPageRoute

    let content
    if (organizations.length === 0)
        content = <EmptyTile header={title} buttonNavLink={link} buttonMessage={linkText} hideButton={true} bgColor="#F2F2F2" />
    else {
        const widthHeight = isMobile ? { width: 205, height: 95 } : { width: 500, height: 280 }

        content = organizations.map((showfloor, index) => (
            <VizSensor key={index} partialVisibility={true}>
                {({ isVisible }) =>
                    isVisible ? (
                        <OrganizationComponent backgroundUrl={showfloor.backgroundImageURL} exhibitor={showfloor} />
                    ) : (
                        <div style={widthHeight}></div>
                    )
                }
            </VizSensor>
        ))
    }

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            hideShadows={isMobile}
            height={isMobile ? "95px" : "220px"}
            navLinkText={linkText}
            scrollBar={organizations && organizations.length > 0}
            childWidth={isMobile ? 205 + 4 : 500 + 4}
            childCount={organizations?.length ?? 0}
        >
            {content}
        </TileRow>
    )
}
