import React, { useEffect, useState } from "react"
import styled from "styled-components"
import VisibilitySensor from "react-visibility-sensor"
import {
    ContactItem,
    ContactListResponse,
    loadRelevantProfilesListData,
    loadUsersData
} from "../../backendServices/BackendServices"
import { NextPageLoader } from "../../communicationArea/CommunicationArea"
import BackendError from "../../ui/BackendError"
import CenteredLoader from "../../ui/CenteredLoader"
import RecommendModal from "../../ui/RecommendModal"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import SpeakersTilesLayout from "../../ui/SpeakersTilesLayout"
import { ShareTargetType, SotUser } from "../../backendServices/Types"
import TopFilterArea, { TopFilterAreaProps } from "./TopFilterArea"
import { viewType } from "./NetworkingPageContentBranding"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"

const RelevantTilesRoot = styled.div<{ reservedSpaceHeight: number; topicFilterPresent: boolean; topFilterArea: boolean }>`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
    position: relative;
    padding-top: ${(props) => (!props.topFilterArea ? "0px" : props.topicFilterPresent ? "110px" : "100px")};

    & .absolute-center {
        left: 0;
        top: 0;
        position: absolute;
        z-index: 10;
    }

    & .ScrollbarsCustom-Content {
        position: relative;
    }
`

const VisibilitySensorParent = styled.div`
    position: absolute;
    width: 100%;
    bottom: 20px;
`

const ScrollContainerRoot = styled.div`
    width: 100%;
`

export const NetworkingActiveTextRoot = styled.div`
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
`

export const NetworkingActiveText = styled.div`
    font-family: ${branding.font1};
    font-size: 15px;
    text-align: center;
    padding: 0 25px;
    font-weight: bold;
`

interface RelevantTilesViewProps {
    page: number
    loggedInUserId: string | undefined
    searchString: string
    subContentHeight: number
    isLoader: boolean
    relevantUsers: ContactItem[]
    sotUsers: SotUser[]
    topicFilter: number | null
    currentViewType: viewType
    isMatchActive?: boolean
    filterAreaProps?: TopFilterAreaProps
    setPage: (value: React.SetStateAction<number>) => void
    setIsLoader: (value: React.SetStateAction<boolean>) => void
    setRelevantUsers?: React.Dispatch<React.SetStateAction<ContactItem[]>>
    setSotUsers?: React.Dispatch<React.SetStateAction<SotUser[]>>
    setLastLoadView?: (value: React.SetStateAction<viewType>) => void
}

const RelevantTilesView = (props: RelevantTilesViewProps) => {
    const languageState = useLanguageState()
    const strings = languageState.getStrings()
    const lang = languageState.getLanguage()
    const { hideOnScroll, setHideOnScroll } = TopBannerSharedState()
    const pageSize = 35
    const [hasMoreData, setHasMoreData] = useState<boolean>(false)
    const [isError, setIsError] = useState("")
    const [showRecommendPersonModal, setShowRecommendPersonModal] = useState<boolean>(false)
    const [link, setLink] = useState<string>("")
    const [targetId, setTargetId] = useState<string>("")
    // Checking if the topic filter is available
    let topicFilterPresent = branding.networkingArea.topicFilterActive

    const onNextPageLoaderVisibilityChange = (isVisible: boolean) => {
        if (isVisible) {
            props.setPage(props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST ? props.page + pageSize : props.page + 1)
        }
    }
    const fetchData = () => {
        if (props.currentViewType === viewType.RELEVANT_TILES && props.loggedInUserId) {
            props.setIsLoader(true)
            if (props.setLastLoadView) props.setLastLoadView(viewType.RELEVANT_TILES)

            let params = {
                searchString: props.searchString,
                itemsPerPage: pageSize,
                page: props.page,
                topicFilter: props.topicFilter as any
            }

            if (!params.topicFilter) delete params.topicFilter

            loadRelevantProfilesListData(props.loggedInUserId || "", params)
                .then((res) => {
                    const response = res as ContactListResponse
                    let contactsTemp = response.contacts?.filter(function (contact) {
                        return contact.sotUser.id !== props.loggedInUserId
                    })
                    if (props.setRelevantUsers)
                        props.setRelevantUsers(props.page === 0 ? contactsTemp : props.relevantUsers.concat(contactsTemp))
                    setHasMoreData(response.hasNextPage)
                })
                .catch((error) => {
                    setIsError(error)
                })
                .finally(() => {
                    props.setIsLoader(false)
                })
        } else if (props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST) {
            props.setIsLoader(true)
            if (props.setLastLoadView) props.setLastLoadView(viewType.USERS_FILTERED_BY_INTEREST)

            const catFilter = branding.networkingArea.userCategories.map((cat) => "cat_" + cat)

            let usersParameters: any = {
                filterlist: ["entity_sotu", catFilter, props.searchString].join(","),
                startresultrow: props.page,
                numresultrows: pageSize,
                order: "lexic"
            }

            loadUsersData(usersParameters)
                .then((res) => {
                    if (props.setSotUsers) props.setSotUsers(props.page === 0 ? res.users : props.sotUsers.concat(res.users))
                    setHasMoreData(props.page < res.count)
                })
                .catch((error) => {
                    props.setIsLoader(false)
                    setIsError(error)
                })
                .finally(() => {
                    props.setIsLoader(false)
                })
        }
    }

    useEffect(() => {
        props.setPage(0)
    }, [props.currentViewType]) // eslint-disable-line

    const refreshKey = [props.currentViewType, props.page, props.searchString, props.topicFilter, lang].join(", ")
    useEffect(() => {
        if (props.isMatchActive) fetchData()
        // eslint-disable-next-line
    }, [refreshKey, props.isMatchActive])

    const onScroll = (scrollValues: any) => {
        if (scrollValues.contentScrollHeight > scrollValues.clientHeight) {
            setHideOnScroll(scrollValues.scrollTop > 0)
        } else {
            setHideOnScroll(true)
        }
    }

    return (
        <RelevantTilesRoot
            reservedSpaceHeight={props.subContentHeight}
            topicFilterPresent={topicFilterPresent}
            topFilterArea={props.filterAreaProps !== undefined && props.filterAreaProps !== null}
        >
            {isError ? (
                <div style={{ marginTop: "25%" }}>
                    <BackendError />
                </div>
            ) : (
                <>
                    {props.filterAreaProps && (
                        <TopFilterArea
                            currentViewType={props.filterAreaProps.currentViewType}
                            personTypeData={props.filterAreaProps.personTypeData}
                            searchValue={props.filterAreaProps.searchValue}
                            userAttributeFilter={props.filterAreaProps.userAttributeFilter}
                            onSetUserAttributeFilter={props.filterAreaProps.onSetUserAttributeFilter}
                            onSetSearchString={props.filterAreaProps.onSetSearchString}
                            onHandleSearch={props.filterAreaProps.onHandleSearch}
                            onGraphReset={props.filterAreaProps.onGraphReset}
                            setCurrentViewType={props.filterAreaProps.setCurrentViewType}
                            setUsersWithLoadedConnections={props.filterAreaProps.setUsersWithLoadedConnections}
                            topFilterRef={props.filterAreaProps.topFilterRef}
                            userAttributes={props.filterAreaProps.userAttributes}
                            viewTypeData={props.filterAreaProps.viewTypeData}
                            selectedTopic={props.filterAreaProps.selectedTopic}
                            onTopicChanged={props.filterAreaProps.onTopicChanged}
                            setSelectedPersonType={props.filterAreaProps.setSelectedPersonType}
                            setCurrentDataPage={props.filterAreaProps.setCurrentDataPage}
                            currentDataPage={props.filterAreaProps.currentDataPage}
                            showLoadNewSuggestionsBtn={props.filterAreaProps.showLoadNewSuggestionsBtn}
                            showShadow={hideOnScroll}
                            isMatchActive={props.isMatchActive}
                            selectedPersonType={props.filterAreaProps.selectedPersonType}
                        />
                    )}
                    <ScrollContainerRoot>
                        <div style={{ marginTop: props.isMatchActive && props.filterAreaProps ? "35px" : "0" }}>
                            <ContentScrollContainer adjustForHeaderWith={`${props.subContentHeight}px`} handleScroll={onScroll}>
                                {props.isLoader && props.page === 0 ? (
                                    <CenteredLoader className={"absolute-center"} />
                                ) : props.isMatchActive ? (
                                    <div style={{ padding: "10px 25px" }}>
                                        <SpeakersTilesLayout
                                            speakersList={
                                                props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST
                                                    ? props.sotUsers
                                                    : props.relevantUsers
                                            }
                                            componentType={
                                                props.currentViewType === viewType.USERS_FILTERED_BY_INTEREST
                                                    ? "user"
                                                    : "contact-item"
                                            }
                                            setLink={setLink}
                                            setShowRecommendPersonModal={setShowRecommendPersonModal}
                                            setTargetId={setTargetId}
                                            showContextMenuOnHover={false}
                                        />
                                        {hasMoreData && (
                                            <VisibilitySensorParent>
                                                <VisibilitySensor
                                                    partialVisibility={true}
                                                    onChange={onNextPageLoaderVisibilityChange}
                                                >
                                                    <NextPageLoader />
                                                </VisibilitySensor>
                                            </VisibilitySensorParent>
                                        )}
                                    </div>
                                ) : (
                                    <NetworkingActiveTextRoot
                                        style={{ height: `calc(100vh - ${props.subContentHeight + 60}px)` }}
                                    >
                                        <NetworkingActiveText>
                                            {strings.communicationArea.activateNetworkingText}
                                        </NetworkingActiveText>
                                    </NetworkingActiveTextRoot>
                                )}
                            </ContentScrollContainer>
                        </div>
                    </ScrollContainerRoot>
                    {showRecommendPersonModal && (
                        <RecommendModal
                            targetId={targetId}
                            type={ShareTargetType.SOTUSER}
                            link={`https://${window.location.hostname}` + link}
                            sotUser={[]}
                            close={() => setShowRecommendPersonModal(false)}
                        />
                    )}
                </>
            )}
        </RelevantTilesRoot>
    )
}

export default RelevantTilesView
