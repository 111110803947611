import React from "react"
import { Person } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { speakersPageRoute } from "../../navigationArea/RoutePaths"
import { IconSpeakers } from "../Icons"
import TileRow from "../TileRow"
import VizSensor from "react-visibility-sensor"
import useWindowDimensions from "../WindowDimensionsHook"
import PersonComponent from "./components/PersonComponent"
import styled from "styled-components"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import { device } from "../../utils/Device"

interface PersonCarouselProps {
    title?: string
    linkText?: string
    link?: string
    onBeforeLinkClick?: () => void
    persons: Person[]
}
export function PersonCarousel(props: PersonCarouselProps) {
    const { persons } = props
    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    // Let's take props over defaults
    const title = props.title ?? strings.receptionPage.speakers
    const icon = <IconSpeakers fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />
    const linkText = props.linkText ?? strings.receptionPage.speakersNavLinkText
    const link = props.link ?? speakersPageRoute

    // Width and Height of the elements
    const widthHeight = isMobile ? { width: 95, height: 95 } : { width: 280, height: 280 }
    const listElements = persons.map((person, index) => (
        <VizSensor key={person.id} partialVisibility={true}>
            {({ isVisible }) => (
                <div style={widthHeight}>{isVisible && <PersonComponent {...person} type={"person"}></PersonComponent>}</div>
            )}
        </VizSensor>
    ))

    let content
    // Show Empty line if there are no persons
    if (persons.length === 0)
        content = <EmptyTile header={title} buttonNavLink={link} buttonMessage={linkText} hideButton={true} bgColor="#F2F2F2" />
    else content = <TileWrapperSpeakers $count={persons.length}>{listElements}</TileWrapperSpeakers>

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            hideShadows={isMobile}
            navLinkText={linkText}
            scrollBar={persons && persons.length > 0}
            childWidth={280 + 9}
            childCount={persons?.length ?? 0}
        >
            {content}
        </TileRow>
    )
}

const TileWrapperSpeakers = styled.div<{ $count?: number }>`
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(${(props) => props.$count}, 95px);
    grid-gap: ${branding.receptionPage.speakersTileGridGap ?? "10px"};
    /* grid-gap: 20px; */
    padding-bottom: 1.25rem;

    @media ${device.tablet} {
        grid-template-columns: repeat(${(props) => props.$count}, 280px);
    }

    @media ${device.mobile} {
        grid-template-columns: repeat(${(props) => props.$count}, 95px);
    }
`
