import { API, graphqlOperation } from "aws-amplify"
import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styled from "styled-components"
import { getUnreadCounterUser, UnreadObject } from "../backendServices/BackendServices"
import branding, { ColorString } from "../branding/branding"
import { useMeetingContext } from "../conference/context/MeetingContext"
import { CommunicationCenterDisplayMode, defaultLogger as logger, useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import { onUpdateUnreadCounterById } from "../graphql/subscriptions"
import { AvatarWithPresenceState } from "../ui/AvatarWithPresenceState"
import CenteredLoader from "../ui/CenteredLoader"
import {
    IconCCChats,
    IconCCNetworking,
    IconCCNotifications,
    IconCCSchedule,
    IconNetworkingToggleClose,
    IconNetworkingToggleOpen
} from "../ui/Icons"
import ChatsTab from "./ChatsTab"
import { ContactEntryType } from "./ContactEntry"
import NetworkingTab from "./NetworkingTab"
import NotificationsTab from "./NotificationsTab"
import ScheduleTab, { ScheduleListType } from "./ScheduleTab"
import SettingsTab from "./settings/SettingsTab"

/* &>* height: 59px because there is a border of 1px and we need to match the 60px of the topbar */
const NavigationAreaRoot = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    color: ${branding.primaryColor ?? "black"};
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    min-height: 60px;
    max-width: 100%;

    & > * {
        height: 59px;
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .networkingCollapsed & {
        border-bottom: 1px solid hsla(0, 0%, 0%, 0%);
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        & > * {
            flex-grow: 0;
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }
`

const CommunicationAreaNavigationItem = styled.button<{ disabled?: boolean }>`
    cursor: ${(props) => (props.disabled ? "none" : "pointer")};
    opacity: ${(props) => (props.disabled ? 0.4 : 1)};
    max-width: 40px;
    width: 20px;
    margin-left: 25px;
    text-align: center;
    border: none;
    background: none;
`

const CommunicationAreaNavigationItemAvatar = styled.div`
    cursor: pointer;
    max-width: 40px;
    width: 20px;
    margin-left: 25px;
    margin-right: 20px;
    text-align: center;
`

const DummyFlexItem = styled.div`
    display: none;
    flex-grow: 1 !important;
    .networkingCollapsed & {
        display: block;
    }
`
const AvatarRootArea = styled.div`
    padding-left: 22px;
`

const CommunicationAreaRoot = styled.div`
    width: 320px;
    min-width: 320px;

    & input::placeholder {
        color: ${branding.communicationAreaSearchPlaceholderColor ?? "black"};
    }

    /* transition: width 0.3s ease-in-out, min-width 0.3s ease-in-out; */

    &:not(.active) {
        min-width: 60px;
        width: 60px;
    }

    display: flex;
    flex-direction: column;

    font-size: 0.9rem;
    background-color: #fff;

    .chatlist-scrollref {
        background-color: ${branding.chatListBackgroundCommunicationAreaColor ?? "white"} !important;
    }
`

export enum NetworkingListType {
    RELEVANT,
    REQUESTS,
    CONTACTS
}

interface CommunicationAreaProps {}

const CommunicationArea: React.FunctionComponent<CommunicationAreaProps> = (props) => {
    const appState = useAppState()
    const meeting = useMeetingContext()
    const [unreadArray, setUnreadArray] = useState({ requests: 0, contacts: 0, conversations: 0, schedules: 0 })
    const userLink = useLoggedInState()
    const loggedInUserId = userLink.user()?.profileId

    function newUnreadObject(unread: UnreadObject) {
        return {
            requests: unread.requests ? unread.requests : 0,
            contacts: unread.contacts ? unread.contacts : 0,
            conversations: unread.conversations ? unread.conversations : 0,
            schedules: unread.schedules && unread.schedules > 0 ? unread.schedules : 0
        }
    }

    useEffect(() => {
        let params = new URLSearchParams(window.location.search)
        let communicationArea = params.get("communicationArea")
        let type = params.get("type") // Used to navigate instantly to schedule type area in case for email link visiting

        if (communicationArea && type) {
            switch (type) {
                case "PENDING":
                    appState.setShowScheduleTab(ScheduleListType.PENDING)
                    break
                case "DECLINED":
                    appState.setShowScheduleTab(ScheduleListType.DECLINED)
                    break
                default:
                    appState.setShowScheduleTab(ScheduleListType.CONFIRMED)
                    break
            }
        }
        ;(async () => {
            if (loggedInUserId) {
                const unreadRecord = (await getUnreadCounterUser(loggedInUserId)) as UnreadObject
                if (!unreadRecord) {
                    logger.warn(
                        "## Error, only in Dev Environment: can't find users unread record because the unreadTable is not valid initialized! -> use https://dmexco-int-event-cloud.com/rest/admin/userpool/DMEXCO/filldynamodbusertable with your environment, look in readme whithin messe.backend"
                    )
                    return
                }
                setUnreadArray(newUnreadObject(unreadRecord))

                const subscriptionUpdate = (
                    (await API.graphql(graphqlOperation(onUpdateUnreadCounterById, { id: loggedInUserId }))) as any
                ).subscribe({
                    next: (resp: any) => {
                        const values = resp.value.data?.onUpdateUnreadCounterById
                        if (values) {
                            setUnreadArray(newUnreadObject(values))
                        }
                    }
                })
                return () => {
                    try {
                        subscriptionUpdate.unsubscribe()
                    } catch (error) {
                        logger.error({
                            message: "CommunicationArea.tsx Unsubscribe failed",
                            errorMessage: error.message,
                            errorStack: error.stack
                        })
                    }
                }
            }
        })()
        //eslint-disable-next-line
    }, [loggedInUserId])

    return (
        <div style={{ height: "100%", borderLeft: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
            <CommunicationAreaRoot
                style={{ height: appState.isNetworkingOpen() ? "95%" : "100%" }}
                className={
                    appState.isNetworkingOpen() ||
                    meeting.getIncomingInvites().length > 0 ||
                    meeting.getOutgoingInvites().length > 0
                        ? "active"
                        : "networkingCollapsed"
                }
            >
                <NavigationArea unreadArray={unreadArray} />
                {appState.isNetworkingOpen() && <Content unreadArray={unreadArray} />}
                {appState.isNetworkingOpen() && <TabFooter />}
            </CommunicationAreaRoot>
        </div>
    )
}

const NetworkingToggleIcon = styled.div`
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    .networkingCollapsed & {
        margin-right: 25px;
    }
`

const IconContainer = styled.button<{ margin?: string }>`
    display: inline-block;
    position: relative;
    margin-left: ${(props) => props.margin};
    border: none;
    background: none;
`

const BadgeUnreadCounter = styled.span<{ visible?: boolean }>`
    position: relative;
    bottom: 12px;
    right: 10px;
    background-color: ${branding.communicationArea.badgeUnreadCounterBgColor ?? "#000"};
    border-radius: 50%;
    margin-left: 1px;
    border: 1px solid ${branding.communicationArea.badgeUnreadCounterBorderColor ?? "#fff"};
    font-size: 10px;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    visibility: ${(props) => (props.visible ? "hidden" : "visible")};
`
interface UnReadArrayProps {
    unreadArray: {
        requests: number
        contacts: number
        conversations: number
        schedules: number
    }
}
const NavigationArea: React.FC<UnReadArrayProps> = (props) => {
    const userState = useLoggedInState()
    const appState = useAppState()
    const eventKey = appState.communicationCenterDisplayMode
    const strings = useLanguageState().getStrings()

    const [unreadArray, setUnreadArray] = useState(props.unreadArray)

    const [count, setCount] = useState<number>(0)

    //unread badge colors
    const [contactsColor, setContactsColor] = useState<ColorString>(
        isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) &&
            appState.isNetworkingOpen() &&
            !branding.communicationArea.disableCCNetworkingIcon
            ? branding.activeIconColor
            : branding.communicationArea.inactiveItemColor
    )

    const [conversationsColor, setConversationsColor] = useState<ColorString>(
        isDisplayMode(CommunicationCenterDisplayMode.CHATS) &&
            appState.isNetworkingOpen() &&
            !branding.communicationArea.disableCCChatIcon
            ? branding.activeIconColor
            : branding.sideIconBar.inactiveItemColor
    )

    const [meetingsColor, setMeetingsColor] = useState<ColorString>(
        isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) &&
            appState.isNetworkingOpen() &&
            !branding.communicationArea.disableCCMeetingIcon
            ? branding.activeIconColor
            : branding.sideIconBar.inactiveItemColor
    )

    if (appState.communicationCenterDisplayMode === undefined) {
        appState.setShowPeopleTab()
    }

    const itemClasses = (targetEventKey: CommunicationCenterDisplayMode) => {
        return targetEventKey === eventKey ? "selected" : ""
    }

    function isDisplayMode(mode: CommunicationCenterDisplayMode): boolean {
        if (!appState.isNetworkingOpen()) {
            return true
        }

        return appState.communicationCenterDisplayMode === mode
    }

    function resetBadgeColors() {
        setContactsColor(
            isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) &&
                appState.isNetworkingOpen() &&
                !branding.communicationArea.disableCCNetworkingIcon
                ? branding.activeIconColor
                : branding.communicationArea.inactiveItemColor
        )

        setConversationsColor(
            isDisplayMode(CommunicationCenterDisplayMode.CHATS) &&
                appState.isNetworkingOpen() &&
                !branding.communicationArea.disableCCChatIcon
                ? branding.activeIconColor
                : branding.sideIconBar.inactiveItemColor
        )

        setMeetingsColor(
            isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) &&
                appState.isNetworkingOpen() &&
                !branding.communicationArea.disableCCMeetingIcon
                ? branding.activeIconColor
                : branding.sideIconBar.inactiveItemColor
        )
    }

    useEffect(() => {
        resetBadgeColors()
        // eslint-disable-next-line
    }, [appState.communicationCenterDisplayMode])

    useEffect(() => {
        setCount(count + 1)
        //props.unreadArray is loaded twice at the beginning for some weird reason, this is used to prevent showing new notification color on the first render
        if (count > 1) {
            if (props.unreadArray.requests + props.unreadArray.contacts > unreadArray.requests + unreadArray.contacts) {
                setContactsColor(branding.communicationArea.newNotificationColor)
            } else {
                setContactsColor(
                    isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) &&
                        appState.isNetworkingOpen() &&
                        !branding.communicationArea.disableCCNetworkingIcon
                        ? branding.activeIconColor
                        : branding.communicationArea.inactiveItemColor
                )
            }

            if (props.unreadArray.conversations > unreadArray.conversations) {
                setConversationsColor(branding.communicationArea.newNotificationColor)
            } else {
                setConversationsColor(
                    isDisplayMode(CommunicationCenterDisplayMode.CHATS) &&
                        appState.isNetworkingOpen() &&
                        !branding.communicationArea.disableCCChatIcon
                        ? branding.activeIconColor
                        : branding.sideIconBar.inactiveItemColor
                )
            }

            if (props.unreadArray.schedules > unreadArray.schedules) {
                setMeetingsColor(branding.communicationArea.newNotificationColor)
            } else {
                setMeetingsColor(
                    isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) &&
                        appState.isNetworkingOpen() &&
                        !branding.communicationArea.disableCCMeetingIcon
                        ? branding.activeIconColor
                        : branding.sideIconBar.inactiveItemColor
                )
            }
        }

        setUnreadArray(props.unreadArray)
        // eslint-disable-next-line
    }, [props.unreadArray])

    return (
        <NavigationAreaRoot
            onClick={() => {
                if (!appState.isNetworkingOpen()) {
                    appState.toggleNetworking()
                }
            }}
        >
            {!appState.isNetworkingOpen() && (
                <OverlayTrigger
                    placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {strings.communicationArea.settingsTitle}
                        </Tooltip>
                    }
                >
                    <CommunicationAreaNavigationItemAvatar
                        style={{ marginBottom: "25px" }}
                        className={itemClasses(CommunicationCenterDisplayMode.SETTINGS)}
                        onClick={() => {
                            appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SETTINGS)
                        }}
                    >
                        <AvatarRootArea>
                            <AvatarWithPresenceState
                                avatarSize={30}
                                badgeSize={10}
                                badgeRight={25}
                                badgeTop={18}
                                showAvatarBadge={true}
                                userId={userState.user()?.profileId}
                                initPresenceByList={false}
                                userType={userState.user()?.type}
                                content={{
                                    pictureUrl: userState.user()?.logoUrl,
                                    alt: [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
                                }}
                            ></AvatarWithPresenceState>
                        </AvatarRootArea>
                    </CommunicationAreaNavigationItemAvatar>
                </OverlayTrigger>
            )}

            <OverlayTrigger
                placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="people-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {strings.communicationArea.peopleTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCNetworkingIcon}
                    className={itemClasses(CommunicationCenterDisplayMode.NETWORKING)}
                    onClick={() => {
                        appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.NETWORKING)

                        if (isDisplayMode(CommunicationCenterDisplayMode.NETWORKING)) {
                            resetBadgeColors()
                        }
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCNetworkingIcon}
                        margin={
                            !appState.isNetworkingOpen() && (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0)
                                ? "-5px"
                                : !appState.isNetworkingOpen() &&
                                  (props.unreadArray.requests === 0 || props.unreadArray.contacts === 0)
                                ? "-30px"
                                : appState.isNetworkingOpen() &&
                                  (props.unreadArray.requests > 0 || props.unreadArray.contacts > 0)
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCNetworking({
                            fill:
                                isDisplayMode(CommunicationCenterDisplayMode.NETWORKING) &&
                                appState.isNetworkingOpen() &&
                                !branding.communicationArea.disableCCNetworkingIcon
                                    ? branding.activeIconColor
                                    : branding.communicationArea.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.requests + props.unreadArray.contacts <= 0 ? null : (
                        <h6 style={{ fontSize: "14px" }}>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCNetworkingIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: contactsColor
                                }}
                            >
                                {props.unreadArray.requests + props.unreadArray.contacts}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </OverlayTrigger>

            <OverlayTrigger
                placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="chat-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {strings.communicationArea.chatTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCChatIcon}
                    className={itemClasses(CommunicationCenterDisplayMode.CHATS)}
                    onClick={() => {
                        if (appState.communicationCenterDisplayMode === CommunicationCenterDisplayMode.CHATS) {
                            appState.setShowChatsTab(null)
                        } else {
                            appState.setShowChatsTab()
                        }

                        if (isDisplayMode(CommunicationCenterDisplayMode.CHATS)) {
                            resetBadgeColors()
                        }
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCChatIcon}
                        margin={
                            !appState.isNetworkingOpen() && props.unreadArray.conversations > 0
                                ? "-5px"
                                : !appState.isNetworkingOpen() && props.unreadArray.conversations <= 0
                                ? "-30px"
                                : appState.isNetworkingOpen() && props.unreadArray.conversations > 0
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCChats({
                            fill:
                                isDisplayMode(CommunicationCenterDisplayMode.CHATS) &&
                                appState.isNetworkingOpen() &&
                                !branding.communicationArea.disableCCChatIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.conversations <= 0 ? null : (
                        <h6>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCChatIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: conversationsColor
                                }}
                            >
                                {props.unreadArray.conversations}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </OverlayTrigger>

            <OverlayTrigger
                placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="schedule-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {strings.communicationArea.scheduleTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCMeetingIcon}
                    className={itemClasses(CommunicationCenterDisplayMode.SCHEDULE)}
                    onClick={() => {
                        appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SCHEDULE)

                        if (isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE)) {
                            resetBadgeColors()
                        }
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCMeetingIcon}
                        margin={
                            !appState.isNetworkingOpen() && props.unreadArray.schedules > 0
                                ? "-5px"
                                : !appState.isNetworkingOpen() && props.unreadArray.schedules === 0
                                ? "-30px"
                                : appState.isNetworkingOpen() && props.unreadArray.schedules > 0
                                ? "20px"
                                : "0px"
                        }
                    >
                        {IconCCSchedule({
                            fill:
                                isDisplayMode(CommunicationCenterDisplayMode.SCHEDULE) &&
                                appState.isNetworkingOpen() &&
                                !branding.communicationArea.disableCCMeetingIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                    {props.unreadArray.schedules <= 0 ? null : (
                        <h6>
                            <BadgeUnreadCounter
                                visible={branding.communicationArea.disableCCMeetingIcon}
                                className="badge badge-pill badge-dark"
                                style={{
                                    backgroundColor: meetingsColor
                                }}
                            >
                                {props.unreadArray.schedules}
                            </BadgeUnreadCounter>
                        </h6>
                    )}
                </CommunicationAreaNavigationItem>
            </OverlayTrigger>

            <OverlayTrigger
                placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                delay={{ show: 250, hide: 400 }}
                overlay={
                    <Tooltip id="notifications-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                        {strings.communicationArea.notificationsTabTitle}
                    </Tooltip>
                }
            >
                <CommunicationAreaNavigationItem
                    disabled={branding.communicationArea.disableCCNotificationIcon}
                    className={itemClasses(CommunicationCenterDisplayMode.NOTIFICATIONS)}
                    onClick={() => {
                        appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.NOTIFICATIONS)
                    }}
                >
                    <IconContainer
                        disabled={branding.communicationArea.disableCCNotificationIcon}
                        margin={!appState.isNetworkingOpen() ? "-26px" : "0px"}
                    >
                        {IconCCNotifications({
                            fill:
                                isDisplayMode(CommunicationCenterDisplayMode.NOTIFICATIONS) &&
                                appState.isNetworkingOpen() &&
                                !branding.communicationArea.disableCCNotificationIcon
                                    ? branding.activeIconColor
                                    : branding.sideIconBar.inactiveItemColor
                        })}
                    </IconContainer>
                </CommunicationAreaNavigationItem>
            </OverlayTrigger>

            <DummyFlexItem />

            {!appState.isNetworkingOpen() && (
                <CommunicationAreaNavigationItem onClick={appState.toggleNetworking}>
                    <NetworkingToggleIcon>
                        {IconNetworkingToggleOpen({ fill: branding.sideIconBar.sideIconColorDark })}
                    </NetworkingToggleIcon>
                </CommunicationAreaNavigationItem>
            )}

            {appState.isNetworkingOpen() && (
                <OverlayTrigger
                    placement={appState.isNetworkingOpen() ? "bottom" : "left"}
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="settings-tooltip" style={{ pointerEvents: "none", fontFamily: branding.font1 }}>
                            {strings.communicationArea.settingsTitle}
                        </Tooltip>
                    }
                >
                    <CommunicationAreaNavigationItemAvatar
                        className={itemClasses(CommunicationCenterDisplayMode.SETTINGS)}
                        onClick={() => {
                            appState.setCommunicationCenterDisplayMode(CommunicationCenterDisplayMode.SETTINGS)
                        }}
                    >
                        <AvatarRootArea>
                            <AvatarWithPresenceState
                                avatarSize={30}
                                badgeSize={10}
                                badgeRight={25}
                                badgeTop={18}
                                showAvatarBadge={true}
                                userId={userState.user()?.profileId}
                                initPresenceByList={false}
                                userType={userState.user()?.type}
                                content={{
                                    pictureUrl: userState.user()?.logoUrl,
                                    alt: [userState.user()?.firstName, userState.user()?.lastName].filter(Boolean).join(" ")
                                }}
                            ></AvatarWithPresenceState>
                        </AvatarRootArea>
                    </CommunicationAreaNavigationItemAvatar>
                </OverlayTrigger>
            )}
        </NavigationAreaRoot>
    )
}

interface ContentProps {
    unreadArray: {
        requests: number
        contacts: number
        conversations: number
        schedules: number
    }
}

const Content: React.FunctionComponent<ContentProps> = (props) => {
    const appState = useAppState()

    switch (appState.communicationCenterDisplayMode) {
        case CommunicationCenterDisplayMode.NETWORKING:
            return <NetworkingTab requests={props.unreadArray.requests} contacts={props.unreadArray.contacts} />
        case CommunicationCenterDisplayMode.CHATS:
            return <ChatsTab conversations={props.unreadArray.conversations} />
        case CommunicationCenterDisplayMode.SCHEDULE:
            return <ScheduleTab schedules={props.unreadArray.schedules} />
        case CommunicationCenterDisplayMode.NOTIFICATIONS:
            return <NotificationsTab />
        case CommunicationCenterDisplayMode.SETTINGS:
            return <SettingsTab />
        default:
            return null
    }
}

export const SearchBarWidth = "35ch"

export const TabContentArea = styled.div`
    margin-top: 15px;
    width: 100%;
`

export const TabEmpty = styled.div`
    padding-top: 30px;
    margin-left: 7%;
    margin-right: 7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    font-family: ${branding.font1};
    font-size: 15px;
    color: ${branding.mainInfoColor ?? "black"};
`

export const MultiSwitchRoot = styled.div`
    z-index: 0;
    margin-top: 20px;
`

export const TabRoot = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 80%;
    flex-grow: 1;
    .person-details {
        color: ${branding.mainInfoColor ?? "black"};
    }
    .expanded {
        color: ${branding.primaryColor ?? "black"};
    }
`

export const SubTabRoot = styled.div<{ tabType?: ContactEntryType }>`
    display: flex;
    flex-direction: column;
    //padding-top: 5px;
    height: ${(props) => (props.tabType === ContactEntryType.CONTACT ? "calc(100vh - 246px)" : "calc(100vh - 215px)")};
    overflow-x: hidden;
`

export const NextPageLoader = styled(CenteredLoader)`
    height: 120px;
    min-height: 120px;
`

export default CommunicationArea

/*********************************************************************************************
 * Profile image
 **********************************************************************************************/
const ProfileImageContainer = styled.div`
    padding-bottom: 10px;
    padding-right: 4px;
    margin-top: 0px;

    & .profile-img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const ProfileImageBiggerContainer = styled.div`
    padding-bottom: 10px;
    padding-right: 4px;
    margin-top: 0px;

    & .profile-img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        background-color: #f7f7fa;
    }
`

const TabFooterRoot = styled.div`
    position: fixed;
    background: #fff;
    height: 60px;
    width: 350px;
    bottom: 0;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 20px;
    border-top: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    z-index: 10;
`

const NetworkingToggleRoot = styled.div`
    cursor: pointer;
    float: left;
    max-width: 40px;
    width: 20px;
    text-align: center;
    margin-top: 15px;
    margin-left: 20px;
`

const NetworkingToggleIconFooter = styled.div`
    z-index: 2;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    /* margin: 15px 10px 0 15px; */
    &:hover .NavItem {
        fill: gray;
    }
    .networkingCollapsed & {
        transform: rotate(180deg) scale(1);
        margin-right: 20px;
    }
`

const FooterLogo = styled.img`
    position: relative;
    height: ${branding.communicationArea.organizerLogoHeight ?? "20px"};
    margin-top: ${branding.communicationArea.organizerLogoMarginTop ?? "15px"};
    margin-left: ${branding.communicationArea.organizerLogoMarginLeft ?? "80px"};
    object-fit: contain;
`

export const TabFooter: React.FunctionComponent = () => {
    const appState = useAppState()
    const logo: string = "/branding/organizer_logo.png"

    return (
        <TabFooterRoot>
            <NetworkingToggleRoot onClick={appState.toggleNetworking}>
                <NetworkingToggleIconFooter>
                    {IconNetworkingToggleClose({ fill: branding.sideIconBar.sideIconColorDark })}
                </NetworkingToggleIconFooter>
            </NetworkingToggleRoot>
            <FooterLogo className="cc-footer-logo" src={logo} />
        </TabFooterRoot>
    )
}

interface ProfileImageProps {
    imageUrl: string
    profileId: string
    biggerContainer: boolean
    setLogoUrl?: Function
}

export function ProfileImage(props: ProfileImageProps) {
    const [imagePreview] = useState("")
    const [imageLoading] = useState(false)

    return props.biggerContainer ? (
        <ProfileImageBiggerContainer>
            <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
                {imageLoading && <CenteredLoader />}
            </div>
        </ProfileImageBiggerContainer>
    ) : (
        <ProfileImageContainer>
            <div className="profile-img" style={{ backgroundImage: `url("${imagePreview || props.imageUrl}")` }}>
                {imageLoading && <CenteredLoader />}
            </div>
        </ProfileImageContainer>
    )
}
