import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { SelectThemeCustom } from "../myprofile/EditMyProfileLayout"
import Select, { OptionTypeBase } from "react-select"
import { useHallplanLink } from "./HallplanNavLink"
import hallplan, { Hall } from "./HallplanWrapper"
import { device } from "../../utils/Device"
import branding from "../../branding/branding"

export interface HallSelectProps {
    selectedHall?: string
}
export default function HallSelect(props: HallSelectProps) {
    const hallLink = useHallplanLink()
    const [halls, setHalls] = useState<Hall[]>(hallplan.getHalls() ?? [])

    useEffect(() => {
        hallplan.addHallsChangedHandler(setHalls)
        return () => {
            hallplan.removeHallsChangedHandler(setHalls)
        }
    }, [])

    const options: { value: string; label: string }[] = []
    halls.map((hall) => options.push({ label: hall.getName(), value: hall.getId() }))
    let selectedValue = options[0] ?? undefined

    const hall = hallplan.getHall(props.selectedHall)
    if (hall) {
        selectedValue = { label: hall.getName(), value: hall.getId() }
    }

    return (
        <HallSelectRoot
            options={options}
            value={selectedValue}
            onChange={(value: OptionTypeBase) => {
                if (value !== null) {
                    hallLink.goTo(value.value)
                }
            }}
            theme={SelectThemeCustom}
        />
    )
}
const HallSelectRoot = styled(Select)`
    width: 200px;
    font-family: ${branding.font1};

    font-size: 12px;
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};

    @media ${device.tablet} {
        flex: 0 200px;
    }

    @media ${device.laptop} {
        flex: 0 250px;
    }

    @media ${device.mobile} {
        font-size: 12px;
    }

    & > div[class*="menu"] {
        margin-top: -2px;
    }
`
