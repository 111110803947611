import React from "react"
import { NavLink, useHistory } from "react-router-dom"
import styled from "styled-components"
import { hallplanRoute, hallplanOverviewRoute } from "../../navigationArea/RoutePaths"

const DetailNavLinkRoot = styled(NavLink)`
    cursor: pointer;
    ${(props) => (props.width ? `width: ${props.width};` : "")}
    &:hover, &:active, &:visited, &:link, &:focus {
        ${(props: HallplanNavLinkProps) => (props.showLinkStyles ? "" : "color: inherit;text-decoration: none;")}
    }
`

type HallplanNavLinkProps = {
    hall: string
    stand: string
    showLinkStyles?: boolean
    width?: string
    disabled?: boolean
}

export const HallplanNavLink: React.FC<HallplanNavLinkProps> = (props) => {
    return (
        <DetailNavLinkRoot to={buildHallplanLink(props.hall, props.stand)} className="detailNavLink" {...props}>
            {props.children}
        </DetailNavLinkRoot>
    )
}

export const buildHallplanLink = (hall?: string, stand?: string): string => {
    if(!hall)
        return hallplanOverviewRoute
    return hallplanRoute.replace(":hall?", hall).replace(":stand?", stand ?? "")
}

export function useHallplanLink() {
    const history = useHistory()

    return {
        goTo: (hall?:string, stand?:string) => {
            history.push({
                pathname: buildHallplanLink(hall, stand)
            });
        }
    }
}