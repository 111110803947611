import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import { DetailNavLink } from "../detailPages/DetailNavLink"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { DesktopVersionContainer } from "../../utils/Device"

const GuestUserBannerRoot = styled.div`
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    width: 100%;
    height: 50px;
    background: ${branding.guestUserBanner.bannerBgColor};
    color: ${branding.guestUserBanner.bannerFgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: pre-wrap;
`
const BannerTextDiv = styled.div`
    font-family: ${branding.font1};
    display: flex;
    justify-content: center;
    align-items: center;

    span {
        font-size: 18px;
    }

    .detailNavLink {
        font-size: 18px;
        text-decoration: underline;
    }
`

interface GuestUserBannerProps {
    setRef?: Function
}

const GuestUserBanner: React.FunctionComponent<GuestUserBannerProps> = (props) => {
    const user = useLoggedInState().user()
    const strings = useLanguageState().getStrings()
    const guestBannerRef = useRef<HTMLDivElement>(null)
    const organization = user?.invitingOrganization ? user.invitingOrganization : null

    useEffect(() => {
        if (guestBannerRef && guestBannerRef.current && props.setRef) props.setRef(guestBannerRef)
        // eslint-disable-next-line
    }, [guestBannerRef])

    let bannerText: string | undefined
    if (organization) {
        if (user?.type === "guest") bannerText = strings.topBannerConfig.guest.bannerTextOrga
        else if (user?.type === "attendee") bannerText = strings.topBannerConfig.attendee.bannerTextOrga
        else if (user?.type === "staff") bannerText = strings.topBannerConfig.staff.bannerTextOrga
        else if (user?.type === "speaker") bannerText = strings.topBannerConfig.speaker.bannerTextOrga
        else if (user?.type === "none") bannerText = strings.topBannerConfig.none.bannerTextOrga
    } else {
        if (user?.type === "guest") bannerText = strings.topBannerConfig.guest.bannerTextNoOrganization
        else if (user?.type === "attendee") bannerText = strings.topBannerConfig.attendee.bannerTextNoOrganization
        else if (user?.type === "staff") bannerText = strings.topBannerConfig.staff.bannerTextNoOrganization
        else if (user?.type === "speaker") bannerText = strings.topBannerConfig.speaker.bannerTextNoOrganization
        else if (user?.type === "none") bannerText = strings.topBannerConfig.none.bannerTextNoOrganization
    }

    if (bannerText) {
        return (
            <DesktopVersionContainer>
                <GuestUserBannerRoot className="user-banner-root" ref={guestBannerRef}>
                    <BannerTextDiv>
                        <span>{bannerText}</span>
                        {organization && (
                            <DetailNavLink id={organization.id} name={organization.name} type="organization">
                                {organization.name}
                            </DetailNavLink>
                        )}
                    </BannerTextDiv>
                </GuestUserBannerRoot>
            </DesktopVersionContainer>
        )
    }

    return null
}

export default GuestUserBanner
