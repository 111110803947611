import React from "react"
import { News } from "../../../backendServices/Types"
import styled from "styled-components"
import branding from "../../../branding/branding"
import BookmarkWithToggle from "../../BookmarkWithToggle"
import { useNavigationSource } from "../../../tracking/NavigationSource"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { IconLinkRightArrow } from "../../Icons"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { device } from "../../../utils/Device"
import { NavLink } from "react-router-dom"

interface NewsComponentProps {
    news: News
}
export function NewsComponent(props: NewsComponentProps) {
    const { news } = props
    const nav = useNavigationSource()
    const strings = useLanguageState().getStrings()

    return (
        <NewsComponentRoot onClick={() => nav.set("LOBBY")} to={buildDetailLink(news.id, news.name, "news")}>
            <BookmarkWithToggle
                className={"show-on-hover exhibitor-bookmark"}
                newBookmarkItem={true}
                type={"news"}
                id={news.id}
                name={news.name}
                color={branding.receptionPage.myFairBookmarkTileIconColor}
                stroke={branding.receptionPage.myFairBookmarkTileIconColor}
            ></BookmarkWithToggle>
            {/* <TileLogo url={news.pictureURL ?? news.logoUrl} alt={news.name}/> */}
            <NewsName>{news.name}</NewsName>
            <VisitNewsLink className={"show-on-hover"}>{strings.receptionPage.visitNewsText}</VisitNewsLink>
            <VisitNewsLink className={"show-on-hover"} style={{ visibility: device.mobile ? "hidden" : "visible" }}>
                {IconLinkRightArrow({
                    stroke: branding.receptionPage.myFairBookmarkTileTextColor,
                })}
            </VisitNewsLink>
        </NewsComponentRoot>
    )
}

const NewsName = styled.div`
    font-size: 16px;
    font-family: ${branding.font1};
    color: #000;
    text-decoration: none;
    text-align: center;

    @media ${device.mobile} {
        font-size: 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

const VisitNewsLink = styled.span`
    font-family: ${branding.font1};
    display: inline-block;
    text-decoration: none;
    position: absolute;
    width: 80%;
    z-index: 1;
    bottom: 10px;
    left: 45%;
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
    text-align: center;
    @media ${device.mobile} {
        font-size: 8px;
        left: 50%;
    }
`

const NewsComponentRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 95px;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 10px;
    margin: 0 0 1.25rem 0;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: inline-block;
    }

    & .show-on-hover {
        display: none;
        color: ${branding.crsTabs.defaultActionItemColor};
    }

    & .exhibitor-bookmark {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    ${VisitNewsLink} + ${VisitNewsLink} {
        left: 80%;
    }

    @media ${device.tablet} {
        flex-basis: 250px;
        width: 250px;
        height: 250px;
        padding: 20px;
    }
`
