import React from "react"
import ChatDummy from "./ChatDummy"
import ChatRoomAreaTab from "./ChatRoomArea"
import { AccessProvider, EventDate } from "../backendServices/Types"
import { useUserRestrictedAreaAccess } from "../globalStates/UserRestrictedAreaAccess"
import CenteredLoader from "../ui/CenteredLoader"

interface ChatProps {
    eventDate: EventDate
    channelAlias?: string
    hideQnA?: boolean
}

const Chat: React.FC<ChatProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    let content = null
    if (!userAccessState.isLoaded(props.eventDate)) content = <CenteredLoader />
    else
        content = (
            <>
                {
                    // if event is not private or user has access,
                    // we can display real Chat component
                    // otherwise, we will display dummy Chat

                    props.eventDate.accessProvider === AccessProvider.PUBLIC || userAccessState.isUnlocked(props.eventDate) ? (
                        <ChatRoomAreaTab
                            chatRoom={props.eventDate.id}
                            slidoName={props.eventDate.channel?.slidoName ?? props.eventDate.location}
                            channelId={props.channelAlias}
                            hideQnA={props.hideQnA}
                        />
                    ) : (
                        <ChatDummy accessProvider={props.eventDate.accessProvider} />
                    )
                }
            </>
        )

    return <div className="h-100 w-100">{content}</div>
}

export default Chat
