import React from "react"
import { News } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../WindowDimensionsHook"
import branding from "../../branding/branding"
import { IconNews } from "../Icons"
import { newsPageRoute } from "../../navigationArea/RoutePaths"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import TileRow from "../TileRow"
import VizSensor from "react-visibility-sensor"
import { NewsComponent } from "./components/NewsComponent"

interface NewsCarouselProps {
    title?: string
    linkText?: string
    link?: string
    onBeforeLinkClick?: () => void
    news: News[]
}
export function NewsCarousel(props: NewsCarouselProps) {
    const { news } = props
    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    // Let's take props over defaults
    const title = props.title ?? strings.navigationArea.newsItemTitle
    const icon = <IconNews fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />
    const linkText = props.linkText ?? strings.receptionPage.newsNavLinkText
    const link = props.link ?? newsPageRoute

    let content
    if (news.length === 0) {
        content = <EmptyTile header={title} buttonNavLink={link} buttonMessage={linkText} hideButton={true} bgColor="#F2F2F2" />
    } else {
        const widthHeight = isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 }
        content = news.map((news, index) => (
            <VizSensor key={index} partialVisibility={true}>
                {({ isVisible }) => (isVisible ? <NewsComponent news={news} /> : <div style={widthHeight}></div>)}
            </VizSensor>
        ))
    }

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkText={linkText}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            height={isMobile ? "95px" : "220px"}
            scrollBar={news && news.length > 0}
            isMyFairPage
            hideShadows={isMobile}
            childWidth={isMobile ? 95 : 250 + 9}
            childCount={news.length ?? 0}
        >
            {content}
        </TileRow>
    )
}
