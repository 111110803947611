import React from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"
import branding from "../../branding/branding"
import { device } from "../../utils/Device"

const EmptyTileWrapper = styled.div<{ backgroundColor: string }>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 10px);
    height: 95px;
    background-color: ${(props) => props.backgroundColor};
    color: ${branding.primaryColor ?? "black"};
    font-family: ${branding.font1};
    border-radius: 5px;

    @media ${device.tablet} {
        font-size: 16pt;
        width: 100%;
        height: 180px;
    }

    @media ${device.mobile} {
        width: calc(100% - 10px);
        height: 95px;
    }
`

const EmptyTileRow = styled.div`
    /* height: 45%; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-family: ${branding.font1};
    color: ${branding.receptionPage.emptyTileTextColor};
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
`

type OpenMoreBtn = {
    message: string
    navLink: string
}

const EmptyTileButton: React.FunctionComponent<OpenMoreBtn> = (props) => {
    return <EmptyTileButtonPanel to={props.navLink}>{props.message}</EmptyTileButtonPanel>
}

const EmptyTileButtonPanel = styled(Link)`
    display: block;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 26px;
    border-radius: 40px;
    border: 1px solid;
    border-color: ${branding.primaryColor ?? "#000"};
    background-color: transparent;
    color: ${branding.primaryColor ?? "#000"};
    padding: 0.4rem 2rem;

    &:hover {
        background-color: ${branding.primaryColor ?? "#000"};
        color: #fff;
        text-decoration: none;
    }
`

interface EmptyTileProps {
    header: string
    buttonNavLink?: string
    buttonMessage?: string
    bgColor: string
    hideButton?: boolean
}

const EmptyTile: React.FunctionComponent<EmptyTileProps> = (props: EmptyTileProps) => {
    return (
        <EmptyTileWrapper backgroundColor={props.bgColor}>
            <EmptyTileRow>
                {props.header}
                {props.hideButton ? null : (
                    <EmptyTileButton
                        navLink={props.buttonNavLink ? props.buttonNavLink : ""}
                        message={props.buttonMessage ? props.buttonMessage : ""}
                    />
                )}
            </EmptyTileRow>
        </EmptyTileWrapper>
    )
}

export default EmptyTile
