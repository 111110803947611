import React from "react"
import styled from "styled-components"
import { IconLock, IconTicket } from "../../ui/Icons"
import branding from "../../branding/branding"
import { useAppState } from "../../globalStates/AppState"
import { useHistory } from "react-router-dom"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import { ResizeState } from "./PictureInPictureVideoPlayer"
import { AccessProvider } from "../../backendServices/Types"

const PictureInPictureDummyPlayerRoot = styled.div`
    position: absolute;
    z-index: 100;
    background: #727272;
    cursor: grab;
`

const LockIconAndTextRoot = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 20px;
    text-align: center;
    font-family: ${branding.font1};
    pointer-events: none;
`
const LockIconRoot = styled.div`
    width: 3vw;
    height: 3vw;
    // this is necessary for Draggable.onStop() to make sure player position is saved even if lock icon is clicked for dragging
    pointer-events: none;
`

export interface PictureInPictureVideoPlayerDummyProps {
    resizeState: ResizeState
    accessProvider?: AccessProvider | undefined
}

const PictureInPictureVideoPlayerDummy: React.FC<PictureInPictureVideoPlayerDummyProps> = (props) => {
    const appState = useAppState()
    const history = useHistory()
    const strings = useLanguageState().getStrings()
    const displayTicketIcon = props.accessProvider === AccessProvider.TICKET

    return (
        <PictureInPictureDummyPlayerRoot
            title={strings.videoPlayerBranding.pipDoubleClickHint}
            onDoubleClick={() => {
                const channelId = appState.liveStreamChannel?.id
                if (channelId) history.push(buildDetailLink(channelId, "", "channel"))
            }}
            style={{
                width: props.resizeState.width,
                height: props.resizeState.height,
            }}
        >
            <LockIconAndTextRoot>
                <LockIconRoot>
                    {displayTicketIcon
                        ? IconTicket({ width: "auto", height: "auto", fill: "#fff" })
                        : IconLock({ width: "auto", height: "auto", fill: "#fff" })}
                </LockIconRoot>
                <div style={{ marginTop: "10px" }}>{strings.videoPlayerBranding.dummyVideoPlayerNoAccessMessage}</div>
            </LockIconAndTextRoot>
        </PictureInPictureDummyPlayerRoot>
    )
}

export default PictureInPictureVideoPlayerDummy
