import de from "date-fns/locale/de"
import en from "date-fns/locale/en-GB"
import moment from "moment"
import React, { ChangeEvent, useEffect, useRef, useState } from "react"
import { Button, Dropdown, DropdownButton, Form, Modal, NavLink, OverlayTrigger, Tooltip } from "react-bootstrap"
import DatePicker, { registerLocale } from "react-datepicker"
import ReactDOM from "react-dom"
import styled from "styled-components"
import { CalendarEntryParticipationStatus, ConversationType } from "../API"
import {
    CalendarEntries,
    CalendarEntry,
    createNewCalendarEntry,
    createNewCalendarEntryParticipation,
    deleteCalendarEntryForUser,
    deleteCalendarEntryParticipationById,
    getCalendarEntriesAcceptedBetweenDates,
    trackVisit,
    updateCalendarEntryById,
    updateCalendarEntryParticipationStatus
} from "../backendServices/BackendServices"
import { EventDate, InvitePerson } from "../backendServices/Types"
import branding from "../branding/branding"
import { ChatConversationParam } from "../communicationArea/ChatPage"
import { saveMeetings } from "../communicationArea/ICal"
import SearchPersonInput from "../communicationArea/SearchPersonInput"
import { useChimeContext } from "../conference/context/ChimeContext"
import { buildDetailLink } from "../contentArea/detailPages/DetailNavLink"
import { useAppState } from "../globalStates/AppState"
import { useLanguageState } from "../globalStates/LanguageState"
import { useLoggedInState } from "../globalStates/LoggedInUser"
import useComponentVisible from "../navigationArea/useComponentVisible"
import {
    findTimezoneName,
    getIndexOfInitialDayToShow,
    getTimezones,
    isBeforeToday,
    isToday,
    momentWithoutTimezoneFromTimezonedMoment,
    Timezone
} from "../utils/DateUtils"
import { isExplorationOrPostEventPhase } from "../utils/EventPhaseChecker"
import { AvatarWithDefault } from "./AvatarWithDefault"
import CenteredLoader from "./CenteredLoader"
import DataUpdateAlert from "./DataUpdateAlert"
import DeleteCalendarEntryModal from "./DeleteCalendarEntryModal"
import {
    IconArrowDownTimezoneDropdown,
    IconArrowLeft,
    IconArrowUpTimezoneDropdown,
    IconCalendarEntry,
    IconCheckmarkGreen,
    IconCheckmarkSuccessfullMini,
    IconChevronLeftSolid,
    IconChevronRightSolid,
    IconClose,
    IconCloseFilled,
    IconCloseRed,
    IconIndicatorErrorBlack
} from "./Icons"
import PersonChip from "./PersonChip"
import { MeetingSlotsDay, TimeSlotType } from "./MeetingSlots"
import { isSlotDisabled } from "../helper/MeetingSlotsHelper"
import TextLimit from "./TextLimit"

registerLocale("en-GB", en)

export enum CalendarEntryModalViewMode {
    CREATE,
    EDIT,
    VIEW
}

const ModalRootDiv = styled.div`
    width: 600px;
`

const WhenTimeContent = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    a:focus {
        opacity: 0.5;
    }

    .rs-picker-date {
        border: none;
        border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
        border-radius: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
    .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
        color: ${branding.calendarEntryModalPageContent.datePickerMenuHeaderColor ?? "#1675e0"};
    }
`
const Title = styled.div`
    /* text-overflow: ellipsis;
    font-weight: bold;
    overflow: hidden;
    margin-top: 9px;
    font-size: 26px;
    font-family: ${branding.font1}; */
    width: auto;
    height: 20px;
    top: 35px;
    left: 30px;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-family: ${branding.font1};
    font-weight: bold;
`
const SubmitButtonContainerLinks = styled.div`
    /* display: flex;
    justify-content: space-between;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 30px;
    margin-bottom: 30px;
    font-size: 12px;
    font-family: ${branding.font1}; */
    padding: 15px 0;
    /* margin-left: -35px;
    margin-right: -35px; */
    display: flex;
    position: relative;
    color: #202428;
    font-size: 0.75rem;
    font-family: ${branding.font1};
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    width: 100%;
    height: 40px;
`

const ChatWithParticipantsDiv = styled.div`
    display: flex;
    margin-left: -14px;
    margin-right: -14px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 12px;
    font-family: ${branding.font1};
    color: "#727272";
`

const SubmitButtonContainer = styled.div`
    /* display: flex;
    justify-content: space-between;
    margin-left: -35px;
    margin-right: -35px;
    margin-top: 30px;
    margin-bottom: 32px; */
    /* flex: 1; */
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
    margin-top: 30px;

    /* .submitButtonInput-root {
        display: flex;
        align-items: center;
    } */
`

const CancelMeetingContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-start;
`

const OtherButtonsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
`
const SelectDateRoot = styled.div`
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    flex-flow: row;
    position: relative;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    cursor: pointer;
    font-size: 16px;
    line-height: 17px;
    height: 30px;

    .dayInput-root {
        position: absolute;
        right: 30px;
    }

    .react-datepicker__input-container::placeholder {
        color: #727272;
    }

    .react-datepicker__current-month {
        font-family: ${branding.font1};
        font-size: 12px !important;
        line-height: 24px !important;
        color: #727272 !important;
    }

    .react-datepicker__day-name {
        font-family: ${branding.font1};
        font-size: 10px;
        line-height: 16px;
        align-items: center;
        color: #727272 !important;
    }

    .meetingDate {
        color: ${branding.calendarEntryModalPageContent.calendarTheme};
        border-radius: 2px !important;
        border-color: ${branding.calendarEntryModalPageContent.calendarTheme} !important;
        background: none !important;
        border: 0;
        outline: none;
    }

    .nonMeetingDate {
        border-radius: 50% !important;
        border-color: #000 !important;
        background: none !important;
        border: 0;
        outline: none;
    }

    .past-dates-disabled {
        color: #c9c9c9;
        cursor: default;
    }

    .react-datepicker__day--selected {
        border: 1px solid !important;
        border: 1px solid !important;
        background-color: #6dd400 !important;
        color: #fff !important;
        border-radius: 3px !important;
        cursor: pointer;
    }

    .react-datepicker {
        border: 1px solid #727272;
        border-radius: 5px;
    }

    .react-datepicker__day-names {
        font-weight: bold;
    }

    .react-datepicker-wrapper {
        display: flex;
        flex: 1;
    }

    .react-datepicker__header {
        border: 0 !important;
        background-color: #fff;
        padding-top: 5px;
    }

    .react-datepicker__navigation--previous {
        left: 10px;
        background: url(../branding/icons/chevron-left-solid-bg.svg) no-repeat;
        border: none;
        height: 15px;
    }

    .react-datepicker__navigation--next {
        right: 10px;
        background: url(../branding/icons/chevron-right-solid-bg.svg) no-repeat;
        border: none;
        height: 15px;
    }

    .btn {
        text-align: left;
        width: 600px;
        box-shadow: none;
    }

    span {
        position: absolute;
        right: 5px;
    }

    .btn-primary {
        border: none;
        border-bottom: 1px solid ${branding.mainInfoColor};
        border-radius: 0px;
        padding-left: 0px;
        font-size: inherit;
        cursor: default;
        color: #8e8e93;
        background-color: #fff;
        box-shadow: none;
    }

    .btn-primary:hover {
        background-color: #fff;
    }

    .btn-primary a:hover {
        background: ${branding.mainInfoColor};
    }

    .btn-primary:active,
    .btn-primary.active {
        outline: unset;
        border: none;
        background-color: #fff;
    }

    .btn .btn-primary:focus {
        outline: unset !important;
        box-shadow: none;
        border: none;
    }
    .btn-primary:not(:disabled):not(.disabled):active,
    .show > .btn-primary.dropdown-toggle {
        color: ${branding.mainInfoColor};
        background-color: #fff;
        border-color: #fff;
        border: none;
    }
    .dropdown-item {
        width: 600px;
        font-size: 14px;
    }
    .dropdown-item:focus {
        background-color: #fff;
        color: ${branding.mainInfoColor};
    }

    .dropdown-toggle::after {
        content: none;
    }
`
const PickersGroupRoot = styled.div`
    margin-bottom: 24px;
    position: relative;

    .nameInput-root {
        position: absolute;
        bottom: 5px;
        right: 5px;
    }

    .form-control {
        border: none;
        border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
        border-radius: 0px;
        padding-left: 0px;
        color: #000;
        font-size: 16px;
        line-height: 17px;
        cursor: default;
        padding: 0;
        height: 30px;
    }

    .form-control:focus {
        outline: none;
        box-shadow: none;
    }

    .rs-picker-date {
        border-bottom: 1px solid ${branding.mainInfoColor};
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-left: 0px;
    }

    .rs-picker-cleanable.rs-picker-date .rs-picker-toggle.rs-btn {
        border: none;
    }

    .rs-picker-default .rs-picker-toggle.rs-btn {
        border: none;
    }
`

const StyledNotesInput = styled(Form.Control)`
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    resize: none;
    width: 100%;
    cursor: default;
    padding: 15px;
    overflow-y: scroll;
    font-size: 12px;
    line-height: 20px;
    color: #202428;
    margin-bottom: -10px;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: #727272 !important;
        /* padding: 5px; */
    }
`

const PeopleDisplayArea = styled.div`
    margin-top: 15px;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
`
// const SubmitButtonDanger = styled.div`
//     width: 50%;
//     height: 30px;
//     cursor: pointer;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     color: ${branding.primaryColor};

//     &:hover {
//         text-decoration: none;
//     }
// `
const CancelButton = styled(Button)<{ viewMode: CalendarEntryModalViewMode; customColor?: boolean }>`
    display: block;
    // eslint-disable-next-line
    width: ${(props) => (props.viewMode === CalendarEntryModalViewMode.CREATE ? "22%" : "40%")};
    height: 35px;
    /* margin-top: 20px; */
    color: ${(props) => (props.customColor ? "red" : branding.recommendModal.cancelBtnPrimaryTextColor)};
    background-color: transparent;
    border: 1px solid ${(props) => (props.customColor ? "red" : branding.recommendModal.cancelBtnPrimaryBorderColor)}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;

    &:hover {
        text-decoration: none;
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        border: none;
    }

    :active {
        outline: 0 !important;
        border: none !important;
    }
    :focus {
        outline: 0 !important;
        border: none !important;
    }
`
const SubmitButton = styled(Button)<{ viewMode: CalendarEntryModalViewMode }>`
    display: block;
    width: ${(props) => (props.viewMode === CalendarEntryModalViewMode.CREATE ? "22%" : "40%")};
    height: 35px;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 20px;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    /* border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important; */
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    }

    :active {
        outline: 0 !important;
        border: none !important;
    }
    :focus {
        outline: 0 !important;
        border: none !important;
    }
`

// const SubmitButton = styled(Button) <{ backgroundColor: string }>`
//     display: block;
//     width: 25%;
//     /* margin: auto; */
//     border-radius: 20px;
//     font-size: 12px;
//     background-color: ${props => props.backgroundColor};
//     color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
//     border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"};

//     :hover {
//       background-color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverBgColor ?? "#e8e8e8"} !important;
//       color: ${branding.sayHelloModal.submitBtnPrimaryOnHoverTextColor ?? "#000"} !important;}
//       border: 2px solid ${props => props.backgroundColor};
// `

// const SubmitButton = styled(Button)`
//     display: block;
//     width: 30%;
//     /* margin-top: 20px; */
//     color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
//     background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
//     border: 2px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
//     border-radius: 20px;
//     font-size: 12px;
//     font-family: ${branding.font1};

//     :hover {
//         background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
//         color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
//     }
// `

const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin-bottom: 24px;
`

const CalendarEntryMainModal = styled(Modal)<{ showAvailable: boolean }>`
    color: ${branding.mainInfoColor};
    font-family: ${branding.font1};
    .modal-dialog {
        width: 650px !important;
        max-width: 650px !important;
        min-width: 650px !important;
    }

    .modal-body {
        padding: 1rem 1rem 1rem 0rem;
    }

    .modal-footer {
        padding: 0rem;
        justify-content: center;
        margin-right: -30px;
        margin-left: -30px;
    }

    .modal-content {
        padding-left: 30px;
        padding-right: ${(props) => (props.showAvailable ? "0" : "0px")};
        width: ${(props) => (props.showAvailable ? "1100px" : "")};
        padding-top: 30px;
        padding-bottom: 0px;
        overflow: hidden;
        /* border-radius: 0px; */
        display: flex;
        flex-flow: row;
        /* border: 1px solid black; */
        background: #fff;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
    }

    .modal-header {
        padding: 35px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
    }

    .modal-header .modal-title {
        width: 100%;
        overflow: visible;
        white-space: normal;
        color: ${branding.mainInfoColor};
    }

    .btn .btn-primary:focus {
        outline: 0 !important;
    }
`

const CalendarEntryModalDatePicker = styled(DatePicker)`
    border: none;
    width: 100%;
    outline: none;
    color: #000;
    background-color: #fff;
    padding-left: 0;
    cursor: pointer;

    ::-webkit-input-placeholder {
        font-weight: 100;
    }

    ::-moz-placeholder {
        font-weight: 100;
    }

    :-moz-placeholder {
        font-weight: 100;
    }

    :-ms-input-placeholder {
        font-weight: 100;
    }
`

const TimePickerRoot = styled.div`
    display: flex;
    flex-flow: column;
    flex: 0.3;
    position: relative;

    .startTimeInput-root,
    .endTimeInput-root {
        position: absolute;
        right: 0;
        bottom: 6px;
    }

    .react-datepicker__triangle,
    .react-datepicker__header {
        display: none;
    }

    .react-datepicker__time,
    .react-datepicker__time-box,
    .react-datepicker__time-container {
        border-radius: 0;
        width: 70px !important;
    }

    .react-datepicker {
        border-radius: 0;
        border-color: black;
    }

    li {
        text-align: start;
        outline: none;
    }

    .react-datepicker__time-list-item--selected {
        background: ${branding.calendarEntryModalPageContent.calendarTheme} !important;
        color: black !important;
    }
`

const CalendarEntryTimePicker = styled(DatePicker)`
    border: none;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    outline: none;
    width: 100%;
    height: 35px;

    :focus {
        background-color: #d9d9d9;
        border-top-left-radius: 5px !important;
        border-top-right-radius: 5px !important;
    }
`
const CurrentTimeZoneTitle = styled.div`
    display: flex;
    flex-flow: row;
    padding-left: 12px;
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
    /* background-color: #2e2a2a; */
    align-items: center;
    height: 14px;
`

const TimezoneModalButton = styled.button<{ backgroundColor: string }>`
    display: block;
    width: 20%;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-weight: bold;
    background-color: ${(props) => props.backgroundColor};
    color: ${branding.sayHelloModal.submitBtnPrimaryTextColor ?? "#fff"};
    /* border: 2px solid ${branding.sayHelloModal.submitBtnPrimaryBorderColor ?? "#000"}; */
    border: 0;
    height: 25px;
    margin-top: 15px;
    margin-left: 250px;
`

const CloseButton = styled.div`
    /* margin-top: auto;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: 0px;
    padding-right: 1rem; */
    position: absolute;
    right: 30px;
    top: 30px;

    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`
// const DisagreeButton = styled.button`
//     width: 25%;
//     height: 35px;
//     cursor: pointer;
//     font-size: 12px;
//     font-family: ${branding.font1};
//     display: flex;
//     flex-flow: row;
//     align-items: center;
//     justify-content: center;
//     margin-right: 15px;
//     border-radius: 20px;
//     background-color: #fff;
//     color: #000;
//     border: 2px solid  #000;

//     :hover {
//       background-color: "#000";
//       color: "#fff"}
//       border: 2px solid #000;
// `
const ButtonLinks = styled(NavLink)`
    /* text-decoration: underline; */
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: 0 10px;
    /* padding: 0px; */
    /* margin-left: 15px;
    margin-right: 15px; */
    /* border-bottom: 1px solid ${branding.mainInfoColor}; */
    color: ${branding.primaryColor};

    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor};
    }

    &::after {
        content: "";
        display: block;
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #d9d9d9;
    }
`

const ChatWithParticipantsButtonLink = styled(NavLink)`
    /* text-decoration: underline; */
    padding: 0px;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: 1px solid #727272;
    color: #727272;
    &:hover {
        text-decoration: none;
        color: ${branding.mainInfoColor};
    }
`
const Label = styled.div`
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    height: 13px;
    font-family: ${branding.font1};
`

const NotesContainer = styled.div`
    margin-top: 30px;
    overflow-y: scroll;
`

const MAX_NOTES_LENGTH = 200

enum CalendarEntryParticipationStatusColor {
    ACCEPTED = "#00B300",
    DECLINED = "#FF0000",
    REQUESTED = "#000000"
}

function isDateInValid(day: Date | null, startDate: Date | null, endDate: Date | null): Boolean {
    if (day == null || startDate == null || endDate == null) {
        return true
    }
    // cannot create meeting in the past
    if (isBeforeToday(moment(day))) return true
    return startDate > endDate || endDate < startDate
}
interface TimezonePickerProps {
    viewMode: CalendarEntryModalViewMode
    setSelectedTimezone: (timezone: string) => void
    selectedTimezone: string
}

const CalendarEntryTimezonePicker: React.FC<TimezonePickerProps> = (props) => {
    const timezones = getTimezones()
    const appState = useAppState()
    const [selectedTimezone, setSelectedTimezone] = useState<string>(appState.timezone)
    const strings = useLanguageState().getStrings()
    const [openDropdown, setOpenDropdown] = useState<boolean>(false)
    const [timeZoneDropdown, setTimeZoneDropdown] = useState<boolean>(false)

    function onTimezoneSelect(item: Timezone) {
        setSelectedTimezone(item.timezones[0])
    }

    return (
        <TimezoneDropdownRoot>
            <Label>{strings.calendarEntryModalPageContent.timeZone}</Label>
            <TimezoneDropdownButton
                onClick={() => setOpenDropdown(!openDropdown)}
                disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
            >
                <p>
                    {props.selectedTimezone
                        ? findTimezoneName(props.selectedTimezone)
                        : strings.communicationArea.timezonePickerText}
                </p>
                {openDropdown
                    ? IconArrowUpTimezoneDropdown({ fill: branding.sideIconBar.sideIconColorDark, width: "15", height: "15" })
                    : IconArrowDownTimezoneDropdown({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
            </TimezoneDropdownButton>
            {openDropdown && (
                <TimezonePickerModalDialog>
                    <TimezoneModalHeader>
                        <TimezoneModalTitle>{strings.calendarEntryModalPageContent.timeZoneModalTitle}</TimezoneModalTitle>
                        <CloseIcon
                            style={{ marginTop: "-5px" }}
                            onClick={() => {
                                setOpenDropdown(false)
                                setTimeZoneDropdown(false)
                            }}
                        >
                            {IconClose({ fill: "#000", width: "10", height: "10" })}
                        </CloseIcon>
                    </TimezoneModalHeader>
                    <TimezoneModalBody onClick={() => setTimeZoneDropdown(!timeZoneDropdown)}>
                        <CurrentTimeZoneTitle>
                            {strings.calendarEntryModalPageContent.timeZoneDropdownCurrentTimezoneTitle}
                        </CurrentTimeZoneTitle>
                        <ArrowIcon>
                            {timeZoneDropdown
                                ? IconArrowUpTimezoneDropdown({
                                      fill: "#000",
                                      width: "15",
                                      height: "15"
                                  })
                                : IconArrowDownTimezoneDropdown({
                                      fill: "#000",
                                      width: "20",
                                      height: "20"
                                  })}
                        </ArrowIcon>
                        <TimezoneDropdown
                            show={timeZoneDropdown}
                            title={
                                selectedTimezone
                                    ? findTimezoneName(selectedTimezone)
                                    : strings.communicationArea.timezonePickerText
                            }
                        >
                            {timezones.map((item, key) => {
                                return (
                                    <TimezoneDropdownItem key={key} onClick={() => onTimezoneSelect(item)}>
                                        {item.name}
                                    </TimezoneDropdownItem>
                                )
                            })}
                        </TimezoneDropdown>
                    </TimezoneModalBody>
                    <TimezoneModalFooter>
                        <TimezoneModalButton
                            onClick={() => {
                                props.setSelectedTimezone(selectedTimezone)
                                setOpenDropdown(false)
                                setTimeZoneDropdown(false)
                            }}
                            backgroundColor={branding.sayHelloModal.submitBtnPrimaryBgColor}
                        >
                            {branding.sayHelloModal.meetingTimeZoneSubmitBtnText}
                        </TimezoneModalButton>
                    </TimezoneModalFooter>
                </TimezonePickerModalDialog>
            )}
        </TimezoneDropdownRoot>
    )
}

const TimezoneDropdownRoot = styled.div`
    position: relative;
    /* border-bottom: 1px solid #e8e8e8; */
    display: flex;
    flex-flow: column;
    width: 60%;
`

const TimezoneDropdownButton = styled(Button)`
    width: 100%;
    height: 35px;
    text-overflow: ellipsis;
    overflow: hidden;
    color: #727272 !important;
    background-color: #fff !important;
    border-color: #e8e8e8 !important;
    border-radius: 5px;
    border: 1px solid #c9c9c9 !important;
    font-size: 12px;
    line-height: 17px;
    outline: none !important;
    box-shadow: none !important;
    text-align: start;
    display: flex;
    flex-flow: row;

    p {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-bottom: 0px;
        margin-top: 2px;
    }

    svg {
        margin-top: 2px;
    }

    span {
        flex: 1;
        display: flex;
        justify-content: flex-end;
    }
`
const TimezoneDropdown = styled(DropdownButton)`
    /* background-color: #e8e8e8; */
    .dropdown-toggle {
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        padding-top: 0;
    }
    .dropdown-menu {
        overflow: scroll;
        width: 100%;
        height: 200px;
        border-color: #727272;
        /* border-radius: 5px;
         */
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        font-size: 12px;
        line-height: 17px;
    }

    .dropdown-toggle::after {
        display: none;
    }

    .btn,
    .btn-primary {
        background-color: #d9d9d9 !important;
        /* border-color: #e8e8e8 !important; */
        border-radius: 0;
        /* color: black !important;
        border: 1px solid; */
        border: none;
        color: #202428 !important;
        outline: none !important;
        text-align: start;
        box-shadow: none !important;
        font-size: 12px;
        line-height: 17px;
        height: 16px;
    }
`
const TimezoneDropdownItem = styled(Dropdown.Item)`
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;

    :active {
        background-color: #d9d9d9;
    }
`

const TimezonePickerModalDialog = styled(Modal.Dialog)`
    position: absolute;
    z-index: 6;
    left: 0px;
    top: 58px;
    margin-top: 11px;

    .modal-content {
        width: 350px;
        overflow: visible;
        display: flex;
        flex-flow: column;
        /* padding-left: 10px;
        padding-right: 10px; */
        border-color: #727272;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        /* padding-top: 5px; */
        padding: 15px;
    }
`
const TimezoneModalHeader = styled(Modal.Header)`
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0 !important;
    padding-top: 5px !important;
`

const TimezoneModalTitle = styled(Modal.Title)`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
`
const TimezoneModalBody = styled(Modal.Body)`
    position: relative;
    cursor: pointer;
    /* height: 50px; */
    background-color: #d9d9d9;
    border-radius: 5px;
    padding: 0;
    margin-top: 15px;
`

const TimezoneModalFooter = styled(Modal.Footer)`
    border: 0;
    padding: 0px;
`

const CloseIcon = styled.div`
    cursor: pointer;
`
const ArrowIcon = styled.div`
    position: absolute;
    top: 20px;
    right: 22px;
    z-index: 1;
`

const AvailabiltyButton = styled.button`
    display: flex;
    flex-flow: row;
    width: 20%;
    height: 36px;
    border-radius: 20px;
    font-weight: bold;
    margin: 5px;
    align-items: center;
    justify-content: space-evenly;
    border: 1px solid ${branding.calendarEntryModalPageContent.calendarTheme};
    font-size: 12px;
    background-color: ${branding.calendarEntryModalPageContent.calendarTheme};
    color: #fff;
`

export interface Obligations {
    userId: string
    meetings: CalendarEntries
    eventDates: EventDate[]
}
interface AvailableUsersProps {
    contactsInvited: InvitePerson[]
    timezone: string
    selectedDate?: Date
    startTime?: Date
    endTime?: Date
    editMode?: boolean
    close: () => void
}

export function dateWithUTCIncrasedByTimezone(timeZone: string, date?: Date, dateYMD?: Date) {
    if (date && timeZone) {
        const dateMoment = moment(date).tz(timeZone)
        dateMoment.set("year", dateYMD ? dateYMD.getFullYear() : date.getFullYear())
        dateMoment.set("month", dateYMD ? dateYMD.getMonth() : date.getMonth())
        dateMoment.set("date", dateYMD ? dateYMD.getDate() : date.getDate())
        dateMoment.set("hour", date.getHours())
        dateMoment.set("minute", date.getMinutes())
        return dateMoment.toDate()
    }
}

const AvailableUsers: React.FC<AvailableUsersProps> = React.memo((props) => {
    const [meetings, setMeetings] = useState<Obligations[]>([])
    const [loaded, setIsLoaded] = useState<boolean>(false)
    const [page, setPage] = useState<number>(0)
    const wrapperRefIcons = useRef<HTMLDivElement>(null)
    const scheduleContainerRef = useRef<HTMLDivElement>(null)
    const currentTimeAreaRef = useRef<HTMLDivElement>(null)
    const appTimezone = useAppState().timezone
    const startTimeOfDay = dateModification(props.selectedDate!)
    const endTimeOfDay = new Date(startTimeOfDay!.getTime() + 24 * 60 * 60 * 1000)
    const strings = useLanguageState().getStrings()

    const iconSize = 30
    const iconMarginLeft = 10
    const scheduleTimes = totalTimes()
    const pxPerMinute = 45 / 60
    const pageSize = 7

    function dateModification(selectedDate: Date) {
        selectedDate.setHours(0)
        selectedDate.setMinutes(0)
        selectedDate.setSeconds(0)
        const modificatedDate = dateWithUTCIncrasedByTimezone(props.timezone, selectedDate)
        return modificatedDate
    }

    function hasNext() {
        if ((page + 1) * pageSize < props.contactsInvited.length) {
            return true
        } else return false
    }

    function onLeftArrowClick() {
        if (page > 0) setPage(page - 1)
    }

    function onRightArrowClick() {
        if (hasNext()) setPage(page + 1)
    }

    useEffect(() => {
        async function loadObligations() {
            if (isDateInValid(props.selectedDate ?? null, props.startTime ?? null, props.endTime ?? null)) {
                setIsLoaded(true)
                return
            }
            const meetings: Obligations[] = []
            let nextToken
            for await (let contact of props.contactsInvited) {
                const resp = await getCalendarEntriesAcceptedBetweenDates(contact.id, startTimeOfDay!, endTimeOfDay, nextToken)
                const eventDatesTimezoneTransformed =
                    contact.eventDates?.map((ed) => {
                        ed.dateTimeStart = moment.tz(moment(ed.dateTimeStart).format(), appTimezone).format() //give timezone of app to start and time which is recieved from api call that has app timezone then parsed to calendar timezone
                        ed.dateTimeEnd = moment.tz(moment(ed.dateTimeEnd).format(), appTimezone).format()
                        return ed
                    }) ?? []
                meetings.push({
                    userId: contact.id,
                    meetings: (resp as CalendarEntries) ?? [],
                    eventDates: eventDatesTimezoneTransformed
                })
            }
            setMeetings(meetings)
            setIsLoaded(true)
        }

        if (wrapperRefIcons && scheduleContainerRef && wrapperRefIcons.current && scheduleContainerRef.current) {
            wrapperRefIcons.current.scrollLeft = 0
            scheduleContainerRef.current.scrollLeft = 0
        }
        loadObligations()
        setPage(0)
    }, [props.contactsInvited, props.selectedDate, props.timezone]) //eslint-disable-line

    useEffect(() => {
        if (currentTimeAreaRef && currentTimeAreaRef.current)
            currentTimeAreaRef.current.scrollIntoView({ block: "start", behavior: "smooth" })
    }, [props.startTime, props.endTime, loaded])

    function totalTimes(startHour?: number) {
        var times = []
        for (var i = startHour ?? 0; i < 25; i++) {
            const date = new Date()
            date.setHours(i)
            date.setMinutes(0)
            times.push(date)
        }
        return times
    }

    function calculateTop(startDate: Date, endDate: Date) {
        if (startDate < startTimeOfDay! && endDate > startTimeOfDay!) {
            // start in previous day,ends in current. So start from bottom
            return 0
        } else {
            var top = ((startDate.getTime() - startTimeOfDay!.getTime()) / (1000 * 60)) * pxPerMinute
            return top
        }
    }

    function calculateTopCurrentTimeAreaAndScheduleTime(startDate: Date) {
        return (startDate.getHours() * 60 + startDate.getMinutes()) * pxPerMinute // add +12 because of border width and margin top of schedule container
    }

    function calculateHeightCurrentTimeArea(dateStart: Date, dateEnd: Date) {
        return (
            (dateEnd.getHours() * 60 + dateEnd.getMinutes() - (dateStart.getHours() * 60 + dateStart.getMinutes())) * pxPerMinute
        )
    }

    function calculateHeight(dateStart: Date, dateEnd: Date) {
        if (dateStart < startTimeOfDay! && dateEnd > startTimeOfDay!) {
            return ((dateEnd.getTime() - startTimeOfDay!.getTime()) / (1000 * 60)) * pxPerMinute
        } else if (dateEnd > endTimeOfDay && dateStart <= endTimeOfDay) {
            return 1440 * pxPerMinute - calculateTop(dateStart, dateEnd)
        } else {
            var height = ((dateEnd.getTime() - dateStart.getTime()) / (1000 * 60)) * pxPerMinute
            if (height + calculateTop(dateStart, dateEnd) > 1440 * pxPerMinute) {
                height = 1440 * pxPerMinute - calculateTop(dateStart, dateEnd) //if height overlap
            }
            return height
        }
    }

    if (!loaded) {
        return <CenteredLoader loaderWrapperStyle={{ top: "400px" }}></CenteredLoader>
    }

    return (
        <AvailableUsersRoot>
            <CloseArrowIcon onClick={() => props.close()}>{IconArrowLeft({ fill: branding.mainInfoColor })}</CloseArrowIcon>
            <ParticipantsText>
                <TitleText>{strings.calendarEntryModalPageContent.availabilityTitle}</TitleText>
                <TimezoneText>{findTimezoneName(props.timezone)}</TimezoneText>
            </ParticipantsText>
            <ParticipantsNavigation>
                <ArrowIconLeft page={page} onClick={() => onLeftArrowClick()}>
                    {IconChevronLeftSolid({
                        width: "15",
                        height: "15",
                        fill: branding.calendarEntryModalPageContent.calendarTheme
                    })}
                </ArrowIconLeft>
                <ParticipantsIconsScroller ref={wrapperRefIcons}>
                    <ParticipantsIcons width={`${props.contactsInvited.length * (iconSize + iconMarginLeft)}px`}>
                        {props.contactsInvited.slice(page * pageSize, (page + 1) * pageSize).map((contact) => {
                            return (
                                <ParticipantIcon
                                    marginLeft={iconMarginLeft}
                                    size={30}
                                    alt={contact.firstName + " " + contact?.lastName}
                                    src={contact?.logoUrl}
                                    backgroundSize="cover"
                                ></ParticipantIcon>
                            )
                        })}
                    </ParticipantsIcons>
                </ParticipantsIconsScroller>
                <ArrowIconRight hasNext={hasNext()} onClick={() => onRightArrowClick()}>
                    {IconChevronRightSolid({
                        width: "15",
                        height: "15",
                        fill: branding.calendarEntryModalPageContent.calendarTheme
                    })}
                </ArrowIconRight>
            </ParticipantsNavigation>
            <ScheduleContainer ref={scheduleContainerRef}>
                <CurrentTimeArea
                    ref={currentTimeAreaRef}
                    top={calculateTopCurrentTimeAreaAndScheduleTime(moment(props.startTime).toDate())}
                    height={calculateHeightCurrentTimeArea(moment(props.startTime).toDate(), moment(props.endTime).toDate())}
                />
                <ScheduleTimeColumnWrapper width={props.contactsInvited.length * (iconSize + iconMarginLeft) + 38}>
                    <ScheduleTimeColumn>
                        {scheduleTimes.map((time) => {
                            return (
                                <ScheduleTime top={calculateTopCurrentTimeAreaAndScheduleTime(time)}>
                                    {moment(time).format("HH:mm")}
                                </ScheduleTime>
                            )
                        })}
                    </ScheduleTimeColumn>
                    {meetings.slice(page * pageSize, (page + 1) * pageSize).map((meetings) => {
                        return (
                            <ParticipantTimeColumn width={iconSize} marginLeft={iconMarginLeft}>
                                {meetings.meetings.items &&
                                    meetings.meetings.items.map((item, index) => {
                                        return (
                                            <ParticipantMeetingTime
                                                key={`m-${index}`}
                                                top={calculateTop(
                                                    moment(item.calendarEntry.start).toDate(),
                                                    moment(item.calendarEntry.end).toDate()
                                                )}
                                                height={calculateHeight(
                                                    moment(item.calendarEntry.start).toDate(),
                                                    moment(item.calendarEntry.end).toDate()
                                                )}
                                            ></ParticipantMeetingTime>
                                        )
                                    })}
                                {branding.calendarEntryModalPageContent.showEventDatesAvailability &&
                                    meetings.eventDates &&
                                    meetings.eventDates.map((item, index) => {
                                        return (
                                            <ParticipantMeetingTime
                                                key={index}
                                                top={calculateTop(
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeStart),
                                                        props.timezone
                                                    ).toDate(),
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeEnd),
                                                        props.timezone
                                                    ).toDate()
                                                )}
                                                height={calculateHeight(
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeStart),
                                                        props.timezone
                                                    ).toDate(),
                                                    momentWithoutTimezoneFromTimezonedMoment(
                                                        moment(item.dateTimeEnd),
                                                        props.timezone
                                                    ).toDate()
                                                )}
                                            ></ParticipantMeetingTime>
                                        )
                                    })}
                            </ParticipantTimeColumn>
                        )
                    })}
                </ScheduleTimeColumnWrapper>
            </ScheduleContainer>
            <LegendContainer>
                <BusyLegend />
                <BusyLegendText>{strings.calendarEntryModalPageContent.busyLegendText}</BusyLegendText>
            </LegendContainer>
        </AvailableUsersRoot>
    )
})

const ParticipantTimeColumn = styled.div<{ width: number; marginLeft: number }>`
    position: relative;
    width: ${(props) => `${props.width}px`};
    margin-top: 12.5px;
    margin-left: ${(props) => `${props.marginLeft}px`};
`

const ScheduleContainer = styled.div`
    height: 450px;
    width: 395px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    position: relative;
    flex-flow: row;
    padding: 0 10px;
    margin-top: 20px;
`

const ScheduleTimeColumnWrapper = styled.div<{ width: number }>`
    width: ${(props) => `${props.width}px`};
    display: flex;
`

const ScheduleTimeColumn = styled.div`
    position: relative;
    width: 38px;
`

const ScheduleTime = styled.div<{ top: number }>`
    position: absolute;
    top: ${(props) => `${props.top}px`};
`
const ParticipantMeetingTime = styled.div<{ top: number; height: number }>`
    position: absolute;
    background-color: #d1d1d1;
    height: ${(props) => `${props.height}px`};
    width: 100%;
    top: ${(props) => `${props.top}px`};
`

const AvailableUsersRoot = styled.div`
    flex: 1;
    display: flex;
    flex-flow: column;
    border-left: 1px solid black;
    padding: 8px 18px 0;
`
const CloseArrowIcon = styled.div`
    display: flex;
    flex-flow: row;
`
const ArrowIconLeft = styled.div<{ page: number }>`
    display: flex;
    flex-flow: row;
    width: 58px;
    align-items: center;
    opacity: ${(props) => (props.page > 0 ? 1 : 0.5)};
`
const ArrowIconRight = styled.div<{ hasNext: boolean }>`
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 58px;
    align-items: center;
    opacity: ${(props) => (props.hasNext ? 1 : 0.5)};
`

const ParticipantsText = styled.div`
    display: flex;
    flex-flow: column;
    margin-top: 17px;
`
const TitleText = styled.div`
    font-weight: bold;
    font-size: 16px;
    line-height: 17px;
    font-family: ${branding.font1};
    font-style: normal;
`

const TimezoneText = styled.div`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-top: 7px;
    width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
`

const ParticipantsNavigation = styled.div`
    display: flex;
    flex-flow: row;
    margin-top: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 13px;
    font-family: ${branding.font1};
`

const ParticipantsIconsScroller = styled.div`
    overflow-x: auto;
    width: 280px;
`
const ParticipantsIcons = styled.div<{ width: string }>`
    display: flex;
    width: ${(props) => props.width ?? "400px"};
`
const ParticipantIcon = styled(AvatarWithDefault)<{ marginLeft?: number }>`
    margin-left: ${(props) => `${props.marginLeft}px`};
    :first-child {
        margin-left: 0;
    }
`
const LegendContainer = styled.div`
    display: flex;
    flex-flow: row;
    align-items: flex-end;
    margin-top: 25px;
`

const BusyLegend = styled.div`
    background-color: #d1d1d1;
    border-left: 1px solid black;
    width: 35px;
    height: 15px;
`

const BusyLegendText = styled.div`
    display: flex;
    flex-flow: row;
    margin-left: 18px;
    line-height: 17px;
    font-size: 10px;
    font-weight: bold;
`

const CurrentTimeArea = styled.div<{ top: number; height: number }>`
    position: absolute;
    width: 100%;
    z-index: 3;
    height: ${(props) => `${props.height}px`};
    top: ${(props) => `${props.top + 12}px`};
    background-color: transparent;
    border: 2px solid ${branding.calendarEntryModalPageContent.currentTimeAreaTheme};
    left: 0;
`

const ValidationIconRoot = styled.div`
    display: block;
    width: 15px;
    z-index: 8;
`
const CustomTooltip = styled(Tooltip)<{ inset?: string }>`
    inset: ${(props) => (props.inset ? `${props.inset} !important` : "")};
`

const ValidationIcon: React.FC<{
    id: string
    tooltipText: string
    valid: boolean
    focused?: boolean
    inset?: string
    alwaysShow?: boolean
}> = (props) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false)

    return (
        <ValidationIconRoot
            className={`${props.id}-root`}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
        >
            {
                <OverlayTrigger
                    show={showTooltip && !props.valid}
                    overlay={
                        <CustomTooltip inset={props.inset} id={props.id} style={{ fontFamily: branding.font1 }}>
                            {props.tooltipText}
                        </CustomTooltip>
                    }
                >
                    <>
                        {(props.focused || props.alwaysShow) && (
                            <div>
                                {props.valid
                                    ? IconCheckmarkGreen({ width: "15", height: "15" })
                                    : IconCloseRed({ width: "15", height: "15" })}
                            </div>
                        )}
                    </>
                </OverlayTrigger>
            }
        </ValidationIconRoot>
    )
}

interface Closable {
    close: () => void
}
interface CalendarEntryModalProps {
    sotUser?: InvitePerson[]
    organizationId?: string
    calendarEntry?: CalendarEntry
    viewMode?: CalendarEntryModalViewMode
    predefinedTitle?: string
    predefinedStartTime?: Date
    availableTimeSlots?: Date[]
    meetingSlotsTable?: MeetingSlotsDay[]
    timeSlotInterval?: number
    onUpdated?: (calendarEntry: CalendarEntry) => void
    onRemovedFromList?: () => void
}
const CalendarEntryModal = (props: CalendarEntryModalProps & Closable) => {
    const TIME_INTERVAL = props.timeSlotInterval ?? 15
    const participantLimit = branding.configuration.calendarEntryParticipantLimit
    const userlink = useLoggedInState()
    const languageState = useLanguageState()
    const language = languageState.getLanguage()
    const appState = useAppState()
    const strings = languageState.getStrings()
    const [joinMeetingDisabled, setJoinMeetingDisabled] = useState(false)
    const [title, setTitle] = useState(props.predefinedTitle ?? "")
    const [active, setActive] = useState(false)
    // const [title, setTitle] = useState("")
    const currentDateEnd = new Date()
    const currentDate = new Date()
    currentDate.setMinutes(currentDate.getMinutes() + 30)
    const [start, setStart] = useState<Date | null>(props.predefinedStartTime ? moment(props.predefinedStartTime).toDate() : null)
    currentDateEnd.setMinutes(currentDateEnd.getMinutes() + 60)
    const [end, setEnd] = useState<Date | null>(start ? moment(props.predefinedStartTime).add(TIME_INTERVAL, "m").toDate() : null)
    const [day, setDay] = useState<Date | null>(
        props.predefinedStartTime ?? momentWithoutTimezoneFromTimezonedMoment(moment(), appState.timezone).toDate()
    )
    const [availableStartTimes, setAvailableStartTimes] = useState<TimeSlotType[]>([])
    const [availableEndTimes, setAvailableEndTimes] = useState<TimeSlotType[]>([])
    const [initialDatePickerDay, setInitialDatePickerDay] = useState<Date | null>(null)
    const [notes, setNotes] = useState("")
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false)
    const chimeContext = useChimeContext()
    const [closeModal, setCloseModal] = useState<boolean>(false)
    const [selectedTimezone, setSelectedTimezone] = useState(appState.timezone)
    const [openDatepicker, setOpenDatepicker] = useState(false)
    const [showAvailable, setShowAvailable] = useState(false)
    const [titleFocused, setTitleFocused] = useState<boolean>(false)
    const [dayFocused, setDayFocused] = useState<boolean>(false)
    const [startTimeFocused, setStartTimeFocused] = useState<boolean>(false)
    const [endTimeFocused, setEndTimeFocused] = useState<boolean>(false)
    const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
    const [errorModalMessage, setErrorModalMessage] = useState<string>(strings.calendarEntryModalPageContent.fieldError)
    const meetingDates = branding.eventTiming.meetingDays
    // eslint-disable-next-line
    const { isComponentVisible, setIsComponentVisible } = useComponentVisible(false)

    const canEdit = props.viewMode === CalendarEntryModalViewMode.CREATE || props.viewMode === CalendarEntryModalViewMode.EDIT
    const user = userlink.user()
    const profileId = user?.profileId
    const organizer: InvitePerson = {
        id: profileId ?? "",
        firstName: user?.firstName ?? "",
        lastName: user?.lastName,
        logoUrl: user?.logoUrl
    }

    const org = props.calendarEntry ? props.calendarEntry.organizationId : props.organizationId
    const myParticipationStatus = props.calendarEntry?.participants.items.find(
        (participation) => profileId === participation.userId
    )?.status

    const invited = props.sotUser || []
    if (!invited.find((item) => item.id === organizer.id)) {
        invited.unshift(organizer)
    }

    const setAvailableEndTimesHelper = () => {
        if (props.meetingSlotsTable && props.meetingSlotsTable.length > 0) {
            const currentDaySlots = props.meetingSlotsTable?.filter((d) => moment(d.date).isSame(day, "date"))[0]?.slots
            if (start && currentDaySlots?.length) {
                for (let i = 0; i < currentDaySlots.length - 1; i++) {
                    const firstSlot = currentDaySlots[i].value
                    const secondSlot = currentDaySlots[i + 1].value
                    const currentTime = moment(firstSlot).set("hours", start?.getHours()).set("minutes", start?.getMinutes())
                    if (isSlotDisabled(currentTime, firstSlot, secondSlot)) {
                        const indexJ = currentDaySlots.findIndex((slot, current) => {
                            if (current > i) return slot.disabled
                            return false
                        })
                        const j = indexJ !== -1 ? indexJ + 1 : currentDaySlots.length
                        setAvailableEndTimes(currentDaySlots.slice(i, j))
                        return
                    }
                }
            }
        }
    }

    useEffect(() => {
        setAvailableEndTimesHelper()
    }, [start]) // eslint-disable-line

    useEffect(() => {
        if (props.meetingSlotsTable) {
            const availableTimes =
                props.meetingSlotsTable
                    ?.filter((d) => moment(d.date).isSame(day, "date"))[0]
                    ?.slots.filter((slot) => !slot.disabled) ?? []
            setAvailableStartTimes(availableTimes)
            setAvailableEndTimesHelper()
        }
    }, [props.meetingSlotsTable, day]) // eslint-disable-line

    const [contactsInvited, setContactsInvited] = useState<InvitePerson[]>(invited)
    useEffect(() => {
        if (closeModal) {
            onCloseModalView()
        }
        // eslint-disable-next-line
    }, [closeModal])

    useEffect(() => {
        const days = meetingDates.map((date) => moment(date))
        const indexOfDayToDisplay = getIndexOfInitialDayToShow(days)
        setInitialDatePickerDay(days[indexOfDayToDisplay].toDate())
    }, [meetingDates])

    useEffect(() => {
        const calendarEntry = props.calendarEntry
        if (calendarEntry && props.viewMode !== CalendarEntryModalViewMode.CREATE) {
            setStart(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.start), appState.timezone).toDate())
            setEnd(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.end), appState.timezone).toDate())
            setDay(momentWithoutTimezoneFromTimezonedMoment(moment(props.calendarEntry!.start), appState.timezone).toDate())

            setStart(moment(calendarEntry.start).toDate())
            setEnd(moment(calendarEntry.end).toDate())
            setDay(moment(calendarEntry.start).toDate())
            setTitle(calendarEntry.title)
            const participations = calendarEntry.participants.items
            // Shift organizer participation to first index
            participations.unshift(
                participations.splice(
                    participations.findIndex((participation) => participation.userId === calendarEntry.userId),
                    1
                )[0]
            )
            setContactsInvited(
                participations.map((participation) => {
                    return {
                        logoUrl: participation.user.pictureUrl,
                        id: participation.user.id,
                        firstName: participation.user.name,
                        status: participation.status
                    }
                })
            )
            if (calendarEntry.description) {
                setNotes(calendarEntry.description)
            }
        }

        // eslint-disable-next-line
    }, [props.calendarEntry, props.viewMode])

    function onCloseModalView() {
        if (profileId === undefined) {
            return
        }
        if (props.viewMode === CalendarEntryModalViewMode.CREATE) {
            setContactsInvited(props.sotUser ? [organizer].concat(props.sotUser) : [organizer])
            setNotes("")
            setTitle("")
            if (props.calendarEntry) {
                setStart(moment(props.calendarEntry.start).toDate())
                setEnd(moment(props.calendarEntry.end).toDate())
                setDay(moment(props.calendarEntry.start).toDate())
            } else {
                resetTimes()
                setDay(new Date())
            }
        }
        props.close()
    }

    useEffect(() => {
        if (!isValidDay(day!)) {
            setJoinMeetingDisabled(true)
        } else {
            setJoinMeetingDisabled(false)
        }
    }, [day])

    function focusAllValidationIcons() {
        setTitleFocused(true)
        setEndTimeFocused(true)
        setStartTimeFocused(true)
        setDayFocused(true)
    }

    function findMyParticipationStatus() {
        if (props.calendarEntry && props.calendarEntry.participants && props.calendarEntry.participants.items) {
            return props.calendarEntry.participants.items.find((participant) => participant.userId === userlink.user()!.profileId)
                ?.status
        }
    }

    function chatWithParticipants() {
        onChatWithParticipants()
    }

    function onChatWithParticipants() {
        appState.setShowChatsTab(
            ChatConversationParam.conversationByConversationId(ConversationType.CALENDARENTRY, props.calendarEntry!.id)
        )
        props.close()
    }

    function joinMeeting() {
        onCallParticipants()
    }

    function exportMeeting() {
        if (props.calendarEntry) saveMeetings(strings, [props.calendarEntry])
    }

    async function onCallParticipants() {
        if (props.calendarEntry) {
            setCloseModal(true)
            chimeContext.createOrJoinMeeting(props.calendarEntry.id, "calenderEntry")
        }
    }

    async function createOrUpdateCalendarEntry(timezone: string) {
        if (isDateInValid(day, start, end) || title.length === 0 || !isTimeValid(start) || !isTimeValid(end, true)) {
            setShowErrorModal(true)
            setErrorModalMessage(strings.calendarEntryModalPageContent.fieldError)
            focusAllValidationIcons()
            return
        }
        if (profileId != null && start != null && end != null && day != null) {
            const startDateMoment = moment(day).tz(timezone)
            startDateMoment.set("year", day.getFullYear())
            startDateMoment.set("month", day.getMonth())
            startDateMoment.set("date", day.getDate())
            startDateMoment.set("hour", start.getHours())
            startDateMoment.set("minute", start.getMinutes())

            const endDateMoment = moment(day).tz(timezone)
            endDateMoment.set("year", day.getFullYear())
            endDateMoment.set("month", day.getMonth())
            endDateMoment.set("date", day.getDate())
            endDateMoment.set("hour", end.getHours())
            endDateMoment.set("minute", end.getMinutes())

            const startDate = startDateMoment.toDate()
            const endDate = endDateMoment.toDate()

            var result: CalendarEntry | null = null
            const calendarEntry = props.calendarEntry
            const participantsId = contactsInvited.map((contact) => {
                return contact.id
            })
            if (props.viewMode === CalendarEntryModalViewMode.CREATE && contactsInvited != null) {
                result = await createNewCalendarEntry(profileId, participantsId, title, startDate, endDate, notes, org)
                // TODO create groupchat conversation
            } else if (props.viewMode === CalendarEntryModalViewMode.EDIT && calendarEntry != null) {
                await Promise.all(
                    calendarEntry.participants.items.map(async (oldParticipation) => {
                        // Delete removed participations
                        if (!participantsId.includes(oldParticipation.userId)) {
                            await deleteCalendarEntryParticipationById(oldParticipation.id)
                        } else {
                            // Update existing participation when startDate, endDate or title was changed
                            if (
                                calendarEntry.start !== startDate.toISOString() ||
                                calendarEntry.end !== endDate.toISOString() ||
                                calendarEntry.title !== title
                            ) {
                                await updateCalendarEntryParticipationStatus(
                                    oldParticipation.id,
                                    oldParticipation.userId,
                                    startDate
                                )
                            }
                        }
                    })
                )

                const oldParticipatsIds = calendarEntry.participants.items.map((participation) => {
                    return participation.userId
                })
                await Promise.all(
                    participantsId.map(async (participantId) => {
                        // Create new participations
                        if (!oldParticipatsIds.includes(participantId)) {
                            await createNewCalendarEntryParticipation(
                                calendarEntry.id,
                                participantId,
                                CalendarEntryParticipationStatus.REQUESTED,
                                startDate
                            )
                        }
                    })
                )

                result = await updateCalendarEntryById(calendarEntry, title, startDate, endDate, notes)
            }

            if (result != null) {
                if (props.viewMode === CalendarEntryModalViewMode.CREATE && props.organizationId) {
                    trackVisit(profileId, props.organizationId, "CALENDARENTRY#SENT")
                }
                onCloseModalView()
                if (props.onUpdated) {
                    props.onUpdated(result)
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    async function deleteCalendarEntry() {
        setShowConfirmationDialog(false)
        if (profileId != null && props.calendarEntry?.id != null) {
            const result = await deleteCalendarEntryForUser(profileId, props.calendarEntry.id)

            if (result != null) {
                onCloseModalView()
                if (props.onRemovedFromList) {
                    props.onRemovedFromList()
                }
            } else {
                // TODO ERROR WHAT TO SHOW HERE?
            }
        }
    }

    function onStartSelected(time: Date) {
        if (time) {
            const startTemp = moment(day).set("hours", time.getHours()).set("minutes", time.getMinutes())
            setStart(startTemp.toDate())
            setEnd(startTemp.add(TIME_INTERVAL, "m").toDate())
        } else {
            setStart(null)
        }
    }

    function onEndSelected(time: Date) {
        if (time) {
            const endTemp = moment(day).set("hours", time.getHours()).set("minutes", time.getMinutes()).toDate()
            setEnd(endTemp)
        }
    }

    const dayFormat = strings.eventTiming.eventDaysFormatPatternShort

    const isTimeSlotAvailable = (time: Date | null, isEndTime?: boolean) => {
        // end time is valid if it's the same or before last available end time of the current user
        if (isEndTime) {
            return moment(time).isSameOrBefore(availableEndTimes[availableEndTimes.length - 1]?.value)
        }

        // start time is valid if it's value is inside available user meeting slots
        const currentDaySlots = props.meetingSlotsTable?.filter((d) => moment(d.date).isSame(day, "date"))[0]?.slots
        if (currentDaySlots && time) {
            for (let i = 0; i < currentDaySlots.length - 1; i++) {
                const firstSlot = currentDaySlots[i]
                const secondSlot = currentDaySlots[i + 1]
                let currentTime = firstSlot.value.clone().set("hours", time?.getHours()).set("minutes", time?.getMinutes())
                if (currentTime.isSame(firstSlot.value, "hours") && currentTime.isSame(firstSlot.value, "minutes")) {
                    return !firstSlot.disabled
                } else if (currentTime.isSame(secondSlot.value, "hours") && currentTime.isSame(secondSlot.value, "minutes")) {
                    return !secondSlot.disabled
                } else if (currentTime.isBetween(firstSlot.value, secondSlot.value)) {
                    return !firstSlot.disabled || !secondSlot.disabled
                }
            }
        }
        return false
    }

    const isTimeValid = (time: Date | null, isEndTime?: boolean) => {
        const isTodayTemp = isToday(moment(day))
        // this check is used only when available meeting slots table of the user is passed to calendar entry component
        if (props.meetingSlotsTable && props.meetingSlotsTable.length > 0 && time) return isTimeSlotAvailable(time, isEndTime)
        return time !== null && ((isTodayTemp && moment(time).isAfter(moment())) || !isTodayTemp)
    }

    const resetTimes = () => {
        const resetStartDate = new Date()
        const resetEndDate = new Date()
        resetEndDate.setMinutes(resetStartDate.getMinutes() + 10)
        setStart(resetStartDate)
        setEnd(resetEndDate)
    }

    function onDaySelected(date: Date) {
        if (isBeforeToday(moment(date))) {
            setShowErrorModal(true)
            setErrorModalMessage(strings.calendarEntryModalPageContent.pastDateErrorMessage)
            return
        }
        setDay(date)
    }

    function onTitleChanged(event: ChangeEvent<HTMLInputElement>) {
        setTitle(event.target.value)
    }

    function onNotesChanged(event: ChangeEvent<HTMLTextAreaElement>) {
        if (event.target.value.length > MAX_NOTES_LENGTH) {
            return
        }
        setNotes(event.target.value)
    }

    function onParticipantInvited(item: any) {
        if (contactsInvited.length >= participantLimit) {
            return
        }
        setContactsInvited(contactsInvited.concat(item))
    }

    function removePerson(item: any) {
        setContactsInvited(contactsInvited.filter((it) => it.id !== item.id))
    }

    function isValidDay(day: Date) {
        if (day === null) {
            return false
        }
        return day.getFullYear() === moment().year() && moment(day).dayOfYear() >= moment().dayOfYear()
    }

    function isMeetingDay(date: Date) {
        let formattedDate = date.toISOString().split("T")[0]

        return meetingDates.find((ed) => ed.split("T")[0] === formattedDate) !== undefined
    }

    function openCalendar() {
        if (props.viewMode === CalendarEntryModalViewMode.VIEW) return
        setOpenDatepicker(!openDatepicker)
        setActive(true)
    }

    function openDetailPage(contact: InvitePerson) {
        if (contact.id !== userlink.user()?.profileId) {
            let link =
                window.location.hostname === "localhost"
                    ? `http://localhost:3000${buildDetailLink(contact.id, contact.firstName + contact.lastName ?? "", "user")}`
                    : `https://${window.location.hostname}${buildDetailLink(
                          contact.id,
                          contact.firstName + contact.lastName ?? "",
                          "user"
                      )}`
            window.open(link, "_blank", "noopener,noreferrer")
        }
    }

    /*function numberOfAcceptedParticipants(): number {
        if (props.calendarEntry && props.calendarEntry?.participants.items.length > 0)
            return props.calendarEntry.participants.items.filter(x => x.status === CalendarEntryParticipationStatus.ACCEPTED).length

        return 0
    }*/

    const minStartTime = moment()
    const minEndTime = start !== null ? moment(start).add("minutes", 10) : minStartTime
    const maxTime = moment({ hours: 23, minutes: 45 })

    return (
        <>
            <CalendarEntryMainModal backdrop="static" animation={false} showAvailable={showAvailable} show>
                <ModalRootDiv onClick={(e) => e.stopPropagation()} style={{ width: "600px" }}>
                    <Modal.Header style={{ paddingRight: 0, paddingTop: 25 }}>
                        <Modal.Title style={{ display: "inline-flex" }}>
                            <Title>
                                {props.viewMode === CalendarEntryModalViewMode.EDIT
                                    ? strings.calendarEntryModalPageContent.editTitle
                                    : props.viewMode === CalendarEntryModalViewMode.CREATE
                                    ? strings.calendarEntryModalPageContent.createTitle
                                    : title}
                            </Title>
                            {/* <CloseButton
                                onClick={(event) => {
                                    event.stopPropagation()
                                    onCloseModalView()
                                }}
                            >
                                {IconClose({ fill: "#000", width: "27", height: "27" })}
                            </CloseButton> */}
                        </Modal.Title>
                        <CloseButton
                            onClick={(event) => {
                                event.stopPropagation()
                                onCloseModalView()
                            }}
                        >
                            {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                        </CloseButton>
                    </Modal.Header>
                    <Modal.Body>
                        {props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                            <PickersGroupRoot onFocus={() => setTitleFocused(true)}>
                                <Label>
                                    {strings.calendarEntryModalPageContent.name}{" "}
                                    <span style={{ color: branding.dangerButtonColor }}>*</span>
                                </Label>
                                <Form.Control
                                    as="input"
                                    type="text"
                                    value={title}
                                    disabled={!canEdit}
                                    onChange={onTitleChanged}
                                    placeholder={strings.calendarEntryModalPageContent.namePlaceholder}
                                ></Form.Control>
                                <ValidationIcon
                                    id="nameInput"
                                    tooltipText={strings.myProfilePageContent.oneRequiredFieldEmptyWarning}
                                    valid={title.length > 0}
                                    focused={titleFocused}
                                />
                            </PickersGroupRoot>
                        )}
                        <Label>
                            {strings.calendarEntryModalPageContent.day}{" "}
                            <span style={{ color: branding.dangerButtonColor }}>*</span>
                        </Label>
                        <SelectDateRoot>
                            <CalendarEntryModalDatePicker
                                selected={day}
                                readOnly={true}
                                locale={language === "de" ? de : en}
                                onCalendarClose={() => {
                                    setOpenDatepicker(false)
                                    setIsComponentVisible(true)
                                    setActive(false)
                                }}
                                shouldCloseOnSelect={true}
                                useWeekdaysShort={true}
                                open={openDatepicker}
                                onInputClick={() => {
                                    openCalendar()
                                    setDayFocused(true)
                                }}
                                onClickOutside={() => {
                                    setOpenDatepicker(false)
                                    setIsComponentVisible(true)
                                    setActive(false)
                                }}
                                includeDates={meetingDates.map((ed) =>
                                    momentWithoutTimezoneFromTimezonedMoment(moment(ed), selectedTimezone).toDate()
                                )}
                                placeholderText={strings.calendarEntryModalPageContent.dateSelectionPlaceholder}
                                openToDate={
                                    initialDatePickerDay ??
                                    momentWithoutTimezoneFromTimezonedMoment(moment(meetingDates[0]), selectedTimezone).toDate()
                                }
                                dayClassName={(date) => {
                                    const disabledClassName = isBeforeToday(moment(date)) ? " past-dates-disabled" : ""
                                    return (isMeetingDay(date) ? "meetingDate" : "nonMeetingDate") + disabledClassName
                                }}
                                popperPlacement="bottom-end"
                                showPopperArrow={false}
                                dateFormat={dayFormat}
                                onChange={(date) => onDaySelected(date as Date)}
                            />
                            <ValidationIcon
                                inset="auto auto 0 -9px"
                                id="dayInput"
                                tooltipText={strings.myProfilePageContent.oneRequiredFieldEmptyWarning}
                                valid={day !== null && !isBeforeToday(moment(day))}
                                focused={dayFocused}
                            />
                            <CloseIcon onClick={() => openCalendar()}>
                                {IconCalendarEntry({
                                    width: "20",
                                    height: "20",
                                    fill: active
                                        ? branding.calendarEntryModalPageContent.calendarIconActiveColor
                                        : branding.sideIconBar.sideIconColorDark
                                })}
                            </CloseIcon>
                        </SelectDateRoot>
                        <WhenTimeContent>
                            <TimePickerRoot>
                                <Label>
                                    {strings.calendarEntryModalPageContent.start}{" "}
                                    <span style={{ color: branding.dangerButtonColor }}>*</span>
                                </Label>
                                <CalendarEntryTimePicker
                                    onInputClick={() => setStartTimeFocused(true)}
                                    selected={start}
                                    disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={TIME_INTERVAL}
                                    timeCaption=""
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    popperPlacement="bottom"
                                    showPopperArrow={false}
                                    includeTimes={
                                        props.meetingSlotsTable && props.meetingSlotsTable.length > 0
                                            ? availableStartTimes.map((slot) => slot.value.toDate())
                                            : undefined
                                    }
                                    minTime={isToday(moment(day)) ? minStartTime.toDate() : undefined}
                                    maxTime={isToday(moment(day)) ? maxTime.toDate() : undefined}
                                    onChange={(time) => {
                                        onStartSelected(time! as Date)
                                    }}
                                />
                                <ValidationIcon
                                    inset="auto auto 0 -3px"
                                    id="startTimeInput"
                                    tooltipText={strings.myProfilePageContent.oneRequiredFieldEmptyWarning}
                                    valid={isTimeValid(start)}
                                    focused={startTimeFocused}
                                />
                            </TimePickerRoot>
                            <TimePickerRoot>
                                <Label>
                                    {strings.calendarEntryModalPageContent.end}{" "}
                                    <span style={{ color: branding.dangerButtonColor }}>*</span>
                                </Label>
                                <CalendarEntryTimePicker
                                    onInputClick={() => setEndTimeFocused(true)}
                                    selected={end}
                                    disabled={props.viewMode === CalendarEntryModalViewMode.VIEW}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={TIME_INTERVAL}
                                    timeCaption=""
                                    dateFormat="HH:mm"
                                    timeFormat="HH:mm"
                                    popperPlacement="bottom"
                                    showPopperArrow={false}
                                    includeTimes={
                                        props.meetingSlotsTable && props.meetingSlotsTable.length > 0
                                            ? availableEndTimes.map((slot) => slot.value.toDate())
                                            : undefined
                                    }
                                    minTime={
                                        isToday(moment(day))
                                            ? minEndTime.toDate()
                                            : start !== null
                                            ? moment(start).add("minutes", 10).toDate()
                                            : undefined
                                    }
                                    maxTime={maxTime.toDate()}
                                    onChange={(time) => onEndSelected(time! as Date)}
                                />
                                <ValidationIcon
                                    inset="auto auto 0 -3px"
                                    id="endTimeInput"
                                    tooltipText={strings.myProfilePageContent.oneRequiredFieldEmptyWarning}
                                    valid={isTimeValid(end, true) && start != null && moment(end).isAfter(start)}
                                    focused={endTimeFocused}
                                />
                            </TimePickerRoot>
                            <CalendarEntryTimezonePicker
                                viewMode={props.viewMode ?? CalendarEntryModalViewMode.CREATE}
                                selectedTimezone={selectedTimezone}
                                setSelectedTimezone={setSelectedTimezone}
                            />
                        </WhenTimeContent>
                        <Label style={{ marginTop: "30px", marginBottom: "auto" }}>
                            {strings.calendarEntryModalPageContent.searchPerson +
                                (org ? `` : ` (${contactsInvited.length}/${participantLimit})`)}
                        </Label>
                        <PeopleDisplayArea>
                            {contactsInvited.map((contact, index) => {
                                const statusColor =
                                    (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                        ? CalendarEntryParticipationStatusColor.ACCEPTED
                                        : (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                        ? CalendarEntryParticipationStatusColor.DECLINED
                                        : CalendarEntryParticipationStatusColor.REQUESTED
                                // const statusLabel =
                                //     (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                //         ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusAccepted
                                //         : (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                //         ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusDeclined
                                //         : (contact as any).status === CalendarEntryParticipationStatus.REQUESTED
                                //         ? strings.calendarEntryModalPageContent.calendarEntryParticipationStatusRequested
                                //         : undefined

                                return (
                                    <PersonChip
                                        key={index}
                                        userId={contact.id}
                                        label={[contact.firstName, contact.lastName].filter(Boolean).join(" ")}
                                        // sublabel={statusLabel}
                                        logoUrl={contact.logoUrl}
                                        icon={
                                            contact.id !== profileId && canEdit
                                                ? IconCloseFilled({
                                                      stroke: statusColor,
                                                      fill: "transparent",
                                                      width: "32px",
                                                      height: "30px"
                                                  })
                                                : undefined
                                        }
                                        successIcon={
                                            (contact as any).status === CalendarEntryParticipationStatus.ACCEPTED
                                                ? IconCheckmarkSuccessfullMini({
                                                      fill: statusColor,
                                                      stroke: "transparent",
                                                      width: "12px",
                                                      height: "12px"
                                                  })
                                                : undefined
                                        }
                                        declineIcon={
                                            !canEdit && (contact as any).status === CalendarEntryParticipationStatus.DECLINED
                                                ? IconClose({
                                                      fill: statusColor,
                                                      stroke: "transparent",
                                                      width: "12px",
                                                      height: "12px"
                                                  })
                                                : undefined
                                        }
                                        onIconClick={() => removePerson(contact)}
                                        onAvatarPanelClick={() => openDetailPage(contact)}
                                        labelColor={statusColor}
                                        backgroundColor={"#FFF"}
                                    ></PersonChip>
                                )
                            })}
                            {contactsInvited.length > 1 &&
                                !isDateInValid(day, start, end) &&
                                props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                                    <AvailabiltyButton onClick={() => setShowAvailable(true)}>
                                        {strings.calendarEntryModalPageContent.availabilityButtonText}
                                        {IconChevronRightSolid({ width: "13", height: "13", fill: "#fff" })}
                                    </AvailabiltyButton>
                                )}
                        </PeopleDisplayArea>
                        {!org && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                            <StyledSearchPersonInput
                                calendarEntryModal={true}
                                timezone={selectedTimezone}
                                start={dateWithUTCIncrasedByTimezone(selectedTimezone, start!, day!)}
                                end={dateWithUTCIncrasedByTimezone(selectedTimezone, end!, day!)}
                                placeholder={strings.calendarEntryModalPageContent.searchPersonPlaceholder}
                                personClicked={onParticipantInvited}
                                excludeIds={contactsInvited.map((p) => p.id)}
                                topClear="7px"
                                topSearch="-3px"
                            />
                        )}
                        {(props.viewMode === CalendarEntryModalViewMode.VIEW ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT) && (
                            <ChatWithParticipantsDiv>
                                {findMyParticipationStatus() === CalendarEntryParticipationStatus.ACCEPTED && (
                                    <ChatWithParticipantsButtonLink onClick={chatWithParticipants}>
                                        {strings.calendarEntryListView.chatButtonTitle}
                                    </ChatWithParticipantsButtonLink>
                                )}
                            </ChatWithParticipantsDiv>
                        )}
                        <NotesContainer>
                            <Label>
                                {/* {strings.calendarEntryModalPageContent.notes + " (" + notes.length + "/" + MAX_NOTES_LENGTH + ")"} */}
                                {strings.calendarEntryModalPageContent.notes}
                            </Label>
                            <StyledNotesInput
                                disabled={!canEdit}
                                rows={3}
                                spellCheck={false}
                                value={notes}
                                onChange={onNotesChanged}
                                as="textarea"
                                placeholder={canEdit ? strings.calendarEntryModalPageContent.notesPlaceholder : ""}
                            />
                            <TextLimit textLength={notes?.length || 0} maxTextLength={MAX_NOTES_LENGTH} />
                        </NotesContainer>

                        {(props.viewMode === CalendarEntryModalViewMode.CREATE ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT) && (
                            <SubmitButtonContainer>
                                {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                    <CancelMeetingContainer>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            customColor={true}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => {
                                                setShowConfirmationDialog(true)
                                            }}
                                        >
                                            {strings.calendarEntryModalPageContent.deleteMeeting}
                                        </CancelButton>
                                    </CancelMeetingContainer>
                                )}
                                <OtherButtonsContainer>
                                    {props.viewMode === CalendarEntryModalViewMode.CREATE && (
                                        // <SubmitButtonDanger onClick={() => props.close()} className="d-flex align-items-center">
                                        //     {strings.globalTexts.cancel}
                                        // </SubmitButtonDanger>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.CREATE}
                                            onClick={() => props.close()}
                                            className="d-flex align-items-center justify-content-center"
                                        >
                                            {strings.globalTexts.cancel}
                                        </CancelButton>
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.CREATE && (
                                        <SubmitButton
                                            viewMode={CalendarEntryModalViewMode.CREATE}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => createOrUpdateCalendarEntry(selectedTimezone)}
                                        >
                                            {strings.calendarEntryModalPageContent.sendInvite}
                                        </SubmitButton>
                                    )}

                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        // <SubmitButtonDanger
                                        //     className="d-flex align-items-center"
                                        //     onClick={() => {
                                        //         setShowConfirmationDialog(true)
                                        //     }}
                                        // >
                                        //     {strings.calendarEntryModalPageContent.deleteMeeting}
                                        // </SubmitButtonDanger>
                                        <CancelButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => props.close()}
                                        >
                                            {strings.globalTexts.cancel}
                                        </CancelButton>
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        <ValidationIcon
                                            id="submitButtonInput"
                                            tooltipText={strings.calendarEntryModalPageContent.calendarNotValid}
                                            valid={(!isDateInValid(day, start, end) as boolean) && title.length > 0}
                                            focused={dayFocused && startTimeFocused && endTimeFocused && titleFocused}
                                        />
                                    )}
                                    {props.viewMode === CalendarEntryModalViewMode.EDIT && (
                                        <SubmitButton
                                            viewMode={CalendarEntryModalViewMode.EDIT}
                                            className="d-flex align-items-center justify-content-center"
                                            onClick={() => createOrUpdateCalendarEntry(selectedTimezone)}
                                        >
                                            {strings.calendarEntryModalPageContent.saveChanges}
                                        </SubmitButton>
                                    )}
                                </OtherButtonsContainer>
                            </SubmitButtonContainer>
                        )}
                    </Modal.Body>
                    <Modal.Footer>
                        {(props.viewMode === CalendarEntryModalViewMode.VIEW ||
                            props.viewMode === CalendarEntryModalViewMode.EDIT) && (
                            <SubmitButtonContainerLinks>
                                <ButtonLinks
                                    disabled={
                                        isExplorationOrPostEventPhase ||
                                        joinMeetingDisabled ||
                                        (myParticipationStatus &&
                                            myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)
                                    }
                                    onClick={joinMeeting}
                                >
                                    {strings.calendarEntryListView.callButtonTitle}
                                </ButtonLinks>
                                <ButtonLinks onClick={exportMeeting}>
                                    {strings.calendarEntryModalPageContent.exportMeeting}
                                </ButtonLinks>

                                {/* <ButtonLinks disabled={numberOfAcceptedParticipants() <= 1 || (myParticipationStatus && myParticipationStatus !== CalendarEntryParticipationStatus.ACCEPTED)} onClick={chatWithParticipants}>{strings.calendarEntryListView.chatButtonTitle}</ButtonLinks> */}
                            </SubmitButtonContainerLinks>
                        )}
                    </Modal.Footer>
                </ModalRootDiv>
                {showAvailable && props.viewMode !== CalendarEntryModalViewMode.VIEW && (
                    <AvailableUsers
                        editMode={props.viewMode === CalendarEntryModalViewMode.EDIT}
                        startTime={start ?? undefined}
                        endTime={end ?? undefined}
                        selectedDate={day ?? undefined}
                        contactsInvited={contactsInvited}
                        timezone={selectedTimezone}
                        close={() => setShowAvailable(false)}
                    ></AvailableUsers>
                )}
                {showErrorModal && (
                    <DataUpdateAlert
                        message={errorModalMessage}
                        close={() => setShowErrorModal(false)}
                        show={showErrorModal}
                        type="calendar-entry-modal"
                        timeoutSeconds={3}
                        titleImage={IconIndicatorErrorBlack({ fill: branding.sideIconBar.sideIconColorDark })}
                        position="bottom"
                    />
                )}
            </CalendarEntryMainModal>
            <DeleteCalendarEntryModal
                backdrop="static"
                type="cancel"
                animation={false}
                show={showConfirmationDialog}
                deleteCalendarEntry={() => deleteCalendarEntry()}
                setShowConfirmationDialog={() => setShowConfirmationDialog(false)}
            />
        </>
    )
}

export default CalendarEntryModal

export const useCalendarEntryModal = () => {
    const [isVisible, setIsVisible] = useState(false)
    const [data, setData] = useState<CalendarEntryModalProps>({} as any)

    const showCalendarEntryModal = (props: CalendarEntryModalProps) => {
        setData(props)
        setIsVisible(true)
    }

    const CalendarModal = () =>
        ReactDOM.createPortal(
            <React.Fragment>{isVisible && <CalendarEntryModal {...data} close={() => setIsVisible(false)} />}</React.Fragment>,
            document.body
        )

    return {
        showCalendarEntryModal,
        CalendarModal
    }
}
