import React from "react"
import { Trademark } from "../../backendServices/Types"
import { useLanguageState } from "../../globalStates/LanguageState"
import useWindowDimensions from "../WindowDimensionsHook"
import branding from "../../branding/branding"
import { IconTrademark } from "../Icons"
import { trademarksPageRoute } from "../../navigationArea/RoutePaths"
import EmptyTile from "../../contentArea/reception/EmptyTile"
import TileRow from "../TileRow"
import VizSensor from "react-visibility-sensor"
import { TrademarkComponent } from "./components/TrademarkComponent"

interface TrademarkCarouselProps {
    title?: string
    linkText?: string
    link?: string
    onBeforeLinkClick?: () => void
    trademarks: Trademark[]
}
export function TrademarkCarousel(props: TrademarkCarouselProps) {
    const { trademarks } = props
    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    // Let's take props over defaults
    const title = props.title ?? strings.navigationArea.trademarksItemTitle
    const icon = <IconTrademark fill={branding.sideIconBar.sideIconColorDark ?? branding.mainInfoColor} />
    const linkText = props.linkText ?? strings.receptionPage.trademarksNavLinkText
    const link = props.link ?? trademarksPageRoute

    let content
    if (trademarks.length === 0) {
        content = <EmptyTile header={title} buttonNavLink={link} buttonMessage={linkText} hideButton={true} bgColor="#F2F2F2" />
    } else {
        const widthHeight = isMobile ? { width: 95, height: 95 } : { width: 250, height: 250 }
        content = trademarks.map((trademark, index) => (
            <VizSensor key={index} partialVisibility={true}>
                {({ isVisible }) => (isVisible ? <TrademarkComponent trademark={trademark} /> : <div style={widthHeight}></div>)}
            </VizSensor>
        ))
    }

    return (
        <TileRow
            icon={icon}
            iconVisible={true}
            title={title}
            titleVisible={true}
            navLink={link}
            navLinkText={linkText}
            navLinkTextVisible={true}
            onBeforeLinkClick={props.onBeforeLinkClick}
            height={isMobile ? "95px" : "220px"}
            scrollBar={trademarks && trademarks.length > 0}
            isMyFairPage
            hideShadows={isMobile}
            childWidth={isMobile ? 95 : 250 + 9}
            childCount={trademarks.length ?? 0}
        >
            {content}
        </TileRow>
    )
}
