// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useRef, useEffect } from "react"
import styled, { css } from "styled-components"
import { useChimeContext, ChannelStatus } from "../context/ChimeContext"
import branding from "../../branding/branding"
import { ContactEntry, ContactEntryType } from "../../communicationArea/ContactEntry"
import {
    IconBlockContact,
    IconMicrophoneOff,
    IconLowerHand,
    IconRaiseHand,
    IconClose,
    IconRemoveFromCall,
    IconVolumeHigh,
    IconNetworkingToggleClose,
    IconStaff,
    IconShareScreenStop
} from "../../ui/Icons"
import {
    BackendServiceError,
    doConnectAction,
    restartRecorder,
    RestartRecorderResponse,
    User,
    loadChannelFirstEventDate,
    EventDateChannelFirstDetailsResponse
} from "../../backendServices/BackendServices"
import { Action } from "../../communicationArea/CommunicationOptions"
import { Modal, Button } from "react-bootstrap"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useLanguageState } from "../../globalStates/LanguageState"
import SearchPersonInput from "../../communicationArea/SearchPersonInput"
import { Contact, EventDate } from "../../backendServices/Types"
import { StagedOpponentsArea } from "../../communicationArea/ConversationDetails"
import ChatPage, {
    ConversationParticipant,
    ChatConversationParam,
    calcConversationId,
    DisplayStyle
} from "../../communicationArea/ChatPage"
import { useMeetingContext } from "../context/MeetingContext"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import CrsMultiSwitch from "../../ui/CrsMultiSwitch"
import { ConversationType } from "../../API"
import { useParams, useRouteMatch, NavLink } from "react-router-dom"
import RosterAttendeeType from "../types/RosterAttendeeType"
import { useAppState } from "../../globalStates/AppState"
import queryString from "query-string"
import { meetingPageRoute } from "../../navigationArea/RoutePaths"
import { useActiveSpeakerContext } from "../context/ActiveSpeakerContext"
import KickUserModal from "../../ui/KickUserModal"
import { defaultLogger as logger } from "../../globalStates/AppState"
import { TextareaRoot } from "../../ui/SayHelloModal"
import TextLimit from "../../ui/TextLimit"

const RosterRoot = styled.div`
    background-color: white;
    border-left: 1px solid white;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    align-items: center;
    height: 100%;
    flex-grow: 1;
`

const RosterPannel = styled.div`
    border-left: 1px solid white;
    width: 330px;
    margin: 0px;
    height: 100%;
    margin-top: -1px;

    &.pannelCollapsed {
        width: 60px;
    }
`

const PannelHead = styled.div`
    text-align: center;
`

const PannelBody = styled.div<{ meetingKind?: string }>`
    display: flex;
    flex-direction: column;
    /*padding-top: 20px;*/
    height: ${(props) => (props.meetingKind === "greenroom" ? "100vh" : "calc(100vh - 191px)")};
    overflow-x: hidden;

    &.pannelCollapsed {
        height: calc(100vh - 133px);
        align-items: center;
    }
`

const PannelFooter = styled.div`
    position: absolute;
    bottom: 0px;
    text-align: center;
    /* margin: 15px 20px 5px 20px; */
    align-content: center;
    max-width: 100%;
    width: 330px;
`

const ButtonAdd = styled.div`
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor ?? "#000"};
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor ?? "#000"};
    border-radius: 5px;
    width: calc(100% - 40px);
    margin: 5px 20px 20px 20px;
    font-family: ${branding.font1};
    font-size: 14px;
    cursor: pointer;
    padding: 8px 0;
    transition: 0.2s;

    :hover {
        opacity: 0.7;
    }
`

const ButtonConnect = styled(ButtonAdd)`
    white-space: nowrap;
    color: ${branding.roster.buttonConnectColor ?? "#000"};
    background: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
    &:hover {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background-color: ${branding.crsTabs.defaultActionItemActiveStateColor} !important;
        opacity: 1 !important;
    }
    &:focus {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background: ${branding.roster.buttonConnectColor ?? "#000"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#FFF"};
    }

    &:active {
        color: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        background: ${branding.roster.buttonConnectColor ?? "#000"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#FFF"};
    }

    &:not {
        color: ${branding.roster.buttonConnectColor ?? "#000"};
        background: ${branding.roster.buttonConnectBgColor ?? "#FFF"};
        border-color: ${branding.roster.buttonConnectBorderColor ?? "#000"};
    }
`

const AttendiesPannel = styled.div`
    display: flex;
    flex-direction: column;
`

const AttendiesPannelHeader = styled.div`
    display: flex;
    flex-direction: row;
    border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 0 20px;
    height: 62px;

    align-items: center;

    &.pannelCollapsed {
        border-bottom: 0px solid white;
    }

    .active {
        color: ${branding.crsMultiSwitcher.crsMultiSwitcherActiveColorInMeeting};
    }
`

const AttendiesTitle = styled.div`
    font-family: ${branding.font1};
    font-size: 16px;
    width: calc(100% - 60px);
    text-align: center;
`

const NetworkingToggleIcon = styled.div`
    position: relative;
    right: 5px;
    cursor: pointer;
    margin-bottom: -1px;
    transform: rotate(0deg);
    transition: transform 0.8s, right 0.3s ease-in-out;
    background-color: hsla(0, 0%, 100%, 0%);
    color: ${branding.mainInfoColor ?? "black"};

    &:hover {
        fill: gray;
    }

    &.pannelCollapsed {
        transform: rotate(180deg) scale(1);
    }
`
const StyledContactEntry = styled(ContactEntry)`
    /** Color of the attendee name */
    & > :first-child:hover {
        color: #000;
    }

    /** Color of the menu action buttons */
    & .expanded .expanded > div > div > div:hover {
        color: #000;
    }

    & .disabled:hover {
        color: black !important;
    }
`
const StyledSearchPersonInput = styled(SearchPersonInput)`
    margin: 16px;
    /* border-bottom: 1px solid ${branding.roster.searchInputColor ?? "#000"}; */
    line-height: 35px;
    color: ${branding.roster.searchInputColor ?? "#000"};
    text-align: left;
    font-family: ${branding.font1};

    input {
        /* margin-left: 15px; */
        background-color: ${branding.roster.searchInputBgColor ?? "#FFF"};
        color: ${branding.roster.searchInputColor ?? "#000"};
        border-radius: 5px;
    }
    input::placeholder {
        color: ${branding.roster.searchInputColor ?? "#000"};
        opacity: 0.6;
    }
`
const MaxAttendees = styled.div`
    padding: 20px;
    font-weight: bold;
`

const AttendeeRoot = styled.div`
    position: relative;

    &:hover > div > svg {
        color: #000;
    }
`

const ModIcon = styled.span<{ left?: string; top?: string; fontSize?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => props.top ?? 0};
    left: ${(props) => props.left ?? "5px"};
    font-size: ${(props) => props.fontSize ?? "20px"};
`
const HandRaisedIcon = styled.div<{ top?: string; right?: string; bottom?: string; left?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => (props.top ? props.top : "32px")};
    right: ${(props) => (props.right ? props.right : "auto")};
    bottom: ${(props) => (props.bottom ? props.bottom : "auto")};
    left: ${(props) => (props.left ? props.left : "8px")};
`
const SpeakingIcon = styled.div<{ top?: string; right?: string; bottom?: string; left?: string }>`
    display: block;
    position: absolute;
    top: ${(props) => (props.top ? props.top : "8px")};
    right: ${(props) => (props.right ? props.right : "auto")};
    bottom: ${(props) => (props.bottom ? props.bottom : "auto")};
    left: ${(props) => (props.left ? props.left : "50px")};
`
const MainModal = styled(Modal)`
    .modal-content {
        border-radius: 5px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    }

    .modal-title {
        font-family: ${branding.font1};
        margin-left: 15px;
        font-size: 18px;
        line-height: 17px;
        font-weight: bold;
        margin-top: 3px;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        border: none;
        padding: 35px 0 20px 0;
    }

    .modal-body {
        display: flex;
        padding: 0px 10px;
    }

    .modal-footer {
        border-top: none;
        justify-content: space-between;
        margin: 0px;
    }

    .close {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 15px;
        font-size: 46px;
        font-weight: 10;
        line-height: 1.66666667;
        color: #000;
        width: 25px;
        padding: 0 2px;
        background: none;
        border-style: none;
        margin-right: 1px;
        margin-top: -16px;
    }
`
const CancelButton = styled(Button)`
    display: block;
    width: 25%;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`
const ConfirmButton = styled(Button)`
    display: block;
    width: 25%;
    /* margin-top: 20px; */
    color: ${branding.recommendModal.submitBtnPrimaryTextColor} !important;
    background-color: ${branding.recommendModal.submitBtnPrimaryBgColor}!important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: 12px;
    line-height: 17px;
    font-family: ${branding.font1};

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

export enum SideBarViewMode {
    ROSTER,
    CHAT
}
export enum KickOrBanMode {
    NONE,
    KICK,
    BAN
}
export default function Roster(props: {
    conversationParticipants?: User[]
    selectedItem?: SideBarViewMode
    guestBannerHeight: number
}) {
    const [selectedItem, setSelectedItem] = useState<SideBarViewMode>()
    const chime = useChimeContext()
    const roster = chime.getRoster()
    const strings = useLanguageState().getStrings()
    const appState = useAppState()
    const isOpen = useAppState().isRosterOpen
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const cachedParticipantsMap = useRef<Map<string, RosterAttendeeType>>(new Map()) // Holds company information for users who left the call
    const meetingKind = chime.getKind()
    const hasChatRoom =
        meetingKind === "virtualCafe" ||
        meetingKind === "showroom" ||
        meetingKind === "greenroom" ||
        meetingKind === "roundtable" ||
        meetingKind === "breakout" ||
        meetingKind === "conferenceroom"
    const loggedInUserId = useLoggedInState().user()?.profileId

    function onRosterToggleClick(value: SideBarViewMode) {
        if (value === SideBarViewMode.ROSTER) {
            appState.setIsMeetingChatShown(false)
        } else {
            appState.setIsMeetingChatShown(true)
        }
    }

    useEffect(() => {
        if (appState.isMeetingChatShown) {
            setSelectedItem(SideBarViewMode.CHAT)
        } else {
            setSelectedItem(SideBarViewMode.ROSTER)
        }
        // eslint-disable-next-line
    }, [appState.isMeetingChatShown, isOpen])

    useEffect(() => {
        appState.setIsMyHandRaised(false)
        appState.setIsRosterOpen(true)

        Object.values(meetingParticipants).forEach((particpant) => {
            if (particpant.id === loggedInUserId && appState.isMyHandRaised) {
                particpant.handRaised = true
                appState.setIsMyHandRaised(true)
            }
        })

        // eslint-disable-next-line
    }, [])

    const isSupportedVirtualCafe = branding.roster.supportVirtualCafes.find((it) => window.location.href.indexOf("vc_" + it) > -1)
    const rosterKeys = Object.keys(roster)

    const meetingParticipants: RosterAttendeeType[] = rosterKeys.map((attendeeId) => {
        return { rosterId: attendeeId, ...roster[attendeeId] }
    })
    meetingParticipants.forEach((meetingParticipant) => {
        if (meetingParticipant.id) {
            cachedParticipantsMap.current.set(meetingParticipant.id, meetingParticipant)
        }
    })

    if (!hasChatRoom && props.conversationParticipants) {
        props.conversationParticipants.forEach((p) => {
            const meetingParticipant = meetingParticipants.find((attendee) => attendee.id === p.id)
            if (!meetingParticipant) {
                const { pictureUrl, ...rest } = p
                const cachedParticipant = cachedParticipantsMap.current.get(p.id)
                meetingParticipants.push({
                    signalStrength: 0,
                    volume: 0,
                    muted: false,
                    handRaised: false,
                    avatarUrl: pictureUrl,
                    position: cachedParticipant?.position,
                    company: cachedParticipant?.company,
                    ...rest
                })
            }
        })
    }

    const itemList = [
        { label: strings.conferenceTexts.participantsSwitch, value: SideBarViewMode.ROSTER },
        { label: strings.conferenceTexts.chatSwitch, value: SideBarViewMode.CHAT }
    ]
    return (
        <RosterRoot className={isOpen ? "" : ""}>
            <RosterPannel className={isOpen ? "" : "pannelCollapsed"}>
                <AttendiesPannel>
                    <div>
                        <AttendiesPannelHeader
                            className={isOpen ? "" : "pannelCollapsed"}
                            style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}
                        >
                            <NetworkingToggleIcon
                                onClick={() => appState.setIsRosterOpen(!isOpen)}
                                className={isOpen ? "" : "pannelCollapsed"}
                            >
                                {IconNetworkingToggleClose({
                                    fill: isMeetingPage
                                        ? branding.sideIconBar.sideIconColorDark ?? "#fff"
                                        : branding.sideIconBar.sideIconColorDark
                                })}
                            </NetworkingToggleIcon>
                            {isOpen && hasChatRoom && (
                                <CrsMultiSwitch
                                    items={itemList}
                                    activeItem={selectedItem}
                                    onItemClick={onRosterToggleClick}
                                    style={{
                                        border: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeBorderColor,
                                        msRootItem: {
                                            flex: "1 1 auto",
                                            fontSize: "0.9rem",
                                            padding: "1px",
                                            margin: "0px 38px 0 8px",
                                            color: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor,
                                            position: "relative",
                                            zIndex: 1
                                        },
                                        msItem: { position: "relative", zIndex: 1 },
                                        msActiveItem: {
                                            backgroundColor: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeBgColor,
                                            color: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor,
                                            position: "absolute",
                                            zIndex: 1
                                        },
                                        itemColor: branding.crsMultiSwitcher.crsMultiSwitcherOnDarkThemeColor
                                    }}
                                />
                            )}
                            {isOpen && !hasChatRoom && (
                                <AttendiesTitle>{strings.conferenceTexts.participantsSwitch}</AttendiesTitle>
                            )}
                        </AttendiesPannelHeader>
                    </div>
                    {isOpen ? (
                        <>
                            {" "}
                            {hasChatRoom && (
                                <ChatContainer
                                    viewMode={selectedItem ?? SideBarViewMode.ROSTER}
                                    guestBannerHeight={props.guestBannerHeight}
                                    virtualCafe={meetingKind === "virtualCafe" && isSupportedVirtualCafe !== undefined}
                                />
                            )}
                            {(!hasChatRoom || selectedItem === SideBarViewMode.ROSTER) && (
                                <>
                                    {meetingKind === "greenroom" ? (
                                        chime.isMod() ? (
                                            <GreenRoomOperatorView
                                                meetingParticipants={meetingParticipants}
                                                channelId={chime.getExternalMeetingId()?.substr(3)}
                                            />
                                        ) : (
                                            <GreenRoomPresentersView
                                                meetingParticipants={meetingParticipants}
                                                channelId={chime.getExternalMeetingId()?.substr(3)}
                                            />
                                        )
                                    ) : (
                                        <ParticipantRoster
                                            meetingParticipants={meetingParticipants}
                                            showParticipantSearch={meetingKind !== "roundtable"}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <PannelBody
                            className="pannelCollapsed"
                            style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}
                        >
                            <AttendeesLoadedAvatarContent
                                isMyHandRaised={appState.isMyHandRaised}
                                meetingParticipants={meetingParticipants}
                            />
                        </PannelBody>
                    )}
                </AttendiesPannel>
            </RosterPannel>
        </RosterRoot>
    )
}

const CloseButton = styled.div`
    position: absolute;
    right: 30px;
    top: 25px;
    cursor: pointer;
    svg {
        color: ${branding.mainInfoColor};
    }
`
const SubmitButtonContainer = styled.div`
    width: 100%;
    justify-content: flex-end;
    display: inline-flex;
    margin-bottom: 20px;
    margin-right: 20px;
`

interface ParticipantRosterProps {
    meetingParticipants: RosterAttendeeType[]
    showParticipantSearch: boolean
}

const ParticipantRoster: React.FC<ParticipantRosterProps> = (props) => {
    const meeting = useMeetingContext()
    const chime = useChimeContext()
    const roster = chime.getRoster()
    const strings = useLanguageState().getStrings()
    const loggedInUserId = useLoggedInState().user()?.profileId

    const [showConnectWithAllModal, setShowConnectWithAllModal] = useState(false)
    const [stagedOpponents, setStagedOpponents] = useState<ConversationParticipant[]>([])

    const sayHelloTextAreaRef = useRef<HTMLTextAreaElement>(null)
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const maxTextLength: number = 150
    const [notes, setNotes] = useState("")

    function onNotesChanged(value: string) {
        if (value.length > maxTextLength) {
            return
        }
        setNotes(value)
    }

    const handleClose = (helloMessage: string) => {
        Object.keys(roster).map((attendeeId: string) => {
            const attendee = roster[attendeeId]
            if (attendee) {
                if (attendee.id !== (loggedInUserId as string) && attendee.id !== "recorder") {
                    // TODO && externalUser.myConnectionStatus === "UNRELATED") {
                    doConnectAction({
                        profileId: loggedInUserId as string,
                        targetProfileId: attendee.id!,
                        message: helloMessage,
                        action: "request"
                    })
                        .then((response: any) => {})
                        .catch((e: { message: React.SetStateAction<string> }) => {
                            // Logged in BackendServices
                        })
                }
            }
            return null
        })
        setShowConnectWithAllModal(false)
    }

    const onPersonSelected = (person: Contact) => {
        const personAddedAlready = stagedOpponents.find((x) => x.id === person.id) || null
        if (person && personAddedAlready === null) {
            const newOpponent = {
                id: person.id,
                name: [person.firstName, person.lastName].join(" "),
                pictureUrl: person.logoUrl,
                position: person.position,
                organization: person.organization
            }
            setStagedOpponents(stagedOpponents.concat([newOpponent]))
        }
    }

    const connectWithAllParticipantsModal = (
        <>
            <MainModal
                show={showConnectWithAllModal}
                onHide={() => setShowConnectWithAllModal(false)}
                backdrop="static"
                centered
                animation={false}
            >
                <Modal.Header>
                    {/* <span style={{ marginTop: "2px", marginRight: "10px" }}>{IconConnect({ fill: isMeetingPage ? branding.sideIconBar.sideIconColorLight ?? "#fff" : branding.sideIconBar.sideIconColorDark })}</span> */}
                    <Modal.Title>
                        <h3 className="modal-title">{strings.sayHelloModal.headerTextSayHello}</h3>
                    </Modal.Title>
                    <CloseButton onClick={() => setShowConnectWithAllModal(false)}>
                        {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                    </CloseButton>
                </Modal.Header>

                <Modal.Body>
                    <div style={{ margin: "10px 20px 10px 20px" }}>
                        <div style={{ alignItems: "center", fontSize: "14px", lineHeight: "18px", marginBottom: "30px" }}>
                            {strings.sayHelloModal.subtitleConnectRequestPart1}{" "}
                            {strings.sayHelloModal.subtitleConnectRequestPart2}
                        </div>
                        <TextareaRoot>
                            <textarea
                                maxLength={maxTextLength}
                                autoFocus={true}
                                ref={sayHelloTextAreaRef}
                                value={notes}
                                onChange={(event) => onNotesChanged(event.target.value)}
                                name="modalInputMessage"
                                // onChange={props.onChange}
                                className="form-control"
                                placeholder={strings.sayHelloModal.sendMessagePlaceholder}
                            ></textarea>
                            <TextLimit textLength={notes?.length || 0} maxTextLength={maxTextLength} />
                        </TextareaRoot>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <SubmitButtonContainer>
                        {/* <Button variant="secondary" onClick={() => handleClose(textAreaRef1.current!.value)}>
          Sent
        </Button>
        <Button variant="primary" onClick={() => setShow(false)}>
          Close
        </Button> */}
                        <CancelButton
                            onClick={() => setShowConnectWithAllModal(false)}
                            className="d-flex align-items-center justify-content-center"
                        >
                            {strings.globalTexts.cancel}
                        </CancelButton>
                        <ConfirmButton
                            variant="primary"
                            onClick={() => handleClose(sayHelloTextAreaRef.current!.value)}
                            className="d-flex align-items-center justify-content-center"
                        >
                            {strings.globalTexts.confirm}
                        </ConfirmButton>
                    </SubmitButtonContainer>
                </Modal.Footer>
            </MainModal>
        </>
    )

    // Handle full room
    let addPerson
    if (!props.showParticipantSearch) {
        addPerson = null
    } else if (props.meetingParticipants.length + stagedOpponents.length >= chime.getMaxAttendees()) {
        addPerson = <MaxAttendees>{strings.conferenceTexts.maxAttendees}</MaxAttendees>
    } else {
        addPerson = (
            <StyledSearchPersonInput
                shortenPersonInfo={true}
                className="styled-search-person-input"
                placeholder={strings.conferenceTexts.searchForParticipantsPlaceholder}
                personClicked={onPersonSelected}
                disableSearchIcon={false}
                disableIds={[
                    ...stagedOpponents.map((p) => p.id),
                    ...Object.keys(roster)
                        .map((attendeeId: string) => {
                            if (roster[attendeeId].id) return roster[attendeeId].id!
                            else return ""
                        })
                        .map((p) => p)
                ]}
                disabledText="Added"
            />
        ) // TODO extract string resource
    }

    return (
        <>
            <PannelHead style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                {addPerson}
                {stagedOpponents.length > 0 && (
                    <>
                        <StagedOpponentsArea
                            includeSelf={false}
                            opponents={stagedOpponents}
                            unstageOpponent={(unstageMe) =>
                                setStagedOpponents(stagedOpponents.filter((op) => op.id !== unstageMe.id))
                            }
                        />
                        <ButtonAdd
                            onClick={async () => {
                                stagedOpponents.map((person) => {
                                    meeting.sendInvite(person.id, { meetingId: chime.getName(), meetingKind: chime.getKind() })
                                    return null
                                })
                                setStagedOpponents([])
                            }}
                        >
                            {strings.conferenceTexts.addParticipantsText}
                        </ButtonAdd>
                    </>
                )}
            </PannelHead>
            <div>
                <PannelBody style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                    <ContentScrollContainer adjustForHeaderWith={"300px"} paddingLeft={"0px"}>
                        <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} />
                    </ContentScrollContainer>
                </PannelBody>
            </div>
            <div style={{ visibility: branding.hasConnectWithAllParticipants ? "visible" : "hidden" }}>
                <PannelFooter>
                    {Object.keys(roster).length > 1 && (
                        <ButtonConnect onClick={() => setShowConnectWithAllModal(true)}>
                            {strings.conferenceTexts.connectWithAllParticipantsText}
                        </ButtonConnect>
                    )}
                </PannelFooter>
            </div>
            {connectWithAllParticipantsModal}
        </>
    )
}

interface AttendeesLoadedListContentProps {
    meetingParticipants: RosterAttendeeType[]
}

const AttendeesLoadedListContent: React.FC<AttendeesLoadedListContentProps> = (props) => {
    const chime = useChimeContext()
    const strings = useLanguageState().getStrings()
    const loggedInUserId = useLoggedInState().user()?.profileId
    //const isMeetingPage = useRouteMatch(meetingPageRoute);
    const [kickOrBan, setKickOrBan] = useState<{ mode: KickOrBanMode; attendeeId?: string; userName?: string }>({
        mode: KickOrBanMode.NONE
    })

    const queryParams: any = queryString.parse(window.location.search)

    return (
        <>
            {props.meetingParticipants.map((attendee) => {
                const attendeeId = attendee.rosterId
                if (!attendee || (attendee.role === "recorder" && !chime.isMod() && !queryParams.debug)) {
                    return null
                }
                const modActions = new Array<Action>()
                if (chime.isMod() && attendeeId) {
                    modActions.push({
                        disabled: false,
                        title: strings.conferenceTexts.kick,
                        hint: strings.conferenceTexts.kickHint,
                        icon: IconRemoveFromCall({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => setKickOrBan({ mode: KickOrBanMode.KICK, attendeeId: attendeeId, userName: attendee.name })
                    })
                    modActions.push({
                        disabled: false,
                        title: strings.conferenceTexts.ban,
                        hint: strings.conferenceTexts.banHint,
                        icon: IconBlockContact({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => setKickOrBan({ mode: KickOrBanMode.BAN, attendeeId: attendeeId, userName: attendee.name })
                    })
                    modActions.push({
                        disabled: false,
                        title: strings.conferenceTexts.mute,
                        hint: strings.conferenceTexts.mute,
                        icon: IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => chime.modMute(attendeeId)
                    })
                    if (chime && chime.getShareScreenTile()?.boundAttendeeId?.replace("#content", "") === attendeeId) {
                        modActions.push({
                            disabled: false,
                            title: strings.conferenceTexts.shareScreenStop,
                            hint: strings.conferenceTexts.shareScreenStop,
                            icon: IconShareScreenStop({ fill: branding.sideIconBar.sideIconColorDark }),
                            onClick: () => chime.modStopContentShare(attendeeId)
                        })
                    }
                }
                if (attendee.handRaised && attendeeId) {
                    modActions.push({
                        disabled: false,
                        title: strings.conferenceTexts.lowerHand,
                        hint: strings.conferenceTexts.lowerHand,
                        icon: IconLowerHand({ fill: branding.sideIconBar.sideIconColorDark }),
                        onClick: () => chime.raiseHand(attendeeId, false)
                    })
                }

                const hideMenu = attendee.id === loggedInUserId || attendee.id === "recorder"
                return (
                    <Attendee
                        attendee={attendee}
                        hideMenu={hideMenu}
                        modActions={modActions}
                        isMyHandRaised={chime.isHandRaised()}
                    />
                )
            })}
            {kickOrBan.mode !== KickOrBanMode.NONE && (
                <KickUserModal kickOrBan={kickOrBan} setKickOrBan={setKickOrBan} chime={chime} />
            )}
        </>
    )
}

interface AttendeeProps {
    attendee: RosterAttendeeType
    hideMenu: boolean
    modActions: Action[]
    isMyHandRaised: boolean
}
function Attendee(props: AttendeeProps) {
    const activeSpeakerContext = useActiveSpeakerContext()

    const { attendee, hideMenu, modActions, isMyHandRaised } = props
    const contact = {
        id: attendee.id,
        profileId: attendee.id,
        lastName: attendee.name,
        logoUrl: attendee.avatarUrl,
        position: attendee.position,
        positionDe: attendee.positionDe,
        company: attendee.company
    }

    return (
        <AttendeeRoot>
            <StyledContactEntry
                contact={contact}
                hideMenu={hideMenu}
                additionalActions={modActions}
                withoutPresence={true}
                disabled={!attendee.rosterId}
                entryType={ContactEntryType.ROSTER}
                useOnlyLastNameForRoute={true}
            />
            {attendee.role === "moderator" && <ModIconElem left={"7px"} />}
            {attendee.role === "recorder" && <RecorderIconElem />}
            {activeSpeakerContext.isActiveSpeaker(attendee.rosterId) && <SpeakingIconElem />}
            {attendee.muted && <MutedIconElem />}
            {(attendee.handRaised || (hideMenu && isMyHandRaised)) && <HandRaisedIconElem />}
        </AttendeeRoot>
    )
}

const AttendeesLoadedAvatarContent: React.FC<{ meetingParticipants: RosterAttendeeType[]; isMyHandRaised?: any }> = (props) => {
    const activeSpeakerContext = useActiveSpeakerContext()
    const loggedInUserId = useLoggedInState().user()?.profileId
    const chime = useChimeContext()
    return (
        <>
            {props.meetingParticipants
                .filter((attendee) => attendee.rosterId)
                .map((attendee) => {
                    return (
                        <div style={{ marginBottom: "20px", position: "relative" }}>
                            <AvatarWithDefault
                                size={36}
                                alt={attendee.name ? attendee.name : ""}
                                src={attendee.avatarUrl}
                                backgroundSize="cover"
                            />
                            {attendee.role === "moderator" && <ModIconElem left={"-5px"} top={"-4px"} fontSize={"14px"} />}
                            {(attendee.handRaised || (loggedInUserId === attendee.id && chime.isHandRaised())) && (
                                <HandRaisedIconElem top={"15px"} left={"-7px"} />
                            )}
                            {attendee.muted && <MutedIconElem top={"-2px"} left={"25px"} />}
                            {activeSpeakerContext.isActiveSpeaker(attendee.rosterId) && (
                                <SpeakingIconElem top={"-2px"} left={"25px"} />
                            )}
                        </div>
                    )
                })}
        </>
    )
}

const ToggleLiveButton = styled.button`
    width: calc(100% - 30px);
    height: 35px;
    margin: 5px 15px 10px 15px;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  color: white;
                  transition-property: none;
              `
            : css`
                  &.green:hover {
                      box-shadow: 0px 0px 0px 5px hsla(120, 100%, 35%, 0.5);
                  }
                  &.red:hover {
                      box-shadow: 0px 0px 0px 5px hsla(0, 100%, 35%, 0.5);
                  }
              `};

    &.green {
        background-color: hsl(120, 100%, 35%); /* #00B300 */
    }
    &.red {
        background-color: hsl(0, 100%, 35%); /* #B30000 */
    }
`

const RoundedButton = styled.button`
    min-width: 35px;
    height: 35px;
    margin: 0 15px 0 0;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  transition-property: none;
              `
            : css`
                  &.locked:hover {
                      box-shadow: 0px 0px 0px 5px rgba(70, 130, 180, 0.5);
                  }
                  &.unlocked:hover {
                      box-shadow: 0px 0px 0px 5px rgba(30, 155, 255, 0.5);
                  }
              `};

    &.locked {
        background-color: steelblue;
    }
    &.unlocked {
        background-color: dodgerblue;
    }
`

const RestartRecorderButton = styled.button`
    min-width: 35px;
    height: 35px;
    padding: 5px 0 5px 2px;
    border-radius: 20px;
    transition: 0.5s;
    cursor: pointer;
    background-color: steelblue;

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  background-color: hsl(0, 0%, 70%) !important;
                  color: white;
                  transition-property: none;
              `
            : css`
                  &:hover {
                      box-shadow: 0px 0px 0px 5px rgba(30, 155, 255, 0.5);
                  }
              `};
`

const ButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    margin: 15px;
`

enum LiveStatus {
    UNKNOWN,
    OFF_AIR_UNLOCKED,
    PENDING_LOCKING,
    PENDING_UNLOCKING,
    OFF_AIR_LOCKED,
    PENDING_OFF_ON,
    ON_AIR,
    PENDING_ON_OFF
}

const minRecorderRestartDelay = 1000 * 120 // max. 1 restart every 2 minutes

const GreenRoomChannelStatusPresenter = styled.div`
    font-family: ${branding.font1};
    color: ${branding.roster.greenRoomPresenterViewColor};
`

const GreenRoomChannelStatusOperator = styled.div`
    font-family: ${branding.font1};
    color: ${branding.mainInfoColor};
`

interface GreenRoomOperatorViewProps {
    channelId?: string
    meetingParticipants: RosterAttendeeType[]
}

const GreenRoomOperatorView: React.FC<GreenRoomOperatorViewProps> = (props) => {
    const chime = useChimeContext()
    const channelStatus = chime.getChannelStatus()
    const isLocked = chime.isLocked()
    const roster = chime.getRoster()

    const [liveButtonEnabled, setLiveButtonEnabled] = useState(false)
    const [enableRecorderButtonHandle, setEnableRecorderButtonHandle] = useState<number | undefined>()
    const [recorderButtonEnabled, setRecorderButtonEnabled] = useState(false)
    const [liveStatus, setLiveStatus] = useState(LiveStatus.UNKNOWN) // additional state for between ON_AIR & OFF_AIR
    const [eta, setEta] = useState(0)
    const [lastRecorderPresentState, setLastRecorderPresentState] = useState(false)
    const isMeetingPage = useRouteMatch(meetingPageRoute)

    const strings = useLanguageState().getStrings().conferenceTexts

    const rosterKeys = Object.keys(roster)
    const recorderPresent = !!rosterKeys.find((key) => roster[key].id === "recorder")

    const onLiveStatusButtonClicked = async () => {
        if (channelStatus === ChannelStatus.OFF_AIR) {
            setLiveStatus(LiveStatus.PENDING_OFF_ON)
            chime.startLive()
        } else if (channelStatus === ChannelStatus.ON_AIR) {
            setLiveStatus(LiveStatus.PENDING_ON_OFF)
            const success = await chime.stopLive()
            if (!success) {
                // success is handled via chime.getChannelStatus()
                setLiveStatus(LiveStatus.ON_AIR)
            }
        }
    }

    const onLockParticipantsButtonClicked = async () => {
        if (liveStatus === LiveStatus.OFF_AIR_UNLOCKED) {
            const authorizedUsers = rosterKeys.filter((key) => roster[key].id !== undefined).map((key) => roster[key].id!)
            setLiveStatus(LiveStatus.PENDING_LOCKING)
            const success = await chime.lockChannel(authorizedUsers)
            if (!success) {
                // success is handled via chime.isLocked()
                setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        } else if (liveStatus === LiveStatus.OFF_AIR_LOCKED) {
            setLiveStatus(LiveStatus.PENDING_UNLOCKING)
            const success = await chime.unlockChannel()
            if (!success) {
                // success is handled via chime.isLocked()
                setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        }
    }

    const onRestartRecorderButtonClicked = async () => {
        const externalMeetingId = chime.getExternalMeetingId()
        if (externalMeetingId) {
            setRecorderButtonEnabled(false)
            setEnableRecorderButtonHandle(3)
            const response = await restartRecorder(externalMeetingId.substr(3))
            if ((response as BackendServiceError).httpStatus) {
                setRecorderButtonEnabled(true)
            } else if ((response as RestartRecorderResponse).success) {
                const handle = window.setTimeout(() => {
                    // disable recorder button for minRecorderRestartDelay ms
                    const recorderPresent = !!rosterKeys.find((key) => roster[key].id === "recorder")
                    setRecorderButtonEnabled(!recorderPresent)
                    setEnableRecorderButtonHandle(undefined)
                }, minRecorderRestartDelay)
                setEnableRecorderButtonHandle(handle)
            }
        }
    }

    useEffect(() => {
        if (isLocked) {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_UNLOCKED:
                case LiveStatus.PENDING_LOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_LOCKED)
            }
        } else {
            switch (liveStatus) {
                case LiveStatus.OFF_AIR_LOCKED:
                case LiveStatus.PENDING_UNLOCKING:
                case LiveStatus.UNKNOWN:
                    setLiveStatus(LiveStatus.OFF_AIR_UNLOCKED)
            }
        }
    }, [isLocked]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        switch (channelStatus) {
            case ChannelStatus.OFF_AIR:
                setLiveStatus(isLocked ? LiveStatus.OFF_AIR_LOCKED : LiveStatus.OFF_AIR_UNLOCKED)
                break
            case ChannelStatus.ON_AIR:
                setLiveStatus(LiveStatus.ON_AIR)
                chime.realtimeMuteLocalAudio()
                break
            case ChannelStatus.PREPARING:
                setLiveStatus(LiveStatus.PENDING_OFF_ON)
                setEta(branding.greenroomGoLiveFollowupDelaySec * 1000) // TODO use time to live value returned from backend on going live instead
                const handle = setInterval(() => {
                    setEta((eta) => {
                        if (eta <= 1000) {
                            clearInterval(handle)
                            return 0
                        }
                        return eta - 1000
                    })
                }, 1000)
                return () => clearInterval(handle)
        }
    }, [channelStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            lastRecorderPresentState &&
            !recorderPresent &&
            (liveStatus === LiveStatus.ON_AIR || liveStatus === LiveStatus.PENDING_OFF_ON)
        ) {
            ;(async () => {
                setLiveStatus(LiveStatus.PENDING_ON_OFF)
                const success = await chime.stopLive("error")
                if (!success) {
                    // success is handled via chime.getChannelStatus()
                    setLiveStatus(LiveStatus.ON_AIR)
                }
            })()
        }
        if (liveStatus === LiveStatus.OFF_AIR_UNLOCKED || (!recorderPresent && liveStatus === LiveStatus.OFF_AIR_LOCKED)) {
            setLiveButtonEnabled(false)
        } else {
            setLiveButtonEnabled(liveStatus === LiveStatus.OFF_AIR_LOCKED || liveStatus === LiveStatus.ON_AIR)
        }
        setLastRecorderPresentState(recorderPresent)
    }, [recorderPresent, liveStatus]) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (recorderPresent) {
            if (enableRecorderButtonHandle) {
                clearTimeout(enableRecorderButtonHandle)
                setEnableRecorderButtonHandle(undefined)
            }
            setRecorderButtonEnabled(false)
        } else {
            setRecorderButtonEnabled(enableRecorderButtonHandle === undefined)
        }
    }, [recorderPresent]) // eslint-disable-line react-hooks/exhaustive-deps

    const liveButtonLabel = calcLiveButtonLabel(liveStatus, strings)
    const lockButtonLabel = calcLockButtonLabel(liveStatus, strings)
    const liveButtonClassName = liveStatus === LiveStatus.OFF_AIR_LOCKED ? "green" : liveStatus === LiveStatus.ON_AIR ? "red" : ""
    const lockButtonClassName =
        liveStatus === LiveStatus.OFF_AIR_LOCKED ? "locked" : liveStatus === LiveStatus.OFF_AIR_UNLOCKED ? "unlocked" : ""
    const lockButtonEnabled = liveStatus === LiveStatus.OFF_AIR_UNLOCKED || liveStatus === LiveStatus.OFF_AIR_LOCKED

    return (
        <>
            <PannelBody
                meetingKind={chime.getKind()}
                style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}
            >
                <ContentScrollContainer adjustForHeaderWith={"300px"} white={"true"} paddingLeft={"0px"}>
                    <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} />
                </ContentScrollContainer>
            </PannelBody>
            <PannelFooter>
                {channelStatus === ChannelStatus.ON_AIR && (
                    <GreenRoomChannelStatusOperator>
                        <LiveIconElem /> {strings.onAirMessage} <LiveIconElem />
                    </GreenRoomChannelStatusOperator>
                )}

                {channelStatus === ChannelStatus.PREPARING && (
                    <GreenRoomChannelStatusOperator>
                        {strings.liveInMessage} {Math.floor(eta / 1000)}
                    </GreenRoomChannelStatusOperator>
                )}

                {liveStatus === LiveStatus.OFF_AIR_LOCKED && (
                    <GreenRoomChannelStatusOperator>
                        <LockIconElem /> {strings.roomLockedTitle} <LockIconElem />
                    </GreenRoomChannelStatusOperator>
                )}
                <ToggleLiveButton
                    className={liveButtonClassName}
                    disabled={!liveButtonEnabled}
                    onClick={onLiveStatusButtonClicked}
                >
                    {liveButtonLabel}
                </ToggleLiveButton>
                <ButtonRow>
                    <RoundedButton
                        className={lockButtonClassName}
                        disabled={!lockButtonEnabled}
                        onClick={onLockParticipantsButtonClicked}
                        style={{ flex: "1 1 auto" }}
                    >
                        {lockButtonLabel}
                    </RoundedButton>
                    <RestartRecorderButton
                        disabled={!recorderButtonEnabled}
                        onClick={onRestartRecorderButtonClicked}
                        style={{ flex: "0 0 auto" }}
                    >
                        <span role="img" aria-label={"Restart Recorder"}>
                            🎥
                        </span>
                    </RestartRecorderButton>
                </ButtonRow>
            </PannelFooter>
        </>
    )
}

function calcLiveButtonLabel(liveStatus: LiveStatus, strings: any) {
    switch (liveStatus) {
        case LiveStatus.OFF_AIR_UNLOCKED:
        case LiveStatus.PENDING_LOCKING:
        case LiveStatus.PENDING_UNLOCKING:
        case LiveStatus.OFF_AIR_LOCKED:
            return strings.goLiveMessage
        case LiveStatus.PENDING_OFF_ON:
            return strings.goingLiveMessage
        case LiveStatus.ON_AIR:
            return strings.stopLiveMessage
        case LiveStatus.PENDING_ON_OFF:
            return strings.stoppingLiveMessage
    }
    return strings.initializingMessage
}

function calcLockButtonLabel(liveStatus: LiveStatus, strings: any) {
    switch (liveStatus) {
        case LiveStatus.OFF_AIR_UNLOCKED:
            return strings.lockRoomMessage
        case LiveStatus.PENDING_LOCKING:
            return strings.lockingMessage
        case LiveStatus.PENDING_UNLOCKING:
            return strings.unlockingMessage
        case LiveStatus.PENDING_OFF_ON:
        case LiveStatus.ON_AIR:
        case LiveStatus.PENDING_ON_OFF:
        case LiveStatus.OFF_AIR_LOCKED:
            return strings.unlockRoomMessage
    }
    return strings.initializingMessage
}

const JoinBreakoutButton = styled(NavLink)<{ disabled?: boolean }>`
    display: flex;
    margin-bottom: 20px;
    margin-left: 5px;
    margin-right: 5px;
    border: 1px solid ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    border-radius: 40px;
    height: 50px;
    justify-content: center;
    align-items: center;
    pointer-events: ${(props) => (props.disabled ? "none" : "auto")};
    opacity: ${(props) => (props.disabled ? "50%" : "100%")};
    font-family: ${branding.font1};
    background-color: ${branding.videoPageContentBranding.joinBreakoutButtonColor};
    color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};

    &:hover,
    &:active,
    &:link,
    &:focus {
        text-decoration: none;
        color: ${branding.videoPageContentBranding.joinBreakoutButtonTextColor};
    }
`

const JoinBreakoutMessage = styled.div`
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-family: ${branding.font1};
    margin-bottom: 8px;
`

const GreenRoomPresentersView: React.FC<GreenRoomOperatorViewProps> = (props) => {
    const chime = useChimeContext()
    const channelStatus = chime.getChannelStatus()
    const [eta, setEta] = useState(0)
    const isMeetingPage = useRouteMatch(meetingPageRoute)
    const strings = useLanguageState().getStrings().conferenceTexts

    const [currentEventDate, setCurrentEventDate] = useState<EventDate>()

    const stringsVideo = useLanguageState().getStrings().videoPageContentBranding

    useEffect(() => {
        loadData()
        // eslint-disable-next-line
    }, [props.channelId])

    function loadData() {
        loadChannelFirstEventDate(props.channelId || "")
            .then((data) => {
                if ((data as BackendServiceError).httpStatus) {
                    const backendError = data as BackendServiceError

                    logger.error(backendError.httpStatusText)
                } else {
                    const resp = data as EventDateChannelFirstDetailsResponse
                    setCurrentEventDate(resp.currentEventDate)
                }
            })
            .catch((err) => logger.error(err.message))
    }

    useEffect(() => {
        if (channelStatus === ChannelStatus.PREPARING) {
            setEta(branding.greenroomGoLiveFollowupDelaySec * 1000) // TODO use time to live value returned from backend on going live instead
            const handle = setInterval(() => {
                setEta((eta) => {
                    if (eta <= 1000) {
                        clearInterval(handle)
                        return 0
                    }
                    return eta - 1000
                })
            }, 1000)
        }
    }, [channelStatus])

    return (
        <>
            <PannelBody style={{ backgroundColor: isMeetingPage ? branding.darkenThemeBackgroundColor ?? "#000" : "#fff" }}>
                <ContentScrollContainer adjustForHeaderWith={"300px"} white={"true"} paddingLeft={"0px"}>
                    <AttendeesLoadedListContent meetingParticipants={props.meetingParticipants} />
                </ContentScrollContainer>
            </PannelBody>
            <PannelFooter style={{ paddingBottom: "20px" }}>
                {branding.configuration.breakoutEnabled && currentEventDate && currentEventDate?.breakoutAccess && (
                    <>
                        <JoinBreakoutMessage>{stringsVideo.joinBreakoutMessage}</JoinBreakoutMessage>
                        <JoinBreakoutButton to={`/meeting/br_${currentEventDate?.id! || ""}/createorjoin`}>
                            {stringsVideo.joinBreakoutButtonText}
                        </JoinBreakoutButton>
                    </>
                )}
                {channelStatus === ChannelStatus.OFF_AIR && (
                    <GreenRoomChannelStatusPresenter>{strings.currentlyNotLiveMessage}</GreenRoomChannelStatusPresenter>
                )}

                {channelStatus === ChannelStatus.PREPARING && (
                    <GreenRoomChannelStatusPresenter>
                        {strings.gettingLiveInMessage} {Math.floor(eta / 1000)}
                    </GreenRoomChannelStatusPresenter>
                )}
                {channelStatus === ChannelStatus.ON_AIR && (
                    <GreenRoomChannelStatusPresenter>
                        <LiveIconElem /> {strings.onAirMessage} <LiveIconElem />
                    </GreenRoomChannelStatusPresenter>
                )}
            </PannelFooter>
        </>
    )
}

const LockIconElem: React.FC = () => {
    return (
        <span role="img" aria-label={"ON AIR"}>
            🔒
        </span>
    )
}
const LiveIconElem: React.FC = () => {
    return (
        <span role="img" aria-label={"ON AIR"}>
            🔴
        </span>
    )
}
interface IconElemProps {
    top?: string
    right?: string
    bottom?: string
    left?: string
    fontSize?: string
}

const ModIconElem: React.FC<IconElemProps> = (props) => {
    const strings = useLanguageState().getStrings()
    return (
        <ModIcon left={props.left} top={props.top} fontSize={props.fontSize}>
            <span role="img" aria-label={strings.conferenceTexts.moderator} title={strings.conferenceTexts.moderator}>
                {IconStaff({ fill: branding.sideIconBar.sideIconColorDark })}
            </span>
        </ModIcon>
    )
}
const RecorderIconElem: React.FC = () => {
    const strings = useLanguageState().getStrings()
    return (
        <ModIcon>
            <span role="img" aria-label={strings.conferenceTexts.moderator} title={strings.conferenceTexts.moderator}>
                🎥
            </span>
        </ModIcon>
    )
}
const HandRaisedIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <HandRaisedIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconRaiseHand({ fill: branding.sideIconBar.sideIconColorDark })}
        </HandRaisedIcon>
    )
}
const SpeakingIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <SpeakingIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconVolumeHigh({ fill: branding.sideIconBar.sideIconColorDark })}
        </SpeakingIcon>
    )
}
const MutedIconElem: React.FC<IconElemProps> = (props) => {
    return (
        <SpeakingIcon top={props.top} right={props.right} bottom={props.bottom} left={props.left}>
            {IconMicrophoneOff({ fill: branding.sideIconBar.sideIconColorDark })}
        </SpeakingIcon>
    )
}

const ChatContent = styled.div<{ guestBannerHeight: number }>`
    height: calc(100vh - 122px - ${(props) => props.guestBannerHeight + "px"}); /* Height of topbar + roster header */
`

interface ChatContainerProps {
    viewMode: SideBarViewMode
    guestBannerHeight: number
    virtualCafe?: boolean
}

const ChatContainer: React.FC<ChatContainerProps> = (props) => {
    const { roomName }: any = useParams()
    return (
        <ChatContent
            style={props.viewMode === SideBarViewMode.CHAT ? {} : { display: "none" }}
            guestBannerHeight={props.guestBannerHeight}
        >
            <ChatPage
                displayStyle={DisplayStyle.DEFAULT}
                param={ChatConversationParam.conversationByConversationId(ConversationType.PUBLIC, calcConversationId(roomName))}
                viewMode={props.viewMode}
                virtualCafe={props.virtualCafe}
            />
        </ChatContent>
    )
}
