import React, { useEffect, useState } from "react"
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import styled from "styled-components"
import branding from "../branding/branding"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../utils/Device"
import { IconArrowHeadDown, IconArrowHeadUp, IconBookmarkFilled, IconCardView, IconListView } from "./Icons"
import useWindowDimensions from "./WindowDimensionsHook"

const CrsTabsRoot = styled.div<{ showOnlyActionButtons: boolean; showShadow?: boolean }>`
    width: 100%;
    position: relative;
    padding: 0;
    margin: 0 auto;
    color: ${branding.crsTabs.tabItemDefaultTextColor};
    background-color: ${branding.crsTabs.defaultBackgroundColor};
    overflow: hidden;
    min-height: 30px;
    padding-bottom: ${(props) => (props.showOnlyActionButtons ? "44px" : "0")};
    box-shadow: ${(props) => (props.showShadow ? branding.primaryScrollDarkShadowTTB : "initial")};

    @media ${device.tablet} {
        padding: 0 25px;
    }
`

const TabsHeader = styled.div`
    width: 100%;
    position: relative;
    overflow: hidden;
    white-space: nowrap;
    padding: 10px 10px 10px 0;
    z-index: 2;
    border-bottom: 1px solid ${branding.crsTabs.defaultBorderColor};
    &:hover {
        overflow-x: auto;
    }
`

const TabItem = styled.div<{ width: number }>`
    display: inline-block;
    box-sizing: content-box;
    width: ${(props) => (props.width ? `${props.width}px` : "auto")};
    text-align: center;
    font-style: normal;
    font-weight: normal;
    font-family: ${branding.font1};
    font-size: ${branding.crsTabs.tabItemDefaultFontSize};
    line-height: 12px;
    cursor: pointer;
    &.active {
        font-weight: bold;
        color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
    }
`

const ActiveTabItemIndicator = styled.div<{ width: number; left: number }>`
    width: ${(props) => `${props.width}px`};
    height: 3px;
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: ${(props) => `${props.left}px`};
    background-color: ${branding.crsTabs.tabItemDefaultActiveStateColor};
    transition: left 0.5s;
`

const TabHeaderActions = styled.div<{ showShadow: boolean }>`
    position: absolute;
    right: 23px;
    top: 0;
    bottom: 1px;
    z-index: 10;
    background-color: #fff;
    display: flex;
    align-items: center;

    @media ${device.mobile} {
        right: 0px;
        width: 50px;
        box-shadow: ${(props) => (props.showShadow ? "0px 4px 8px 1px rgba(217, 217, 217, 1)" : "initial")};
    }
`

const TabHeaderActionsItem = styled.div<{ display?: string; hideViewIcon?: boolean }>`
    cursor: pointer;
    display: ${(props) => (props.hideViewIcon ? "none" : "block")};
    :last-child {
        margin-left: 10px;
    }
`

export enum ActionTypes {
    TILELIST = "TILELIST",
    BOOKMARK = "BOOKMARK",
    TOGGLEFILTERS = "TOGGLEFILTERS"
}

export interface TabItemType {
    label: string
    value: string
    actions?: ActionTypes[]
}

export enum ViewMode {
    LIST,
    TILES
}

interface CrsTabsProps {
    tabItems: TabItemType[]
    tabsContentHeight?: string
    activeItem?: any
    onTabItemClicked?: (tabItem: string) => void
    viewMode?: ViewMode
    onViewModeChange?: (viewMode: ViewMode) => void
    hideBookmarkButton?: boolean
    bookmarkFilter?: boolean
    setBookmarkFilter?: (value: boolean) => void
    filtersVisible?: boolean
    setFiltersVisible?: (value: boolean) => void
    responsive?: boolean
    isBlocked?: boolean
    onViewModeToggle?: () => void
    showOnlyActionButtons?: boolean
    style?: React.CSSProperties | undefined

    itemWidth?: number
    mobileItemWidth?: number

    showShadow?: boolean
    customWindowSizeForViewSwitch?: number
}

const CrsTabs = (props: CrsTabsProps) => {
    const [widestTabItemWidth, setWidestTabItemWidth] = useState(0)
    const [activeIndicatorLeft, setActiveIndicatorLeft] = useState(0)
    const [selectedTabItem, setSelectedTabItem] = useState(
        props.activeItem
            ? props.tabItems.find((item: TabItemType) => item.value === props.activeItem) || props.tabItems[0]
            : props.tabItems[0]
    )

    const { isMobile, width } = useWindowDimensions()
    let itemPadding = props.itemWidth ?? (isMobile ? props.mobileItemWidth ?? 120 : 200)

    const onTabItemClicked = (tabItem: TabItemType) => {
        if (props.onTabItemClicked) {
            props?.onTabItemClicked(tabItem.value)
        }

        setSelectedTabItem(tabItem)
    }

    const setWidestTabItemWidthMethod = () => {
        let tabItems = document.querySelectorAll("div.tab-item")
        let maxWidth = 0
        let totalWidth = maxWidth + itemPadding // padding
        tabItems.forEach((element) => {
            if (element.clientWidth > maxWidth) maxWidth = element.clientWidth
        })
        setWidestTabItemWidth(totalWidth)
    }

    useEffect(() => {
        setWidestTabItemWidthMethod()
        if (props.activeItem) {
            setSelectedTabItem(props.tabItems.find((item: TabItemType) => item.value === props.activeItem) || props.tabItems[0])
            setActiveIndicatorLeft(props.tabItems.findIndex((x) => x.value === props.activeItem) * itemPadding)
        }
        // eslint-disable-next-line
    }, [props.tabItems])

    const ListViewToggleButton: React.FunctionComponent = () => {
        const onToggleViewModeClicked = () => {
            if (props.viewMode === ViewMode.LIST) {
                if (props.onViewModeChange) {
                    props.onViewModeChange!(ViewMode.TILES)
                } else if (props.onViewModeToggle) {
                    props.onViewModeToggle()
                }
            } else {
                if (props.onViewModeChange) {
                    props.onViewModeChange!(ViewMode.LIST)
                } else if (props.onViewModeToggle) {
                    props.onViewModeToggle()
                }
            }
        }

        return (
            <>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="listtileview-tooltip" style={{ fontFamily: branding.font1 }}>
                            {props.viewMode === ViewMode.TILES
                                ? branding.crsTabs.listViewTooltip
                                : branding.crsTabs.tileViewTooltip}
                        </Tooltip>
                    }
                >
                    <TabHeaderActionsItem
                        onClick={onToggleViewModeClicked}
                        display={props.responsive && width < 1350 ? "none" : "flex"}
                        hideViewIcon={
                            props.isBlocked || (props.responsive && width < (props.customWindowSizeForViewSwitch ?? 1350))
                        }
                    >
                        {props.viewMode === ViewMode.TILES
                            ? IconListView({ fill: branding.crsTabs.defaultActionItemColor })
                            : IconCardView({
                                  fill: branding.crsTabs.defaultActionItemActiveStateColor
                              })}
                    </TabHeaderActionsItem>
                </OverlayTrigger>
            </>
        )
    }

    const BookmarkButton: React.FunctionComponent = () => {
        function handleClick() {
            if (props.setBookmarkFilter && props.bookmarkFilter !== undefined) {
                props.setBookmarkFilter(!props.bookmarkFilter)
            }
        }
        return (
            <DesktopVersionContainer>
                <OverlayTrigger
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    overlay={
                        <Tooltip id="bookmarkview-tooltip" style={{ fontFamily: branding.font1 }}>
                            {branding.crsTabs.bookmarkViewTooltip}
                        </Tooltip>
                    }
                >
                    <TabHeaderActionsItem onClick={handleClick}>
                        {props.bookmarkFilter
                            ? branding.defaultToggleIcon
                                ? IconBookmarkFilled({
                                      fill: branding.crsTabs.defaultActionItemActiveStateColor
                                  })
                                : IconBookmarkFilled({
                                      fill: branding.crsTabs.defaultActionItemColor
                                  })
                            : IconBookmarkFilled({
                                  fill: branding.crsTabs.defaultActionItemColor
                              })}
                    </TabHeaderActionsItem>
                </OverlayTrigger>
            </DesktopVersionContainer>
        )
    }

    const ToggleFiltersButton: React.FunctionComponent = () => {
        function handleClick() {
            if (props.setFiltersVisible && props.filtersVisible !== undefined) {
                props.setFiltersVisible(!props.filtersVisible)
            }
        }
        return (
            <MobileVersionContainer>
                <TabHeaderActionsItem onClick={handleClick}>
                    {props.filtersVisible
                        ? IconArrowHeadUp({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })
                        : IconArrowHeadDown({ fill: branding.crsTabs.tabItemDefaultActiveStateColor })}
                </TabHeaderActionsItem>
            </MobileVersionContainer>
        )
    }

    return (
        <CrsTabsRoot
            showShadow={props.showShadow}
            showOnlyActionButtons={props.showOnlyActionButtons || false}
            style={props.style}
        >
            <TabsHeader
                style={{
                    display: props.showOnlyActionButtons ? "none" : "block",
                    paddingRight: selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) ? "60px" : "10px"
                }}
            >
                {props.tabItems.map((tabItem, index) => {
                    return (
                        <TabItem
                            key={index}
                            className={[selectedTabItem.value === tabItem.value ? "active" : "", "tab-item"].join(" ")}
                            width={widestTabItemWidth}
                            onClick={() => onTabItemClicked(tabItem)}
                        >
                            {tabItem.label}
                        </TabItem>
                    )
                })}
                <ActiveTabItemIndicator width={widestTabItemWidth} left={activeIndicatorLeft} />
            </TabsHeader>
            <TabHeaderActions
                showShadow={(selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) ?? false) && props.tabItems.length >= 3}
            >
                {selectedTabItem.actions?.includes(ActionTypes.TILELIST) && <ListViewToggleButton />}
                {selectedTabItem.actions?.includes(ActionTypes.BOOKMARK) && <BookmarkButton />}
                {selectedTabItem.actions?.includes(ActionTypes.TOGGLEFILTERS) && <ToggleFiltersButton />}
            </TabHeaderActions>
        </CrsTabsRoot>
    )
}

export default CrsTabs
