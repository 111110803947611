import React, { useRef, useEffect, useState, Dispatch, SetStateAction, useImperativeHandle, forwardRef } from "react"
import videojs, { VideoJsPlayer } from "video.js"
import styled from "styled-components"
import { keyframes } from "styled-components"
import { useHistory } from "react-router-dom"
import { useAppState } from "../../globalStates/AppState"
import { buildDetailLink } from "../detailPages/DetailNavLink"
import { useLanguageState } from "../../globalStates/LanguageState"
import "./Video-js.css"
import { ResizeState } from "./PictureInPictureVideoPlayer"

/* mini-version of VideoPlayer.tsx for Picture-In-Picture-Mode,
livestream-capable video-player which uses the open source library video.js
video.js implements HLS-Streaming
the UI is styled by changing the default CSS-skin that video.js provides */

/* ################### video player logic #################################################################################################*/

const usePlayer = (
    channelId: string | undefined,
    url: string | undefined,
    userControlsEnabled: boolean,
    setErrorOccured: Dispatch<SetStateAction<boolean>>
) => {
    const appState = useAppState()
    const history = useHistory()
    const strings = useLanguageState().getStrings()

    const videoRef = useRef(null)
    const [player, setPlayer] = useState<VideoJsPlayer | null>(null)

    // instantiate and dispose of player only once
    // similar to componentDidMount / componentDidUpdate / componentWillUnmount
    // do something after render
    useEffect(() => {
        if (!channelId || !url) {
            return undefined
        }
        const options = {
            autoplay: false,
            controls: userControlsEnabled,
            preload: "auto",
            liveui: true,
            fluid: true,
            userActions: {
                // doubleClick: handlePlayerDoubleClick,
                hotkeys: {
                    fullscreenKey: () => false,
                },
            },
            html5: {
                vhs: {
                    withCredentials: true,
                    enableLowInitialPlaylist: true,
                    smoothQualityChange: true,
                    overrideNative: true,
                },
                nativeAudioTracks: false,
                nativeVideoTracks: false,
            },
            sources: [
                {
                    src: url,
                    type: "application/x-mpegURL",
                },
            ],
            controlBar: {
                children: ["PlayToggle"],
            },
        }

        let audioTrackList: videojs.TrackList

        function addTrack(e: Event) {
            // if audio track was previously set in appState, choose this audio track
            // don't do anything, if only one audio track was added
            const audioTrackFromAppState = appState.videoPlayerStatus?.audioTrack
            if (audioTrackFromAppState && audioTrackList.length > 1) {
                for (let i = 0; i < audioTrackList.length; i++) {
                    let track = audioTrackList[i]
                    if (track !== undefined) {
                        const trackAny = track as any
                        if (trackAny.label === audioTrackFromAppState) {
                            // setting enabled to true does not seem to trigger a change event, so we trigger it ourselves
                            trackAny.enabled = true
                            audioTrackList.trigger("change")
                        } else {
                            trackAny.enabled = false
                        }
                    }
                }
            }
        }

        function assertOneTrack(e: Event) {
            // todo: this only works for two audio tracks
            // prevent activating two audio tracks at the same time
            if (audioTrackList.length > 1) {
                const trackAny0 = audioTrackList[0] as any
                const trackAny1 = audioTrackList[1] as any
                if (trackAny0.enabled === trackAny1.enabled) {
                    e.stopImmediatePropagation()
                }
            }
        }
        // pass player ready callback as third argument
        const vjsPlayer = videojs(videoRef.current, { ...options }, function (this: VideoJsPlayer) {
            // read volume level, mute-state and play/pause-state from appState, so it stays the same when user switches
            // between Video- and PiP-Player

            /* the following lines deal with the feature, that video.js-Player allows a state where volume is 0 but mute 
            is set to false and vice versa, this is probably due to the fact that muting can be toggled via two UI-Elements:
            volume-slider and mute-toggle-button,
            this is probably on purpose so the user can mute player via mute button and then switch back to the previous
            volume, when mute is turned off again */
            const tmpAppstate = appState.videoPlayerStatus
            if (tmpAppstate) {
                if (tmpAppstate.volume === 0) {
                    this.volume(0)
                    this.muted(true)
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                } else if (tmpAppstate.isMuted === true) {
                    this.muted(true)
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                } else {
                    if (tmpAppstate.volume) {
                        this.volume(tmpAppstate.volume)
                    }
                    this.muted(false)
                    // this.trigger('volumechange');   // update UI-Element Volume-Bar
                }

                if (tmpAppstate.isPaused !== undefined) {
                    if (appState.videoPlayerStatus?.isPaused) {
                        vjsPlayer.pause()
                    } else {
                        vjsPlayer.play()
                    }
                }
            }

            // { IWillNotUseThisInPlugins: true } suppresses warning about tech usage
            audioTrackList = vjsPlayer.tech({ IWillNotUseThisInPlugins: true })?.audioTracks()

            if (audioTrackList) {
                audioTrackList.addEventListener("addtrack", addTrack)
                audioTrackList.addEventListener("change", assertOneTrack)
            }
        }) // end player init

        // load error is displayed using appstrings from branding.js
        videojs.addLanguage("de", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.pipStreamErrorMessage,
        })

        videojs.addLanguage("en", {
            "The media could not be loaded, either because the server or network failed or because the format is not supported.":
                strings.videoPlayerBranding.pipStreamErrorMessage,
        })

        vjsPlayer.src(url)

        const controlBar = vjsPlayer.getChild("ControlBar")

        if (vjsPlayer) {
            const bigPlayButton = vjsPlayer.getChild("BigPlayButton")
            if (bigPlayButton) {
                vjsPlayer.removeChild(bigPlayButton)
            }
        }

        function handleError(event: React.MouseEvent) {
            setErrorOccured(true)
        }
        vjsPlayer.on("error", handleError)

        if (controlBar) {
            controlBar.on("dblclick", handlePlayerDoubleClick)
        }

        vjsPlayer.addClass("vjs-miniplayer")

        setPlayer(vjsPlayer)

        // returned function is called as cleanup
        return () => {
            // write volume level, mute-state and play/pause-state into appState, so it stays the same when user switches
            // between Video- and PiP-Player

            appState.setVideoPlayerStatus({
                volume: vjsPlayer.volume(),
                isMuted: vjsPlayer.muted(),
                isPaused: vjsPlayer.paused(),
                audioTrack: appState.videoPlayerStatus?.audioTrack,
            })
            if (audioTrackList) {
                audioTrackList.removeEventListener("change", assertOneTrack)
                audioTrackList.removeEventListener("addtrack", addTrack)
            }
            controlBar?.off("dblclick", handlePlayerDoubleClick)
            vjsPlayer.off("error", handleError)
            vjsPlayer.dispose()
        }
        // passing empty array as second argument will result in the effect and clean up running only once (on mount and unmount)
        // effect doesn't depend on any value from props or state
    }, []) //eslint-disable-line

    function handlePlayerDoubleClick(event: React.MouseEvent) {
        if (player) {
            player.dispose()
        }
        if (channelId) {
            history.push(buildDetailLink(channelId, "", "channel"))
        }
    }

    if (!channelId || !url) {
        return undefined
    }

    return { player: player, videoRef: videoRef }
} // end usePlayer()

export interface PictureInPictureVideoPlayerProps {
    userControlsEnabled: boolean
    resizeState: ResizeState
    errorOccured: boolean
    setErrorOccured: Dispatch<SetStateAction<boolean>>
}

const PictureInPictureVideoPlayerReal = forwardRef((props: PictureInPictureVideoPlayerProps, ref) => {
    const appState = useAppState()
    const playerRef = usePlayer(
        appState.liveStreamChannel?.id,
        appState.liveStreamChannel?.url,
        props.userControlsEnabled,
        props.setErrorOccured
    )
    const strings = useLanguageState().getStrings()

    useImperativeHandle(ref, () => ({
        handleClick: () => {
            const player = playerRef?.player
            if (!player) {
                return
            }
            if (player.paused()) {
                player.play()
            } else {
                player.pause()
            }
        },
    }))

    if (!playerRef) {
        return <></>
    }

    return (
        <PictureInPicturePlayerRoot title={props.errorOccured ? "" : strings.videoPlayerBranding.pipDoubleClickHint}>
            <div data-vjs-player>
                <video
                    ref={playerRef.videoRef}
                    className="video-js"
                    data-isPip="true"
                    data-isStream="true"
                    title={appState.liveStreamChannel?.eventDate?.name}
                ></video>
            </div>
        </PictureInPicturePlayerRoot>
    )
}) // end PictureInPicturePlayer

/* ################### UI styling #########################################################################################################*/

// keyframes for fading animations (pause and play)

// pause animation
const screenFadePause = keyframes`
    from {
        opacity: 1;
    }

    to {
        opacity: 0.25;
    }
`

const logoFadePause = keyframes`
    from {
        opacity: 0.25;
        transform: scale(2);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
`

// play animation
const screenFadePlay = keyframes`
    from {
        opacity: 0.25;
    }

    to {
        opacity: 1;
    }
`

const logoFadePlay = keyframes`
    from {
        opacity: 1;
        transform: scale(1);
    }

    to {
        opacity: 0.25;
        transform: scale(2);
    }
`

export const PictureInPicturePlayerRoot = styled.div`
    z-index: 1400;
    position: relative;
    display: flex;
    justify-content: center; // centered horizontally
    align-items: center; // centered vertically

    /* video player  */
    #virtualGuide & .video-js.vjs-miniplayer {
        cursor: pointer;
    }

    /* video element */
    #virtualGuide & .vjs-miniplayer .vjs-tech {
        background-color: #000;
        box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.4);
    }

    /* paused: fade out to darkened screen and fade in logo when paused */
    #virtualGuide & .vjs-miniplayer.vjs-paused .vjs-tech {
        animation: ${screenFadePause} 0.2s linear;
        opacity: 0.25;
    }

    #virtualGuide & .vjs-miniplayer.vjs-paused.vjs-has-started .vjs-play-control {
        animation: ${logoFadePause} 0.2s linear;
    }

    /* playing: reverse on-pause-animation when playback resumes*/
    #virtualGuide & .vjs-miniplayer.vjs-playing .vjs-tech {
        animation: ${screenFadePlay} 0.2s linear;
    }

    #virtualGuide & .vjs-miniplayer.vjs-playing.vjs-has-started .vjs-play-control {
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        animation: ${logoFadePlay} 0.2s linear;
        opacity: 0;
    }

    #virtualGuide & .vjs-play-control {
        pointer-events: none;
    }

    #virtualGuide & #vjs_video_3:hover .vjs-big-play-button {
        background-color: transparent;
    }

    /* control bar */
    #virtualGuide & .vjs-control-bar {
        /* pointer-events: none; */
        background: transparent;
        height: 100%;

        // center child elements that have position:absolute
        position: absolute;
        display: flex;
        justify-content: center; // horizontally centered
        align-items: center; // vertically centered
    }

    /* error display */
    #virtualGuide & .vjs-error-display.vjs-modal-dialog::before {
        text-indent: -9999px;
    }

    #virtualGuide & .vjs-error-display.vjs-modal-dialog .vjs-modal-dialog-content {
        padding-top: 22% !important;
        cursor: default;
        overflow: hidden;
    }
    /* control buttons - icons */
    #virtualGuide & .vjs-big-play-button {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        border-style: none !important;
        border-radius: 1px;
        padding: 0 0 0 0;
        /* center play button */
        position: absolute !important;
    }

    #virtualGuide & .vjs-button[title="Play"] {
        background: url(/videoPlayerIcons/play.svg) no-repeat;
        width: 23px !important;
    }

    #virtualGuide & .vjs-button[title="Pause"] {
        background: url(/videoPlayerIcons/pause.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Pause"],
    #virtualGuide & .vjs-button[title="Play"],
    #virtualGuide & .vjs-big-play-button {
        position: absolute;
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
    }

    #virtualGuide & .vjs-button[title="Mute"] {
        background: url(/videoPlayerIcons/volume-3.svg) no-repeat;
    }

    #virtualGuide & .vjs-mute-control[title="Unmute"] {
        background: url(/videoPlayerIcons/volume-mute.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Replay"] {
        background: url(/videoPlayerIcons/skip-back.svg) no-repeat;
    }

    #virtualGuide & .vjs-button[title="Captions"] {
        background: url(/videoPlayerIcons/settings.svg) no-repeat;
    }

    #virtualGuide & .vjs-theatermode-control {
        background: url(/videoPlayerIcons/theatre-mode.svg) no-repeat;
        height: 38px !important;
        width: 38px !important;
        margin-top: 1px !important;
    }

    /* animate volume icon for different levels */
    #virtualGuide & .vjs-icon-volume-low {
        background: url(/videoPlayerIcons/volume-low.svg) no-repeat;
    }

    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"] {
        background: url(/videoPlayerIcons/volume-1.svg) no-repeat;
    }
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"] {
        background: url(/videoPlayerIcons/volume-2.svg) no-repeat;
    }

    /* audiotrack button when different audio tracks available */
    #virtualGuide & .vjs-button[title="Audio Track"] {
        background: url(/videoPlayerIcons/music-note.svg) no-repeat;
    }

    /* remove yellow border from buttons when hovered over or clicked */
    #virtualGuide & .vjs-button:active,
    #virtualGuide & .vjs-button:hover,
    #virtualGuide & .vjs-button:focus,
    #virtualGuide & .vjs-button:visited,
    #virtualGuide & .vjs-big-play-button:active,
    #virtualGuide & .vjs-big-play-button:hover,
    #virtualGuide & .vjs-big-play-button:focus,
    #virtualGuide & .vjs-big-play-button:visited,
    #virtualGuide & .vjs-control-bar:focus {
        border-style: none !important;
        outline-style: none !important;
        background-color: transparent !important;
    }

    #virtualGuide & button.vjs-close-button.vjs-control.vjs-button {
        background: url(/videoPlayerIcons/close.svg) no-repeat;
        background-size: contain;
        margin-top: 10px !important;
        margin-right: 15px !important;
    }

    /* styling that all buttons in control bar have in common*/
    #virtualGuide & .vjs-button[title="Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Exit Picture-in-Picture"],
    #virtualGuide & .vjs-button[title="Fullscreen"],
    #virtualGuide & .vjs-button[title="Mute"],
    #virtualGuide & .vjs-mute-control[title="Unmute"],
    #virtualGuide & .vjs-button[title="Non-Fullscreen"],
    #virtualGuide & .vjs-button[title="Audio Track"],
    #virtualGuide & .vjs-button.vjs-vol-1[title="Mute"],
    #virtualGuide & .vjs-button.vjs-vol-2[title="Mute"],
    #virtualGuide & .vjs-button[title="Audio Track"],
    #virtualGuide & .vjs-button[title="Replay"],
    #virtualGuide & .vjs-button[title="Captions"],
    #virtualGuide & .vjs-theatermode-control,
    #virtualGuide & button.vjs-close-button.vjs-control.vjs-button {
        text-indent: -9999px;
        width: 26px;
        height: 26px;
        background-size: contain;
        filter: invert(100%);
        margin-top: 6.5px;
        margin-left: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        border-radius: 1px;
        padding: 0 0 0 0;
    }

    /* live button */
    #virtualGuide & .vjs-seek-to-live-control {
        border: transparent !important;
        height: 31px;
        display: block;
        margin-top: 5px;
    }

    #virtualGuide & .vjs-seek-to-live-control:focus {
        text-shadow: none;
        border-style: none !important;
        outline-style: none !important;
    }

    /* live text */
    #virtualGuide & .vjs-seek-to-live-text {
        user-select: none;
        font-size: 16px;
        overflow: hidden;
        margin-top: 10px;
    }

    #virtualGuide & .vjs-seek-to-live-text:focus {
        outline: none;
    }

    /* red dot */
    #virtualGuide & .vjs-icon-placeholder {
        font-size: 16px;
        overflow: hidden;
        vertical-align: -2px;
    }

    /* progress bar */
    #virtualGuide & .vjs-progress-control {
        bottom: 10px;
    }

    /* white slide-bar */
    #virtualGuide & .vjs-play-progress.vjs-slider-bar {
    }

    #virtualGuide & .vjs-progress-holder {
    }

    /* white dot */
    #virtualGuide & .vjs-load-progress {
    }

    /* little line that attaches to cursor as you hover the progress bar */
    #virtualGuide & .vjs-mouse-display {
    }

    /* white progress bar and little circle at current position*/
    #virtualGuide & .vjs-play-progress {
    }

    /* volume slider */
    #virtualGuide & .vjs-volume-horizontal {
        margin-top: 5px;
    }
`

export default PictureInPictureVideoPlayerReal
