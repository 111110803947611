import React from "react"
import ReactDOM from "react-dom"
import styled from "styled-components"
import branding from "../../../branding/branding"
import { IconClose } from "../../../ui/Icons"

const ModalRoot = styled.div`
    font-family: ${branding.font1};
    background: rgba(0, 0, 0, 0.6);
    position: absolute;
    color: ${branding.mainInfoColor};

    a {
        color: ${branding.mainInfoColor};
    }
`

const ModalDialog = styled.div`
    max-width: 700px;
`

const ModalContent = styled.div`
    /* border: 1px solid ${branding.mainInfoColor};
    border-radius: 0; */
    padding: 30px 30px;
    border-radius: 5px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
`

const ModalBodyText = styled.p`
    font-size: 14px;
    line-height: 18px;
    font-family: ${branding.font1};
    margin-top: 25px;

    a {
        text-decoration: underline;
    }

    @media (max-width: 1400px) {
        font-size: 12px;
    }
`

const ModalBodyTitle = styled.p`
    /* margin-bottom: 10px; */
    width: auto;
    height: 20px;
    font-size: 18px;
    line-height: 20px;
    font-style: normal;
    font-family: ${branding.font1};
    font-weight: bold;

    @media (max-width: 1400px) {
        font-size: 16px;
    }
`

const ModalBody = styled.div`
    padding: 0rem 20px;
`
const SubmitButtonContainer = styled.div`
    justify-content: flex-end;
`

const AgreeButton = styled.div`
    display: block;
    width: auto;
    min-width: 30%;
    max-width: 50%;
    padding: 5px;
    height: 35px;
    color: ${branding.globalTexts.agreeBtnTextColor ?? "#fff"} !important;
    background: ${branding.globalTexts.agreeBtnBackgroundColor ?? "#000"} !important;
    border: 1px solid ${branding.recommendModal.submitBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    line-height: 17px;
    font-family: ${branding.font1};
    cursor: pointer;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
    }
`

const DisagreeButton = styled.div`
    display: block;
    width: auto;
    min-width: 30%;
    max-width: 50%;
    padding: 5px;
    height: 35px;
    color: ${branding.recommendModal.cancelBtnPrimaryTextColor ?? "#000"};
    background-color: transparent;
    border: 1px solid ${branding.recommendModal.cancelBtnPrimaryBorderColor}!important;
    border-radius: 5px;
    font-size: ${branding.globalTexts.privacyPolicyFontSize ?? "15px"};
    line-height: 17px;
    font-family: ${branding.font1};
    margin-right: 15px;
    text-align: center;

    :hover {
        background-color: ${branding.recommendModal.submitBtnPrimaryOnHoverBgColor} !important;
        color: ${branding.recommendModal.submitBtnPrimaryOnHoverTextColor} !important;
        text-decoration: none;
    }
`
const ModalCloseButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const ModalCloseButton = styled.span`
    cursor: pointer;
`

interface ModalProps {
    title: string
    text: string
    acceptButtonText: string
    accept: () => void
    dismissButtonText: string
    dismiss: () => void
}

export default function Modal(props: ModalProps) {
    return ReactDOM.createPortal(
        <React.Fragment>
            <ModalRoot className="modal d-block" role="dialog">
                <ModalDialog className="modal-dialog modal-dialog-centered" role="document">
                    <ModalContent className="modal-content">
                        <ModalCloseButtonContainer>
                            <ModalCloseButton onClick={props.dismiss}>
                                {" "}
                                {IconClose({ fill: branding.recommendModal.closeIconColor, width: "15", height: "15" })}
                            </ModalCloseButton>
                        </ModalCloseButtonContainer>
                        <ModalBody className="modal-body">
                            <div className="row">
                                <ModalBodyTitle>{props.title}</ModalBodyTitle>
                                <ModalBodyText dangerouslySetInnerHTML={{ __html: props.text }} />
                            </div>
                            <SubmitButtonContainer className="row d-flex mt-4">
                                <DisagreeButton
                                    onClick={props.dismiss}
                                    className="d-flex align-items-center justify-content-center"
                                >
                                    {props.dismissButtonText}
                                </DisagreeButton>
                                <AgreeButton onClick={props.accept} className="d-flex align-items-center justify-content-center">
                                    {props.acceptButtonText}
                                </AgreeButton>
                            </SubmitButtonContainer>
                        </ModalBody>
                    </ModalContent>
                </ModalDialog>
            </ModalRoot>
        </React.Fragment>,
        document.body
    )
}
