import FileSaver from "file-saver"
import React from "react"
import { Row, Col } from "react-bootstrap"
import styled from "styled-components"
import { trackVisit } from "../../../backendServices/BackendServices"
import { Attachment } from "../../../backendServices/Types"
import branding from "../../../branding/branding"
import { useLoggedInState, User } from "../../../globalStates/LoggedInUser"
import { IconDownload, IconFile } from "../../../ui/Icons"
import { usePrivacyPolicyModal } from "../../../ui/PrivacyPolicyModal"
import { SimpleOrganization } from "../../ExhibitorsPageContent"
import { getContentType, MediaProps } from "./Media"
import { FormattedDateExtensionDiv, HeaderTitle } from "./StyledComponents"
import { device, MobileVersionContainer } from "../../../utils/Device"
import { useLanguageState } from "../../../globalStates/LanguageState"

const NonMediaGalleryRoot = styled.div``

const MediaRow = styled(Row)`
    display: flex;
    border-bottom: 1px solid ${branding.listDividerColor};
    padding: 25px 0;
    justify-content: space-between;

    @media ${device.mobile} {
        margin-right: -5px !important;
    }
`

const MediaImageCol = styled(Col)`
    flex: 0 0 20px;
    display: flex;
    align-items: center;
    padding: 0;
`

const MediaColInfo = styled(Col)`
    padding-left: 25px;
    display: flex;
    align-items: center;
`

const MediaTitle = styled.p`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 0;
    cursor: pointer;

    @media ${device.mobile} {
        font-size: 12px;
    }
`

const MediaColIcons = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
`

const FormattedDateExtensionDivExtended = styled(FormattedDateExtensionDiv)`
    height: 20px;
    border: 1px solid ${branding.listDividerColor};
    padding: 0 5px;
    margin-left: 15px;
    cursor: pointer;
`

const ExstensionText = styled.p`
    font-family: ${branding.font1};
    font-size: 12px;
    margin: 0;
    padding: 0;
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface NonMediaGalleryProps {
    media: Attachment[]
    marginTop?: string
}

const NonGalleryMedia = (props: NonMediaGalleryProps) => {
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    const user = useLoggedInState().user()
    const strings = useLanguageState().getStrings()

    const downloadClick = (
        props: React.PropsWithChildren<MediaProps>,
        showPrivacyPolicyModal: (organization: SimpleOrganization, callback: (accepted: boolean) => void) => void,
        att: Attachment,
        user: User | undefined
    ) => {
        if (props.organization)
            showPrivacyPolicyModal(props.organization, (accepted) => {
                startDownloadProcess(att, user!, props.organization)
            })
        else startDownloadProcess(att, user!, props.organization !== undefined ? props.organization : undefined)
    }

    const startDownloadProcess = (attachment: Attachment, loggedInUser: User, organization?: SimpleOrganization) => {
        if (attachment) {
            const fileName = attachment.title
            FileSaver.saveAs(attachment.url, fileName)
            if (organization?.id) trackVisit(loggedInUser.profileId, organization?.id, "MEDIA#DOWNLOAD", attachment.id)
        }
    }

    return (
        <NonMediaGalleryRoot style={{ marginTop: props.marginTop ?? "3rem" }}>
            <PrivacyModal />
            {props.media.length > 0 && (
                <MobileVersionContainer>
                    <Row className="mb-4" style={{ marginRight: "5px", marginTop: "20px" }}>
                        <HeaderTitle>
                            {props.media.length > 1
                                ? strings.trademarkDetailPageContent.downloadsTitle + " (" + props.media.length + ")"
                                : branding.trademarkDetailPageContent.downloadTitle + " (" + 1 + ")"}
                        </HeaderTitle>
                    </Row>
                </MobileVersionContainer>
            )}
            {props.media.map((att, key) => {
                const exstension = getContentType(att)
                return (
                    <MediaRow key={key}>
                        <MediaImageCol>
                            {IconFile({ width: "20px", height: "20px", fill: branding.sideIconBar.sideIconColorDark })}
                        </MediaImageCol>
                        <MediaColInfo onClick={() => downloadClick(props, showPrivacyPolicyModal, att, user)}>
                            <MediaTitle>{att.title ? att.title : "NO TITLE"}</MediaTitle>
                            {exstension && exstension.length < 10 && (
                                <FormattedDateExtensionDivExtended>
                                    <ExstensionText>{exstension}</ExstensionText>
                                </FormattedDateExtensionDivExtended>
                            )}
                        </MediaColInfo>
                        <MediaColIcons>
                            <DownloadIconDiv
                                onClick={() => {
                                    downloadClick(props, showPrivacyPolicyModal, att, user)
                                }}
                            >
                                {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                            </DownloadIconDiv>
                        </MediaColIcons>
                    </MediaRow>
                )
            })}
        </NonMediaGalleryRoot>
    )
}

export default NonGalleryMedia
