import React, { useEffect, useState } from "react"
import { getInterest } from "../../backendServices/BackendServices"
import { Category } from "../../backendServices/Types"
import branding from "../../branding/branding"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import CompanyDetails from "../../ui/CompanyDetails"
import { IconEmail, IconFacebook, IconFacebookOriginal, IconInstagram, IconInstagramOriginal, IconLanguages, IconLinkedIn, IconLinkedInOriginal, IconLocation, IconPhone, IconPinterest, IconPinterestOrginal, IconTwitter, IconTwitterOriginal, IconXing, IconXingOriginal, IconYouTube, IconYouTubeOriginal } from "../../ui/Icons"
import { getListOfLanguagesString } from "./LanguageSwitcherHelper"
import { SocialMediaType, CategoryType } from "./MyProfilePageContentBranding"
import { CategoryData } from "./ViewMyProfileLayout"
import { ProfileRoot, ProfileSectionsContainer, EditProfile, UserImageDiv, Title, Paragraph, ContactSectionIconContainer, ContactSectionTextContainer, BiographyContainer, ContactSectionButtonsContainer, SocialMediaContainer, ProfileSocialsWrapper, ProfileSocialItem, ProfileSectionRoot, ContactCategoryBindings, CategoriesSectionProps, SectionProps, StyledButton } from "./MobileMyProfilePageContent"
import { findCountryName } from "./CountrySwitcherHelper"
import { MyProfileSubPageProps, EditProfileSection } from "./MyProfilePageContent"
import styled, { css } from "styled-components"
import { useHistory } from "react-router-dom"
import { useFavoriteState } from "../../globalStates/Favorites"
import { useAppState } from "../../globalStates/AppState"
import { useChimeContext } from "../../conference/context/ChimeContext"
import { logoutUser } from "../../communicationArea/settings/SettingsTab"

const MobileViewMyProfileLayout: React.FunctionComponent<MyProfileSubPageProps> = (props: MyProfileSubPageProps) => {
    const loggedInUser = useLoggedInState().user()

    const [categoryData, setCategoryData] = useState<CategoryData>({ interests: [], offerings: [], lookingfor: [], selectedInterests: [], selectedOfferings: [], selectedLookingfor: [] })

    function getData() {
        getInterest().then(res => {
            if (res.content.interests) {
                const interests: Category[] = res.content.interests ?? []
                const offerings: Category[] = res.content.lookingforandoffering ?? []
                const lookingfor: Category[] = res.content.lookingforandoffering ?? []
                const selectedInterests = interests.filter(i => (loggedInUser?.interests ?? []).includes(i.id))
                const selectedOfferings = offerings.filter(i => (loggedInUser?.offering ?? []).includes(i.id))
                const selectedLookingfor = lookingfor.filter(i => (loggedInUser?.lookingfor ?? []).includes(i.id))
                setCategoryData(
                    {
                        interests: interests,
                        offerings: offerings,
                        lookingfor: lookingfor,
                        selectedInterests: selectedInterests,
                        selectedOfferings: selectedOfferings,
                        selectedLookingfor: selectedLookingfor
                    }
                )
            }
        })
    }

    useEffect(() => {
        getData()
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    return <ProfileRoot>
        <ProfileSectionsContainer>
            <ContactSection setMyProfileMode={props.setMyProfileMode} setEditProfileSection={props.setEditProfileSection} />

            <SocialMediaSection setMyProfileMode={props.setMyProfileMode} setEditProfileSection={props.setEditProfileSection} />

            {
                branding.myProfilePageContent.categoriesOrder.includes(CategoryType.INTERESTS) &&
                <InterestsSection categoryData={categoryData} setMyProfileMode={props.setMyProfileMode} setEditProfileSection={props.setEditProfileSection} />
            }

            {
                (branding.myProfilePageContent.categoriesOrder.includes(CategoryType.OFFERS) || branding.myProfilePageContent.categoriesOrder.includes(CategoryType.NEEDS)) &&
                <OffersNeedsSection categoryData={categoryData} setMyProfileMode={props.setMyProfileMode} setEditProfileSection={props.setEditProfileSection} />
            }

            <CompanySection />

            <LogoutSection />
        </ProfileSectionsContainer>
    </ProfileRoot>
}

export default MobileViewMyProfileLayout

const ContactSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const loggedInUser = useLoggedInState().user()
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()

    const currentUserInfo: string = (lang === "de" ? loggedInUser?.infotextDe : loggedInUser?.infotext) || ""
    const localizedSalutation = lang === "de" ? (loggedInUser?.salutationDe ?? loggedInUser?.salutation) : loggedInUser?.salutation
    const currentUserName: string = branding.myProfilePageContent.salutationVisible ? [localizedSalutation, loggedInUser?.firstName, loggedInUser?.lastName].filter(Boolean).join(" ") : [loggedInUser?.firstName, loggedInUser?.lastName].filter(Boolean).join(" ") 
    const localizedPosition = lang === "de" ? (loggedInUser?.positionDe ?? loggedInUser?.position) : loggedInUser?.position
    const currentUserPosition: string = [localizedPosition, loggedInUser?.company].filter(Boolean).join(" " + strings.communicationArea.personCompanyLink + " ")
    const currentUserIndustry: string = (lang === "de" ? loggedInUser?.industryDe : loggedInUser?.industry) || ""

    return <ProfileSectionRoot>
        <EditProfile setMyProfileMode={props.setMyProfileMode} section={EditProfileSection.CONTACT} setSection={props.setEditProfileSection} guestBannerHeight={0} />
        <UserImageDiv>
            <AvatarWithDefault size={80} src={loggedInUser?.logoUrl} alt={[loggedInUser?.firstName, loggedInUser?.lastName].join(" ") ?? "#"} />
        </UserImageDiv>
        <Title>{currentUserName}</Title>
        <Paragraph>{[currentUserPosition, currentUserIndustry].filter(Boolean).join(" | ")}</Paragraph>

        {loggedInUser?.countrycode &&
            <Paragraph>
                <ContactSectionIconContainer>
                    {IconLocation({ fill: branding.sideIconBar.sideIconColorDark })}
                </ContactSectionIconContainer>

                <ContactSectionTextContainer>
                    {findCountryName(loggedInUser?.countrycode, lang)}
                </ContactSectionTextContainer>
            </Paragraph>}

        {loggedInUser?.languages && loggedInUser?.languages?.length! > 0 &&
            <Paragraph>
                <ContactSectionIconContainer>
                    {IconLanguages({ fill: branding.sideIconBar.sideIconColorDark })}
                </ContactSectionIconContainer>

                <ContactSectionTextContainer>
                    {getListOfLanguagesString(loggedInUser?.languages, lang)}
                </ContactSectionTextContainer>
            </Paragraph>

        }

        {currentUserInfo !== "" &&
            <BiographyContainer>
                <Title>{strings.myProfilePageContent.biographyMobileLabel}</Title>

                <Paragraph>{currentUserInfo}</Paragraph>

            </BiographyContainer>
        }

        {false && <ContactSectionButtonsContainer>
            {loggedInUser?.phone &&
                <StyledButton>
                    <a href={"tel:" + loggedInUser?.phone} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                        <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <ContactSectionIconContainer>
                                {IconPhone({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ContactSectionIconContainer>

                            <ContactSectionTextContainer>
                                {strings.myProfilePageContent.phoneLabel}
                            </ContactSectionTextContainer>
                        </Paragraph>
                    </a>
                </StyledButton>
            }

            <StyledButton>
                <a href={"mailto:" + loggedInUser?.email} style={{ color: branding.sideIconBar.sideIconColorDark }}>
                    <Paragraph style={{ marginTop: "auto", marginBottom: "auto" }}>
                        <ContactSectionIconContainer>
                            {IconEmail({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                        </ContactSectionIconContainer>

                        <ContactSectionTextContainer>
                            {strings.myProfilePageContent.emailLabel}
                        </ContactSectionTextContainer>
                    </Paragraph>
                </a>
            </StyledButton>
        </ContactSectionButtonsContainer>}

    </ProfileSectionRoot>
}

const SocialMediaSection: React.FunctionComponent<SectionProps> = (props: SectionProps) => {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    return <ProfileSectionRoot>
        <EditProfile setMyProfileMode={props.setMyProfileMode} section={EditProfileSection.SOCIALS} setSection={props.setEditProfileSection} guestBannerHeight={0} />
        <SocialMediaContainer>
            <Title>{strings.myProfilePageContent.socialMediaSectionTitle}</Title>

            <ProfileSocialsWrapper>
                {branding.myProfilePageContent.availableSocialMedias.map((item, index) => {
                    if (item === SocialMediaType.FACEBOOK) {
                        return <>
                            {userState.user()?.facebook && <ProfileSocialItem key={index} href={userState.user()?.facebook} target="_blank">
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconFacebookOriginal({ width: '20', height: '20' }) : IconFacebook({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.TWITTER) {
                        return <>
                            {userState.user()?.twitter && <ProfileSocialItem key={index} href={userState.user()?.twitter} target="_blank">
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconTwitterOriginal({ width: '20', height: '20' }) : IconTwitter({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.LINKEDIN) {
                        return <>
                            {userState.user()?.linkedIn && <ProfileSocialItem key={index} href={userState.user()?.linkedIn} target="_blank">
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconLinkedInOriginal({ width: '20', height: '20' }) : IconLinkedIn({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.XING) {
                        return <>
                            {userState.user()?.xing && <ProfileSocialItem key={index} href={userState.user()?.xing}>
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconXingOriginal({ width: '20', height: '20' }) : IconXing({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.INSTAGRAM) {
                        return <>
                            {userState.user()?.instagram && <ProfileSocialItem key={index} href={userState.user()?.instagram}>
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconInstagramOriginal({ width: '20', height: '20' }) : IconInstagram({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.YOUTUBE) {
                        return <>
                            {userState.user()?.youTube && <ProfileSocialItem key={index} href={userState.user()?.youTube}>
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconYouTubeOriginal({ width: '20', height: '20' }) : IconYouTube({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    if (item === SocialMediaType.PINTEREST) {
                        return <>
                            {userState.user()?.pinterest && <ProfileSocialItem key={index} href={userState.user()?.pinterest}>
                                {branding.socialMediaIcons.useOriginalSocialMediaIcons ? IconPinterestOrginal({ width: '20', height: '20' }) : IconPinterest({ fill: branding.sideIconBar.sideIconColorDark, width: "20", height: "20" })}
                            </ProfileSocialItem>}
                        </>
                    }

                    return null
                })
                }


            </ProfileSocialsWrapper>
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const InterestsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    return <ProfileSectionRoot>
        <EditProfile setMyProfileMode={props.setMyProfileMode} section={EditProfileSection.INTERESTS} setSection={props.setEditProfileSection} guestBannerHeight={0} />
        <SocialMediaContainer>
            <ContactCategoryBindings type={CategoryType.INTERESTS} data={props.categoryData} />
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const OffersNeedsSection: React.FunctionComponent<CategoriesSectionProps> = (props: CategoriesSectionProps) => {
    return <ProfileSectionRoot>
        <EditProfile setMyProfileMode={props.setMyProfileMode} section={EditProfileSection.LOOKINGFORANDOFFERING} setSection={props.setEditProfileSection} guestBannerHeight={0} />
        <SocialMediaContainer>
            <>
                {branding.myProfilePageContent.categoriesOrder.map((item, index) => {
                    switch (item) {
                        case "OFFERS":
                            return <ContactCategoryBindings key={index} type={CategoryType.OFFERS} data={props.categoryData} />

                        case "NEEDS":
                            return <ContactCategoryBindings key={index} type={CategoryType.NEEDS} data={props.categoryData} />

                        default: return null
                    }

                })}
            </>
        </SocialMediaContainer>
    </ProfileSectionRoot>
}

const CompanySection: React.FunctionComponent = () => {
    const loggedInUser = useLoggedInState().user()

    return <>
        {loggedInUser && loggedInUser?.organizations && loggedInUser?.organizations![0] &&
            <ProfileSectionRoot>
                <SocialMediaContainer>
                    <CompanyDetails organization={loggedInUser?.organizations[0]} />
                </SocialMediaContainer>
            </ProfileSectionRoot>
        }
    </>
}

const LogoutButton = styled.button`
    flex: 0 0 auto;
    //margin-top: 10px;
    margin-bottom: 20px;
    padding: 7px;
    min-height: 45px;
    height: 45px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid ${branding.dangerButtonColor};
    color: ${branding.dangerButtonColor};
    background: #fff;
    transition: 0.5s;
    cursor: pointer;
    width: 100%;
    outline: none;

    &:focus { outline: none; }

    ${props => props.disabled
        ? css`
            opacity: 0.5;
            transition-property: none;
        `
        : css`
            &:hover { opacity: 0.7; }
        `
    };
`

const LogoutSection: React.FunctionComponent = () => {

    const userState = useLoggedInState()
    const history = useHistory()
    const favoriteState = useFavoriteState()
    const appState = useAppState()
    const chime = useChimeContext()

    const strings = useLanguageState().getStrings()

    return <>
        {
            userState.user() &&
            <ProfileSectionRoot style={{ height: "45px", background: "transparent" }}>
                <LogoutButton onClick={() => logoutUser(userState, history, appState, favoriteState, chime)}>
                    {strings.communicationArea.logOutTitle}
                </LogoutButton>
            </ProfileSectionRoot>
        }
    </>
}