import React from "react"
import { Product } from "../../../backendServices/Types"
import styled from "styled-components"
import branding from "../../../branding/branding"
import BookmarkWithToggle from "../../BookmarkWithToggle"
import { TileLogo } from "./TileLogo"
import { useNavigationSource } from "../../../tracking/NavigationSource"
import { buildDetailLink } from "../../../contentArea/detailPages/DetailNavLink"
import { IconLinkRightArrow } from "../../Icons"
import { useLanguageState } from "../../../globalStates/LanguageState"
import { device } from "../../../utils/Device"
import { NavLink } from "react-router-dom"
import useWindowDimensions from "../../WindowDimensionsHook"

interface ProductComponentProps {
    product: Product
}
export function ProductComponent(props: ProductComponentProps) {
    const { product } = props
    const nav = useNavigationSource()
    const strings = useLanguageState().getStrings()

    const { isMobile } = useWindowDimensions()

    return (
        <ProductComponentRoot onClick={() => nav.set("LOBBY")} to={buildDetailLink(product.id, product.name, "product")}>
            <BookmarkWithToggle
                className={"show-on-hover exhibitor-bookmark"}
                newBookmarkItem={true}
                type={"product"}
                id={product.id}
                name={product.name}
                color={branding.receptionPage.myFairBookmarkTileIconColor}
                stroke={branding.receptionPage.myFairBookmarkTileIconColor}
            ></BookmarkWithToggle>
            <TileLogo
                url={product.pictureURL ?? product.logoUrl}
                alt={isMobile ? product.name.replace(/(.{25})..+/, "$1…") : product.name.replace(/(.{80})..+/, "$1…")}
            />
            <VisitExhibitorLink className={"show-on-hover"}>{strings.receptionPage.visitProductText}</VisitExhibitorLink>
            <VisitExhibitorLink className={"show-on-hover"}>
                {IconLinkRightArrow({
                    stroke: branding.receptionPage.myFairBookmarkTileTextColor
                })}
            </VisitExhibitorLink>
        </ProductComponentRoot>
    )
}

const VisitExhibitorLink = styled.span`
    font-family: ${branding.font1};
    display: inline-block;
    text-decoration: none;
    position: absolute;
    width: 80%;
    z-index: 1;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    color: ${branding.receptionPage.myFairBookmarkTileTextColor};
    font-size: 16px;
    text-align: center;
`

const ProductComponentRoot = styled(NavLink)`
    position: relative;
    display: flex;
    flex-basis: 95px;
    justify-content: center;
    align-items: center;
    width: 95px;
    height: 95px;
    border-style: solid;
    border-width: 1px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    padding: 10px;
    margin: 0 0 1.25rem 0;
    color: #000;
    margin-right: ${branding.receptionPage.myFairPageExhibitorTilesMargin ?? "10px"};
    border-radius: 5px;

    &::before {
        background-color: white;
    }

    &:hover .show-on-hover {
        display: inline-block;

        @media ${device.mobile} {
            display: none;
        }
    }

    & .show-on-hover {
        display: none;
        color: ${branding.crsTabs.defaultActionItemColor};
    }

    & .exhibitor-bookmark {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    ${VisitExhibitorLink} + ${VisitExhibitorLink} {
        left: 80%;
        @media ${device.tablet} {
            left: 63%;
        }
    }

    @media ${device.tablet} {
        flex-basis: 500px;
        width: 500px;
        height: 280px;
        padding: 20px;
    }

    @media ${device.mobile} {
        flex-basis: 95px;
        width: 95px;
        height: 95px;
        padding: 5px;
    }

    p  {
        font-family: ${branding.font1};

        @media ${device.mobile} {
            font-size: 12px;
        }
    }
`
