import { format } from "date-fns"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { useParams } from "react-router-dom"
import styled, { css } from "styled-components"
import {
    createPrivacyUserQuestionId,
    loadEventDateDetails,
    loadExhibitorData,
    trackEventDateDetailsPageVisit,
    trackOnDemandDetailsPageVisit
} from "../../backendServices/BackendServices"
import { AccessProvider, Attachment, Category, Company, EventDate, Person, ShareTargetType } from "../../backendServices/Types"
import branding from "../../branding/branding"
import Chat from "../../chatRoomArea/Chat"
import { saveEvent } from "../../communicationArea/ICal"
import { useAppState } from "../../globalStates/AppState"
import { getNow } from "../../globalStates/EventState"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useUserRestrictedAreaAccess } from "../../globalStates/UserRestrictedAreaAccess"
import Breadcrumb, { BreadcrumbItem } from "../../navigationArea/Breadcrumb"
import TopBar from "../../navigationArea/TopBar"
import { useNavigationSource } from "../../tracking/NavigationSource"
import { calcBreadcrumbLocations } from "../../tracking/RouteTracker"
import { AvatarWithDefault } from "../../ui/AvatarWithDefault"
import { AvatarWithPresenceState } from "../../ui/AvatarWithPresenceState"
import BookmarkWithToggle from "../../ui/BookmarkWithToggle"
import CenteredLoader from "../../ui/CenteredLoader"
import CompanyDetails from "../../ui/CompanyDetails"
import EventLayout from "../../ui/EventLayout"
import GuestModal from "../../ui/GuestModal"
import {
    IconDownload,
    IconDownloadEventDateDetail,
    IconFile,
    IconLiveStream,
    IconRoundTable,
    IconShare,
    IconThreeDotsMenu
} from "../../ui/Icons"
import { usePrivacyPolicyModal } from "../../ui/PrivacyPolicyModal"
import RecommendModal, { useRecommendModal } from "../../ui/RecommendModal"
import { ContentScrollContainer } from "../../ui/ScrollContainer"
import { Staff, StaffEntry, StaffType } from "../../ui/StaffEntry"
import useWindowDimensions from "../../ui/WindowDimensionsHook"
import { isEventDateLive } from "../../utils/DateUtils"
import { device, MobileVersionContainer, DesktopVersionContainer } from "../../utils/Device"
import { isLivePhase, isSoftOpeningPhase } from "../../utils/EventPhaseChecker"
import { trackNavigation } from "../../utils/GTMTracking"
import TopBannerSharedState from "../advertisingBanner/TopBannerSharedState"
import { SimpleOrganization } from "../ExhibitorsPageContent"
import GuestUserBanner from "../guestUserBanner/GuestUserBanner"
import GuestUserBannerSharedState from "../guestUserBanner/GuestUserBannerSharedState"
import { SectionTopButton } from "../myprofile/MobileMyProfilePageContent"
import { PointsBadge } from "../program/EventDateEntry"
import { ComingUp, JoinBreakout } from "../VideoPageContent"
import ActionButton, { ActionButtonTheme } from "../videoPlayer/ActionButton"
import VideoPlayer from "../videoPlayer/VideoPlayer"
import Categories from "./components/Categories"
import { Links } from "./components/DetailPageSections"
import { filterMedia, Media } from "./components/Media"
import NonGalleryMedia from "./components/NonGalleryMedia"
import { HeaderTitle } from "./components/StyledComponents"
import { buildDetailLink, DetailNavLink } from "./DetailNavLink"
import { EventDateDetailSection } from "./DetailPageBranding"
import {
    BasicInfoDescription,
    BasicInfoDescriptionText,
    BasicInfoDescriptionTitle,
    EventLocation,
    EventLocationContainer,
    EventLocationRoot,
    EventMenuModal,
    SectionRoot,
    SpacingCol
} from "./EventDetailPageContent"

const germanLocale = require("date-fns/locale/de")

/* #region  Share and bookmark icons component */
const ShareIcon = styled.div`
    cursor: pointer;
    margin-right: 20px;
    color: ${branding.primaryColor};
`
interface ShareBookmarkIconsProps {
    eventdate: EventDate
}

const ShareBookmarkIcons: React.FunctionComponent<ShareBookmarkIconsProps> = React.memo((props) => {
    const { RecommendModal, showRecommendModal } = useRecommendModal()
    const strings = useLanguageState().getStrings()
    const targetId = props.eventdate!.id
    const link = buildDetailLink(targetId, props.eventdate.name, "eventdate")

    return (
        <>
            <RecommendModal />
            {props.eventdate && (
                <ShareIcon
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()

                        saveEvent(strings, props.eventdate!)
                    }}
                >
                    {IconDownloadEventDateDetail({ fill: branding.sideIconBar.sideIconColorDark })}
                </ShareIcon>
            )}
            <ShareIcon
                onClick={(e) => {
                    e.preventDefault()
                    e.stopPropagation()
                    showRecommendModal(ShareTargetType.EVENTDATE, targetId, link)
                }}
            >
                {IconShare({ fill: branding.sideIconBar.sideIconColorDark })}
            </ShareIcon>
            <BookmarkWithToggle
                newBookmarkItem={true}
                fontSize={"25px"}
                color={branding.sideIconBar.sideIconColorDark}
                favIconBasic={true}
                type={"eventdate"}
                id={targetId}
                name={props.eventdate!.name}
            />
        </>
    )
})
/* #endregion */

/* #region  Person and attachment data component */
const PersonAttachmentRow = styled(Row)`
    display: flex;
    /* margin-top: 10px; */
    margin-bottom: 25px;
`

const AttachmentRow = styled(Row)`
    display: flex;
    /* margin-bottom: 25px; */
    border-bottom: 1px solid #d9d9d9;
    align-items: center;
    padding: 25px 0;
`

const PersonAttachmentImageCol = styled(Col)`
    display: auto;
    align-items: center;
`

const PersonColInfo = styled(Col)`
    font-family: ${branding.font1};
    display: flex;
    align-items: left;
`

const PersonInfoDiv = styled.div<{ size: number }>`
    /* margin-left: 10px; */
    font-family: ${branding.font1};
    width: ${(props) => (props.size > 1600 ? "55%" : "40%")};
    margin-left: -20px;
`

const PersonAttachmentTitle = styled.h5`
    font-family: ${branding.font1};
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 10px !important;
    margin-top: 10px;
`

const AttachmentTitle = styled.h5`
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 10px !important;
    margin-top: 10px;
`

const PersonPosition = styled.p`
    /* margin-top: 3px; */
    font-family: ${branding.font1};
    font-size: 12px;
    white-space: pre-wrap;
    margin-bottom: 0px !important;
`

const PersonAttachmentColIcons = styled(Col)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`

const AttachmentExstensionDiv = styled.div`
    border: 1px solid ${branding.mainInfoColor};
    border-radius: 5px;
    padding: 3px 5px;
    margin-left: 20px;
    max-height: 30px;
`

const ExstensionText = styled.p`
    font-size: 16px;
    font-family: ${branding.font1};
`

const DownloadIconDiv = styled.div`
    cursor: pointer;
`

interface PersonAttachmentDataProps {
    person?: Person
    attachment?: Attachment
    type: "person" | "attachment"
    modalToggle?(): void
    startDownload?: boolean
    setSelectedAttachment?(value: Attachment): void
    setShowRecommendOrganizationModal?: (toggled: boolean) => void
    setTargetId?: (target: string) => void
    setLink?: (link: string) => void
    setType?: (type: ShareTargetType) => void
}

const PersonAttachmentData: React.FunctionComponent<PersonAttachmentDataProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const windowSize = useWindowDimensions()

    const [staff, setStaff] = useState<Staff>()

    function handleClick() {
        if (props.setSelectedAttachment) props.setSelectedAttachment(props.attachment!)

        if (!props.startDownload && props.modalToggle) props.modalToggle()
    }

    useEffect(() => {
        setStaff({
            firstName: props.person?.firstName!,
            lastName: props.person?.lastName!,
            id: props.person?.id!,
            type: StaffType.PERSON,
            logoUrl: props.person?.logoUrl!,
            position: props.person?.position!,
            company: props.person?.organization!,
            sotUserId: props.person?.userId!,
            visible: true,
            showroomStandby: false,
            title: "",
            categories: []
        })
    }, [props.person])

    if (props.type === "person" && props.person) {
        return (
            <>
                <DesktopVersionContainer>
                    <PersonAttachmentRow>
                        <PersonAttachmentImageCol sm={4} className="pr-0">
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <AvatarWithPresenceState
                                        badgeSize={20}
                                        showAvatarBadge={true}
                                        badgeRight={windowSize.width < 1850 ? -16 : -45}
                                        badgeTop={windowSize.width < 1850 ? 50 : 70}
                                        userId={props.person.userId}
                                        avatarSize={windowSize.width < 1850 ? 71 : 101}
                                        content={{
                                            pictureUrl: props.person.logoUrl || props.person.userLogoUrl,
                                            alt: [props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"
                                        }}
                                    />
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <AvatarWithDefault
                                    size={windowSize.width < 1850 ? 65 : windowSize.width < 1350 ? 45 : 95}
                                    src={props.person.userLogoUrl ?? props.person.logoUrl ?? undefined}
                                    alt={[props.person.firstName, props.person.lastName].filter(Boolean).join(" ") ?? "#"}
                                    backgroundSize="cover"
                                />
                            )}
                        </PersonAttachmentImageCol>
                        {/* <PersonColInfo xs={8}> */}
                        <PersonInfoDiv
                            size={windowSize.width}
                            style={{
                                marginLeft:
                                    windowSize.width > 2450
                                        ? "-20px"
                                        : windowSize.width > 2060
                                        ? "5px"
                                        : windowSize.width > 1850
                                        ? "22px"
                                        : windowSize.width > 1680
                                        ? "5px"
                                        : windowSize.width > 1500
                                        ? "15px"
                                        : windowSize.width > 1300
                                        ? "25px"
                                        : "35px"
                            }}
                        >
                            {props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                            {!props.person.userId && (
                                <DetailNavLink
                                    id={props.person.id}
                                    type={"person"}
                                    name={`/person/${props.person.firstName}_${props.person.lastName}`}
                                >
                                    <PersonAttachmentTitle>
                                        {[props.person.title, props.person.firstName, props.person.lastName]
                                            .filter(Boolean)
                                            .join(" ")}
                                    </PersonAttachmentTitle>
                                    <PersonPosition>
                                        {[props.person.position, props.person.organization]
                                            .filter(Boolean)
                                            .join(" " + strings.communicationArea.personCompanyLink + " ")}
                                    </PersonPosition>
                                </DetailNavLink>
                            )}
                        </PersonInfoDiv>
                    </PersonAttachmentRow>
                </DesktopVersionContainer>
                <MobileVersionContainer>
                    <MobileVersionContainer>
                        {staff && <StaffEntry staff={staff} organizationId={""} backOffice={false} speaker={true} />}
                    </MobileVersionContainer>
                </MobileVersionContainer>
            </>
        )
    } else if (props.attachment) {
        let index = props.attachment.url.lastIndexOf(".")
        let exstension = props.attachment.url.substring(index + 1).toUpperCase()
        return (
            <AttachmentRow className="m-0">
                <PersonAttachmentImageCol
                    style={{ paddingRight: "0", flex: "0 0 20px", marginRight: "25px" }}
                    xs={1}
                    className="p-0"
                >
                    {IconFile({ fill: branding.sideIconBar.sideIconColorDark, width: "20px", height: "20px" })}
                </PersonAttachmentImageCol>
                <PersonColInfo xs={9} className="p-0">
                    <AttachmentTitle>{props.attachment.title ? props.attachment.title : "NO TITLE"}</AttachmentTitle>
                    <AttachmentExstensionDiv>
                        <ExstensionText>{exstension}</ExstensionText>
                    </AttachmentExstensionDiv>
                </PersonColInfo>
                <PersonAttachmentColIcons style={{ marginLeft: "calc(8.333333% - 55px)" }} className="d-flex justify-content-end">
                    <DownloadIconDiv onClick={handleClick}>
                        {IconDownload({ fill: branding.sideIconBar.sideIconColorDark })}
                    </DownloadIconDiv>
                </PersonAttachmentColIcons>
                <Col xs={2}></Col>
            </AttachmentRow>
        )
    } else {
        return <></>
    }
}
/* #endregion */

/* #region  All persons content */
const PersonsAttachmentsContentRow = styled(Row)<{ descriptionExists?: any }>`
    font-family: ${branding.font1};
    margin-left: "30px";
    border-left: ${(props) =>
        props.descriptionExists ? (branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9") : "none"};
    padding-left: 20px;
`

const PersonsAttachmentsContentCol = styled(Col)`
    font-family: ${branding.font1};
    /* border-bottom: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"}; */
    padding: 0px 0;
`

interface PersonsAttachmentsHeaderPositionRowProps {
    type: "person" | "attachment"
}

const PersonsAttachmentsHeaderPositionRow = styled(Row)<PersonsAttachmentsHeaderPositionRowProps>`
    margin: ${(props) => (props.type === "person" ? "30px 0 25px 0" : "30px 0 25px 0")};
`

const PersonsAttachmentsHeaderTitle = styled.h3`
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: bold;
    margin: 0px;
`

interface PersonsProps {
    persons: Array<Person>
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
    descriptionExists?: any
}

const Persons: React.FunctionComponent<PersonsProps> = (props) => {
    let strings = useLanguageState().getStrings()
    let lastPosition: string | undefined = ""
    var personsWithFunctionNameSorted = props.persons.sort((a, b) => {
        if (a.personFunctionTargetName === undefined && b.personFunctionTargetName === undefined) {
            return 0
        } else if (a.personFunctionTargetName === undefined) {
            return 1
        } else if (b.personFunctionTargetName === undefined) {
            return -1
        } else {
            return a.personFunctionTargetName > b.personFunctionTargetName
                ? -1
                : a.personFunctionTargetName < b.personFunctionTargetName
                ? 1
                : 0
        }
    })

    function getPositionHeader(positionName: string | undefined) {
        let count = personsWithFunctionNameSorted.filter((person) => person.personFunctionTargetName === positionName).length
        if (positionName === undefined) {
            return strings.eventDateDetailPageContent.noSectionTitle + " (" + count + ")"
        } else {
            return positionName + " (" + count + ")"
        }
    }

    return (
        <PersonsAttachmentsContentRow descriptionExists={props.descriptionExists}>
            {personsWithFunctionNameSorted.map((person) => {
                if (person.personFunctionTargetName !== lastPosition) {
                    lastPosition = person.personFunctionTargetName
                    let positionHeader = getPositionHeader(person.personFunctionTargetName)
                    return (
                        <PersonsAttachmentsContentCol xs={12} key={person.id}>
                            <PersonsAttachmentsHeaderPositionRow type="person">
                                <PersonsAttachmentsHeaderTitle>{positionHeader}</PersonsAttachmentsHeaderTitle>
                            </PersonsAttachmentsHeaderPositionRow>
                            <PersonAttachmentData
                                person={person}
                                type="person"
                                setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                setLink={props.setLink}
                                setTargetId={props.setTargetId}
                                setType={props.setType}
                            />
                        </PersonsAttachmentsContentCol>
                    )
                } else {
                    return (
                        <PersonsAttachmentsContentCol xs={12} key={person.id}>
                            <PersonAttachmentData
                                person={person}
                                type="person"
                                setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal!}
                                setLink={props.setLink!}
                                setTargetId={props.setTargetId!}
                                setType={props.setType!}
                            />
                        </PersonsAttachmentsContentCol>
                    )
                }
            })}
        </PersonsAttachmentsContentRow>
    )
}
/* #endregion */

const FormattedDateCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-top: 20px;
    padding-left: 0px;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const LocationCol = styled(Col)`
    display: flex;
    align-items: center;
    font-size: 20px;
    padding: 25px 0 0 0px;

    @media (max-width: 1000px) {
        flex-direction: column;
        align-items: flex-start;
    }
`

const JoinBreakoutCol = styled(Col)`
    display: flex;
    justify-content: flex-end;
`

export const EventDateLocationRoot = styled.div`
    position: relative;
    width: 100%;
    height: 20px;
    margin-top: 12px;
    margin-left: 10px;
    font-family: ${branding.font1};
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
`

export const EventDateLocationContainer = styled.div`
    margin: 0;
    position: absolute;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    white-space: nowrap;
`

const ShareBookmarkDiv = styled.div`
    float: right;
    display: flex;
    /* margin: 50px 5px 0 50px; */
    margin-top: 15px;
    font-size: 20px;
`

const EventDateFormattedDate = styled.p`
    font-family: ${branding.font1};
    font-size: 12px;
    width: auto;
    height: 15px;
`

const EventDateNameTeaserCol = styled(Col)`
    font-family: ${branding.font1};
    padding-left: 0px;
    @media (max-width: 769px) {
        margin-top: 100px;
    }
`

const EventDateName = styled.h2`
    /* margin-bottom:  10px; */
    font-family: ${branding.font1};
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;
`

const EventDateTeaserArea = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    /* border-right: 1px solid #d9d9d9; */
    width: 95%;
`
const EventDateTeaser = styled.p`
    font-size: 14px;
    margin-top: 25px;
    font-family: ${branding.font1};
    /* padding-right: 20px; */
    width: 95%;
`

const EventDateTeaserTitle = styled.div`
    font-size: 18px;
    font-family: ${branding.font1};
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
`

const EventDateDetailBodyRoot = styled.div<{ adjustHeight: number }>`
    font-family: ${branding.font1};
    display: flex;
    flex-direction: row;
    width: 100%;
    height: ${(props) => `calc(100vh - ${props.adjustHeight}px)`};

    .ScrollbarsCustom-Content {
        padding: 0 !important;
    }

    .container-root {
        padding-bottom: 40px;
    }

    @media ${device.mobile} {
        background: ${branding.myProfilePageContent.mobilePageBackgroundColor};
    }
`
const RoundTableAccessDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 50px;
    border: ${branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9"};
    border-radius: 5px;
    height: 100px;
    font-size: 16px;
    position: relative;

    @media (max-width: 1600px) {
        font-size: 14px;
    }
`
const ActionButtonCol = styled(Col)`
    padding: 0;
    height: 70%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    & p {
        font-size: 12px;
        margin-bottom: 0px;
        font-family: ${branding.font1};
    }
`
const ComingUpContentRoot = styled.div`
    margin-top: "-50px";
    padding-bottom: 25px;
    padding-top: 20px;

    @media (max-width: 1500px) {
        margin-top: "-30px";
    }
`

/* #region LiveEvent */

const LiveTileHeader = styled.div`
    position: absolute;
    display: flex;
    flex-flow: row;
    padding: 15px;
    width: 100%;
    color: white;
    z-index: 2;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 100%);
    background-blend-mode: multiply;
`

const LiveTileRoot = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }
`

const VideoOnDemandRoot = styled.div`
    position: relative;
    display: flex;
    flex-flow: column;

    & h2 {
        font-family: ${branding.font1};
        font-style: normal;
        font-size: 18px;
        line-height: 23px;
        text-transform: uppercase;
        display: flex;
        margin-left: 25px;
        flex: 1;
        text-align: center;
    }
`

const LiveEventRoot = styled.div`
    display: flex;
    flex-flow: row;
    justify-content: center;
`

const LiveStreamingImagePreview = styled.div<{ $backgroundImage?: string }>`
    position: relative;
    display: flex;
    height: 500px;
    ${(props) =>
        props.$backgroundImage
            ? `background: url("${props.$backgroundImage}");`
            : "background: url('/branding/no-event-default-image.png');"}
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    align-items: center;
    justify-content: center;
`

export enum EventDateTiming {
    BEFORE,
    LIVE,
    AFTER
}
interface LiveEventDateProps {
    streamingUrl: string
    isLive: boolean
    organizationId: string | undefined
    pageTitle: string
    eventDate: EventDate
    isChannelPage?: boolean
    showGuestModal(value: boolean): void
    useMobileDesign: boolean
}
const LiveEventSection: React.FunctionComponent<LiveEventDateProps> = (props) => {
    const userAccessState = useUserRestrictedAreaAccess()
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()

    const userLink = useLoggedInState()
    const questionId = createPrivacyUserQuestionId(props.organizationId ?? "")
    const alreadyAccepted = userLink.getPrivacyUserAnswer(questionId)?.accepted
    const [disablePlaying, setDisablePlaying] = useState(
        !alreadyAccepted && branding.configuration.showPrivacyModelForEventDates === true
    ) //play the video if user already accepted the policy
    const [modalShown, setModalShown] = useState(false)
    const [vodClicked, setVodClicked] = useState(false)

    const title = props.eventDate?.channel?.name ?? ""
    let content: JSX.Element | null = null

    let contentColWidth = props.useMobileDesign ? 12 : 8

    // 1. if user is on channel page, we are displaying VideoPlayer - real or dummy
    // 2. if event is not live and videoOnDemandUrl is presented, then we can display VideoPlayer(real or dummy) with videoOnDemand
    // 3. if neither condition are not fulfilled, we can display eventdate title and image preview

    if (props.isChannelPage) {
        // 1. condition LIVE STREAM
        contentColWidth = 12
        content = (
            <VideoPlayer
                streamUrl={props.streamingUrl}
                eventDate={props.eventDate}
                live={true}
                setShowGuestModal={props.showGuestModal}
            />
        )
    } else {
        const liveTileHeader = (
            <LiveTileHeader>
                <div>{IconLiveStream({ fill: "currentColor" })}</div>
                <h2>{title}</h2>
                <ActionButton
                    restrictedArea={props.eventDate}
                    setShowGuestModal={props.showGuestModal}
                    theme={ActionButtonTheme.PRIVATE_EVENT_LIGHT_PLAYER}
                />
            </LiveTileHeader>
        )

        if (!props.isLive && props.eventDate.videoOnDemandUrl) {
            // 2. condition VOD
            content = (
                <VideoOnDemandRoot
                    onClick={(e) => {
                        if (props.organizationId && !modalShown && branding.configuration.showPrivacyModelForEventDates)
                            showPrivacyPolicyModal({ id: props.organizationId, ...props.eventDate.organizationConsent }, () => {
                                setDisablePlaying(false)
                                setModalShown(true)
                            })
                        else {
                            setDisablePlaying(false)
                        }
                        if (props.pageTitle) {
                            trackNavigation(props.pageTitle + " - Live Video")
                        }

                        // this should be triggered only once
                        if (props.organizationId && !disablePlaying && !vodClicked) {
                            setVodClicked(true)
                            trackOnDemandDetailsPageVisit(userLink.user()?.profileId!, props.organizationId, props.eventDate.id!)
                        }
                    }}
                >
                    {userAccessState.isUnlocked(props.eventDate) && liveTileHeader}
                    <VideoPlayer
                        streamUrl={props.eventDate.videoOnDemandUrl}
                        eventDate={props.eventDate}
                        live={false}
                        disablePlaying={disablePlaying}
                        setShowGuestModal={props.showGuestModal}
                    />
                </VideoOnDemandRoot>
            )
        } else {
            // 3. condition EVENT DATE PAGE
            content = (
                <LiveTileRoot className="live-preview-img">
                    {liveTileHeader}
                    <LiveStreamingImagePreview
                        $backgroundImage={props.eventDate?.liveStreamingPreviewUrl ?? props.eventDate?.logoUrl}
                    />
                </LiveTileRoot>
            )
        }
    }

    return (
        <>
            <LiveEventRoot>
                <Col xs={contentColWidth} className="p-0">
                    {content}
                </Col>
            </LiveEventRoot>
            <PrivacyModal />
        </>
    )
}
/* #endregion LiveEvent */

/* #region BasicInfo */
interface BasicInfoSectionProps {
    eventDate: EventDate
    pointsBadgeData: any
    isChannelPage?: boolean
    userAccessState: any
    joinBreakoutDisabled?: boolean
    breakoutRoomParticipants?: number
    showRecommendModal: (shareType: ShareTargetType, entityId: string, link: string) => void
    useMobileDesign: boolean
}

const BasicInfoSection: React.FunctionComponent<BasicInfoSectionProps> = (props: BasicInfoSectionProps) => {
    const lang = useLanguageState().getLanguage()
    const strings = useLanguageState().getStrings()
    const [showEventMenuModal, setShowEventMenuModal] = useState<boolean>(false)

    //returns date-time in format: Wed., 12 Sept., 2020, 17:00 - 18:30
    const getFormattedDate = (date: string) => {
        return format(moment(date).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, {
            locale: lang === "de" ? germanLocale : null
        })
    }

    return (
        <SectionRoot marginTop="10px">
            <MobileVersionContainer>
                <div style={{ marginLeft: "12px", paddingTop: "10px" }}>
                    <div style={{ color: branding.primaryColor, height: "20px", display: "inline-block" }}>
                        {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                    </div>
                    <EventLocationRoot style={{ display: "inline-block" }}>
                        <EventLocationContainer>{props.eventDate.location}</EventLocationContainer>
                    </EventLocationRoot>
                    <SectionTopButton style={{ right: "1.5rem", marginTop: "-30px" }} onClick={() => setShowEventMenuModal(true)}>
                        {IconThreeDotsMenu({ fill: branding.sideIconBar.sideIconColorDark })}
                    </SectionTopButton>
                </div>
            </MobileVersionContainer>

            <Row className="m-0">
                <SpacingCol xs={2} className="p-0" />

                <LocationCol xs={props.useMobileDesign ? 12 : 4}>
                    <DesktopVersionContainer>
                        <div style={{ display: "inline-flex" }}>
                            <div style={{ color: branding.primaryColor, height: "20px" }}>
                                {IconLiveStream({ fill: branding.sideIconBar.sideIconColorDark })}
                            </div>
                            <EventDateLocationRoot>
                                <EventDateLocationContainer style={{ top: "5px" }}>
                                    {props.eventDate.location}
                                </EventDateLocationContainer>
                            </EventDateLocationRoot>
                        </div>
                    </DesktopVersionContainer>

                    <MobileVersionContainer>
                        <div style={{ marginLeft: "15px" }}>
                            <EventDateFormattedDate>{getFormattedDate(props.eventDate.date)}</EventDateFormattedDate>
                            <EventLocation>{props.eventDate.name}</EventLocation>
                        </div>
                    </MobileVersionContainer>
                </LocationCol>

                <JoinBreakoutCol xs={4}>
                    <DesktopVersionContainer>
                        {
                            // join breakout room - visible only on channel page
                            props.isChannelPage && (
                                <div className="w-100">
                                    {/* join breakout room section */}
                                    {branding.configuration.breakoutEnabled &&
                                        props.eventDate.breakoutAccess &&
                                        props.userAccessState.isUnlocked(props.eventDate) && (
                                            <JoinBreakout
                                                eventDate={props.eventDate}
                                                disabled={props.joinBreakoutDisabled}
                                                participants={props.breakoutRoomParticipants!}
                                            />
                                        )}
                                </div>
                            )
                        }
                    </DesktopVersionContainer>
                </JoinBreakoutCol>
            </Row>

            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    <FormattedDateCol xs={8}>
                        <EventDateFormattedDate>{getFormattedDate(props.eventDate.date)} </EventDateFormattedDate>
                    </FormattedDateCol>
                    <Col xs={2} />
                </Row>

                <Row className="m-0">
                    <Col xs={2} />
                    <FormattedDateCol xs={6}>
                        <EventDateNameTeaserCol xs={12}>
                            <EventDateName>{props.eventDate.name}</EventDateName>

                            {props.pointsBadgeData && (
                                <PointsBadge
                                    className={"mt-3"}
                                    textColor={props.pointsBadgeData.colorText}
                                    fillColor={props.pointsBadgeData.colorFill}
                                    borderColor={props.pointsBadgeData.colorBorder}
                                >
                                    {strings.programSchedule.pointsBadgeText}
                                </PointsBadge>
                            )}
                        </EventDateNameTeaserCol>
                    </FormattedDateCol>
                    <Col xs={2} className="p-0">
                        <ShareBookmarkDiv>
                            <ShareBookmarkIcons eventdate={props.eventDate} />
                        </ShareBookmarkDiv>
                    </Col>
                </Row>
            </DesktopVersionContainer>

            <MobileVersionContainer>
                {props.eventDate.text && props.eventDate.text?.length > 0 && (
                    <Row className="m-0">
                        <BasicInfoDescription>
                            <BasicInfoDescriptionTitle>
                                {strings.eventDateDetailPageContent.descriptionTextForEventDate}
                            </BasicInfoDescriptionTitle>
                            <BasicInfoDescriptionText>
                                {props.eventDate.text?.split(/\n+/).map((item, i) => (
                                    <p key={i}>{item}</p>
                                ))}
                            </BasicInfoDescriptionText>
                        </BasicInfoDescription>
                    </Row>
                )}
            </MobileVersionContainer>

            {showEventMenuModal && (
                <EventMenuModal
                    event={props.eventDate}
                    handleClose={() => setShowEventMenuModal(false)}
                    showRecommendModal={props.showRecommendModal}
                    type={ShareTargetType.EVENTDATE}
                    marginTop="320px"
                />
            )}
        </SectionRoot>
    )
}
/* #endregion BasicInfo */

/* #region Typeform */

const TypeformSectionDivider = styled.div`
    border-bottom: ${branding.mainBorder ?? "1px solid #d9d9d9"};
    margin-top: 20px;
`

const TypeformSectionDescriptionText = styled.div`
    /* margin-bottom:  10px; */
    font-family: ${branding.font1};
    font-size: 16px;
    font-weight: 400;
    line-height: 18.75px;
    margin: 0;
    white-space: pre-line;

    @media ${device.mobile} {
        font-size: 12px;
        margin-top: 25px;
        margin-left: 15px;
        max-width: 50%;
    }
`

const TypeformSectionButton = styled.button`
    flex: 0 0 auto;
    margin-top: 8px;
    padding: 7px;
    min-height: 35px;
    height: auto;
    font-size: 12px;
    border-radius: 5px;
    border: 1px solid ${branding.crsTabs.defaultActionItemActiveStateColor};
    color: #fff;
    background: ${branding.crsTabs.defaultActionItemActiveStateColor};
    transition: 0.5s;
    cursor: pointer;
    width: auto;
    outline: none;

    @media ${device.tablet} {
        min-width: 189px;
    }

    @media ${device.mobile} {
        max-width: 120px;
        margin-top: 15px;
        margin-right: 15px;
    }

    &:focus {
        outline: none;
    }

    ${(props) =>
        props.disabled
            ? css`
                  border: 1px solid ${branding.crsTabs.defaultBorderColor};
                  background: ${branding.crsTabs.defaultBorderColor};
                  transition-property: none;
                  cursor: default;
              `
            : css`
                  &:hover {
                      opacity: 0.7;
                  }
              `};
`

const TypeformDescription: React.FunctionComponent<{ typeformButtonDisabled: boolean; time: any }> = (props) => {
    function replaceTextPlaceholder(text: string) {
        return text.replace("${time}", props.time)
    }

    return (
        <TypeformSectionDescriptionText>
            {props.typeformButtonDisabled && branding.eventDateDetailPageContent.typeformSectionCountdownVisible
                ? replaceTextPlaceholder(branding.eventDateDetailPageContent.typeformSectionWaitingDescriptionText)
                : branding.eventDateDetailPageContent.typeformSectionDescriptionText}
        </TypeformSectionDescriptionText>
    )
}

const TypeformButton: React.FunctionComponent<{ text: string; onClick: () => void; typeformButtonDisabled: boolean }> = (
    props
) => {
    return (
        <TypeformSectionButton disabled={props.typeformButtonDisabled} onClick={() => props.onClick()}>
            {props.text}
        </TypeformSectionButton>
    )
}

interface TypeformSectionProps {
    eventDate: EventDate
    typeformButtonDisabled: boolean
    time: any
}

const TypeformSection: React.FunctionComponent<TypeformSectionProps> = (props: TypeformSectionProps) => {
    const userState = useLoggedInState()
    const strings = useLanguageState().getStrings()

    function onTypeformSectionButtonClick() {
        if (props.eventDate.typeformUrl && userState.user() && userState.user()?.remoteId) {
            let adjustedUrl = props.eventDate.typeformUrl?.replace(
                "${remoteUserId}",
                userState.user()?.remoteId ?? userState.user()?.email!
            )
            window.open(`${adjustedUrl}`, "_blank")
        }
    }
    return (
        <>
            {branding.eventDateDetailPageContent.typeformSectionEnabled &&
                props.eventDate.typeformUrl &&
                props.eventDate.videoOnDemandUrl && (
                    <SectionRoot>
                        <DesktopVersionContainer>
                            <Row className="m-0">
                                <Col xs={2} />
                                <Col xs={8} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <TypeformSectionDivider />
                                </Col>
                                <Col xs={2} />
                            </Row>
                        </DesktopVersionContainer>

                        <DesktopVersionContainer>
                            <Row className="m-0" style={{ paddingTop: "5px", paddingBottom: "5px" }}>
                                <SpacingCol xs={2} />
                                <FormattedDateCol xs={6}>
                                    <EventDateNameTeaserCol xs={10}>
                                        <TypeformDescription
                                            typeformButtonDisabled={props.typeformButtonDisabled}
                                            time={props.time}
                                        />
                                    </EventDateNameTeaserCol>
                                </FormattedDateCol>
                                <Col xs={2} className="p-0">
                                    <ShareBookmarkDiv>
                                        <TypeformButton
                                            typeformButtonDisabled={props.typeformButtonDisabled}
                                            text={strings.eventDateDetailPageContent.typeformSectionButtonText}
                                            onClick={() => onTypeformSectionButtonClick()}
                                        />
                                    </ShareBookmarkDiv>
                                </Col>
                            </Row>
                        </DesktopVersionContainer>

                        <MobileVersionContainer>
                            <Row
                                className="m-0"
                                style={{ paddingTop: "5px", paddingBottom: "5px", justifyContent: "space-between" }}
                            >
                                <TypeformDescription typeformButtonDisabled={props.typeformButtonDisabled} time={props.time} />
                                <TypeformButton
                                    typeformButtonDisabled={props.typeformButtonDisabled}
                                    text={strings.eventDateDetailPageContent.typeformSectionButtonText}
                                    onClick={() => onTypeformSectionButtonClick()}
                                />
                            </Row>
                        </MobileVersionContainer>

                        <MobileVersionContainer></MobileVersionContainer>

                        <DesktopVersionContainer>
                            {!props.eventDate.organizationId && (
                                <Row className="m-0">
                                    <Col xs={2} />
                                    <Col xs={8} style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <TypeformSectionDivider />
                                    </Col>
                                    <Col xs={2} />
                                </Row>
                            )}
                        </DesktopVersionContainer>
                    </SectionRoot>
                )}
        </>
    )
}

/* #endregion Typeform */

/* #region CompanyDetails */

const CompanyDetailsRoot = styled.div`
    @media ${device.tablet} {
        margin-top: -12px;
        margin-bottom: 15px;
    }

    @media ${device.mobile} {
        margin-left: auto;
        margin-right: auto;
        margin-top: 20px;
    }
`

interface CompanyDetailsProps {
    organizationId?: string
    company?: Company
    useMobileDesign: boolean
}

const CompanyDetailsSection: React.FunctionComponent<CompanyDetailsProps> = (props: CompanyDetailsProps) => {
    return (
        <>
            {props.organizationId && props.company && (
                <SectionRoot>
                    <Row className="m-0">
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8} className="p-0">
                            {props.organizationId && props.company && (
                                <CompanyDetailsRoot>
                                    <CompanyDetails organization={props.company} />
                                </CompanyDetailsRoot>
                            )}
                        </Col>
                        <SpacingCol xs={2} />
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion CompanyDetails */

/* #region DescriptionPersons */

const DescriptionPersonsRow = styled(Row)`
    @media ${device.mobile} {
        margin-left: 10px !important;
        margin-right: 5px !important;
    }
`

interface DescriptionPersonsProps {
    eventDate: EventDate
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
}

const DescriptionPersonsSection: React.FunctionComponent<DescriptionPersonsProps> = (props: DescriptionPersonsProps) => {
    const descriptionExists = props.eventDate.text && props.eventDate.text?.length! > 0

    return (
        <>
            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    {descriptionExists && (
                        <Col xs={5} className="p-0">
                            <EventDateTeaserArea>
                                <EventDateTeaserTitle>
                                    {branding.eventDateDetailPageContent.descriptionTextForEventDate}
                                </EventDateTeaserTitle>
                                <EventDateTeaser>
                                    {props.eventDate.text?.split(/\n+/).map((item, i) => (
                                        <p key={i}>{item}</p>
                                    ))}
                                </EventDateTeaser>
                            </EventDateTeaserArea>
                            {/* } */}
                        </Col>
                    )}

                    <Col xs={descriptionExists ? 3 : 4} className="p-0">
                        <Persons
                            persons={props.eventDate.persons}
                            setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                            setLink={props.setLink}
                            setTargetId={props.setTargetId}
                            setType={props.setType}
                        />
                    </Col>
                </Row>
            </DesktopVersionContainer>
            <MobileVersionContainer>
                {props.eventDate.persons && props.eventDate.persons.length > 0 && (
                    <SectionRoot>
                        <DescriptionPersonsRow className="m-0">
                            <Col xs={11} className="p-0">
                                <Persons
                                    persons={props.eventDate.persons}
                                    setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                    setLink={props.setLink}
                                    setTargetId={props.setTargetId}
                                    setType={props.setType}
                                />
                            </Col>
                        </DescriptionPersonsRow>
                    </SectionRoot>
                )}
            </MobileVersionContainer>
        </>
    )
}

/* #endregion DescriptionPersons */

/* #region EventLayout */

interface EventLayoutProps {
    eventDate: EventDate
    useMobileDesign: boolean
}

const EventLayoutSection: React.FunctionComponent<EventLayoutProps> = (props: EventLayoutProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage && (
                <SectionRoot>
                    <Row style={{ marginTop: "15px" }}>
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8}>
                            <EventLayout event={props.eventDate.event} />
                        </Col>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion EventLayout */

/* #region PrivateEventAccessView */
interface PrivateEventAccessViewProps {
    eventDate: EventDate
    isChannelPage?: boolean
    setShowGuestModal: (value: boolean) => void
}

const PrivateEventAccessView: React.FC<PrivateEventAccessViewProps> = (props) => {
    const strings = useLanguageState().getStrings()
    const lang = useLanguageState().getLanguage()
    const userEventAccess = useUserRestrictedAreaAccess()
    const isRoundTable = props.eventDate.type === "roundtable"
    const [eventDateTiming, setEventDateTiming] = useState<EventDateTiming>()
    let startTime = moment(props.eventDate.startdatetime)
    let endTime = moment(props.eventDate.enddatetime)

    if (isRoundTable) {
        startTime = startTime.subtract(branding.configuration.roundTableOpenBeforeMinutes ?? 5, "minutes")
        endTime = endTime.add(branding.configuration.roundTableOpenAfterMinutes ?? 5, "minutes")
    }

    // event date timing phases
    useEffect(() => {
        const now = getNow()
        if (now.isBefore(startTime)) {
            setEventDateTiming(EventDateTiming.BEFORE)
            const timeToLive = startTime.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.LIVE)
                }, timeToLive)
                return () => clearTimeout(handle)
            }
        } else if (now.isBefore(endTime)) {
            setEventDateTiming(EventDateTiming.LIVE)
            const timeToEnd = endTime.diff(now)
            if (timeToEnd < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    setEventDateTiming(EventDateTiming.AFTER)
                }, timeToEnd)
                return () => clearTimeout(handle)
            }
        } else {
            setEventDateTiming(EventDateTiming.AFTER)
        }
    }, [props.eventDate.id]) // eslint-disable-line

    const getDescriptionText = () => {
        let privateEventText = ""
        if (isRoundTable) {
            if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                privateEventText = strings.eventDateDetailPageContent.privateEventDateTicketNoAccessText
            } else if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                privateEventText = strings.eventDateDetailPageContent.eventDateRoundTableNoAccessText
            }
        } else {
            if (props.eventDate.accessProvider === AccessProvider.TICKET) {
                privateEventText = strings.eventDateDetailPageContent.privateEventDateTicketNoAccessText
            } else if (props.eventDate.accessProvider === AccessProvider.BACKOFFICE) {
                privateEventText = strings.eventDateDetailPageContent.privateEventDateNoAccessText
            }
        }

        if (eventDateTiming === EventDateTiming.AFTER) {
            privateEventText = strings.eventDateDetailPageContent.eventDateRoundTablePostEventText
        } else if (userEventAccess.isUnlocked(props.eventDate)) {
            const startDateTime = moment(props.eventDate.date + " " + props.eventDate.start)
            const tempText = isRoundTable
                ? strings.eventDateDetailPageContent.eventDateRoundTableGrantedAccessText
                : strings.eventDateDetailPageContent.privateEventDateGrantedAccessText
            privateEventText = tempText.split("{$startTime}").join(
                format(moment(startDateTime).toDate(), strings.eventTiming.eventDaysFormatPatternEvtDetailPage, {
                    locale: lang === "de" ? germanLocale : null
                }) +
                    (lang === "de" ? " um " : " at ") +
                    props.eventDate.start
            )
        }
        return privateEventText
    }

    if (
        props.eventDate.type !== "roundtable" &&
        (eventDateTiming === EventDateTiming.AFTER || userEventAccess.isUnlocked(props.eventDate))
    ) {
        return null
    }

    return (
        <>
            <RoundTableAccessDiv>
                <Col xs={1}>{IconRoundTable({ width: "30", height: "30", fill: branding.mainInfoColor })}</Col>
                <Col xs={9}>{getDescriptionText()}</Col>
                <ActionButtonCol xs={2}>
                    <ActionButton
                        restrictedArea={props.eventDate}
                        setShowGuestModal={props.setShowGuestModal}
                        theme={ActionButtonTheme.PRIVATE_EVENT_DARK_BOX}
                    />
                </ActionButtonCol>
            </RoundTableAccessDiv>
        </>
    )
}

const PrivateEventAccessViewSection: React.FunctionComponent<PrivateEventAccessViewProps> = (
    props: PrivateEventAccessViewProps
) => {
    return (
        <>
            <DesktopVersionContainer>
                <Row className="m-0">
                    <Col xs={2} />
                    <Col xs={8} className="p-0">
                        {!props.isChannelPage && (
                            <PrivateEventAccessView eventDate={props.eventDate} setShowGuestModal={props.setShowGuestModal} />
                        )}
                    </Col>
                </Row>
            </DesktopVersionContainer>
        </>
    )
}

/* #endregion PrivateEventAccessView */

/* #region Links */

const LinksSectionRow = styled(Row)`
    @media ${device.mobile} {
        margin-left: 15px !important;
    }
`

interface LinksProps {
    organizationId?: string
    company?: Company
    eventDate: EventDate
    useMobileDesign: boolean
}

const LinksSection: React.FunctionComponent<LinksProps> = (props: LinksProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <>
            {branding.eventDateDetailPageContent.linksVisible && props.eventDate?.links && (
                <SectionRoot>
                    <LinksSectionRow className="m-0">
                        <SpacingCol xs={2} />
                        <Col xs={props.useMobileDesign ? 12 : 8} className="p-0" style={{ marginTop: "15px" }}>
                            <Links
                                organizationId={props.eventDate.organizationId}
                                organization={props.company}
                                links={props.eventDate.links}
                                tooltipMessage={strings.organizationDetailPageContent.copyToClipboardText}
                                colXs={12}
                                colPadding={"0px"}
                            ></Links>
                        </Col>
                    </LinksSectionRow>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion Links */

/* #region Media */

const MediaSectionCol = styled(Col)`
    margin-top: 15px;
    margin-left: -15px;

    @media ${device.mobile} {
        margin-left: -5px;
    }
`

interface MediaProps {
    organizationId?: string
    company?: Company
    eventDate: EventDate
    useMobileDesign: boolean
}

const MediaSection: React.FunctionComponent<MediaProps> = (props: MediaProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.mediaVisibleOnEventDateDetailPage && props.eventDate?.attachments && (
                <SectionRoot>
                    <Row className="m-0">
                        <SpacingCol xs={2} />
                        <MediaSectionCol xs={props.useMobileDesign ? 11 : 8} className="p-0">
                            {props.eventDate.attachments?.length > 0 && (
                                <Media
                                    media={props.eventDate.attachments}
                                    organizationId={props.organizationId}
                                    organization={props.company}
                                />
                            )}
                        </MediaSectionCol>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}

/* #endregion Media */

/* #region NonGalleryMedia */

interface NonGalleryMediaProps {
    eventDateMedia?: Attachment[]
}

const NonGalleryMediaSection: React.FunctionComponent<NonGalleryMediaProps> = (props: NonGalleryMediaProps) => {
    const strings = useLanguageState().getStrings()

    return (
        <>
            {branding.eventDateDetailPageContent.downloadsVisibleOnEventDateDetailPage &&
                !branding.eventDateDetailPageContent.mediaVisibleOnEventDateDetailPage &&
                props.eventDateMedia &&
                props.eventDateMedia.length && (
                    <SectionRoot>
                        <Row className="m-0">
                            <Col xs={2} />
                            <Col xs={8} className="p-0" style={{ marginTop: "15px" }}>
                                <DesktopVersionContainer>
                                    <HeaderTitle>
                                        {props.eventDateMedia.length > 1
                                            ? strings.trademarkDetailPageContent.downloadsTitle +
                                              " (" +
                                              props.eventDateMedia.length +
                                              ")"
                                            : branding.trademarkDetailPageContent.downloadTitle + " (" + 1 + ")"}
                                    </HeaderTitle>
                                </DesktopVersionContainer>
                                <div style={{ marginRight: "12px", marginLeft: "12px" }}>
                                    <NonGalleryMedia media={props.eventDateMedia} marginTop="10px" />
                                </div>
                            </Col>
                        </Row>
                    </SectionRoot>
                )}
        </>
    )
}

/* #endregion NonGalleryMedia */

/* #region Categories */

interface CategoriesProps {
    eventDate: EventDate
    useMobileDesign: boolean
}

const CategoriesSection: React.FunctionComponent<CategoriesProps> = (props: CategoriesProps) => {
    return (
        <>
            {branding.eventDateDetailPageContent.showCategories &&
                props.eventDate.categories &&
                props.eventDate.categories.length > 0 && (
                    <SectionRoot>
                        <Row className="m-0">
                            <SpacingCol xs={2} />
                            <Col
                                xs={props.useMobileDesign ? 12 : 8}
                                style={{
                                    borderTop: branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage
                                        ? "0px solid #transparent"
                                        : "1px solid #d9d9d9",
                                    marginTop: "15px"
                                }}
                            >
                                <Categories categories={props.eventDate.categories} categoryType={"eventdate"} />
                            </Col>
                        </Row>
                    </SectionRoot>
                )}
        </>
    )
}

/* #endregion Categories */

/* #region ComingUp */

interface ComingUpProps {
    isChannelPage?: boolean
    comingUpSectionVisible?: boolean
    nextEventDate?: EventDate
    calculatedChange?: number
}

const ComingUpSection: React.FunctionComponent<ComingUpProps> = (props: ComingUpProps) => {
    return (
        <>
            {props.isChannelPage && (
                <SectionRoot>
                    <Row style={{ margin: "15px 0px 0px 0px" }}>
                        <Col xs={2} className="pr-0" />
                        <Col
                            xs={8}
                            className="p-0"
                            style={{
                                borderTop: branding.eventDateDetailPageContent.eventLayoutVisibleOnEventDateDetailPage
                                    ? "0px solid #transparent"
                                    : "1px solid #d9d9d9"
                            }}
                        >
                            {/* comingUp section  - visible only on channel page */}
                            <div className="w-100">
                                <ComingUpContentRoot>
                                    {props.comingUpSectionVisible && (
                                        <ComingUp
                                            nextEventDate={props.nextEventDate}
                                            calculatedChange={props.calculatedChange!}
                                        />
                                    )}
                                </ComingUpContentRoot>
                            </div>
                        </Col>
                    </Row>
                </SectionRoot>
            )}
        </>
    )
}
/* #endregion ComingUp */

interface EventDateDetailBodyProps {
    eventDate: EventDate
    adjustHeight: number
    pageTitle?: string
    // only for channel page
    isChannelPage?: boolean
    streamingUrl?: string
    joinBreakoutDisabled?: boolean
    breakoutRoomParticipants?: number
    comingUpSectionVisible?: boolean
    nextEventDate?: EventDate
    event?: Event
    calculatedChange?: number
    //
    setShowRecommendOrganizationModal: (toggled: boolean) => void
    setTargetId: (target: string) => void
    setLink: (link: string) => void
    setType: (type: ShareTargetType) => void
}

export const EventDateDetailBody: React.FunctionComponent<EventDateDetailBodyProps> = (props) => {
    // global states
    const userAccessState = useUserRestrictedAreaAccess()
    const [eventDateMedia, setEventDateMedia] = useState<Attachment[]>()
    // component states
    // const [startDownload, setStartDownload] = useState(false)
    // const [selectedAttachment, setSelectedAttachment] = useState<Attachment>()
    const [company, setCompany] = useState<Company>()
    const [showGuestModal, setShowGuestModal] = useState<boolean>(false)

    const { RecommendModal, showRecommendModal } = useRecommendModal()

    const [millisecondsLeft, setMillisecondsLeft] = useState<number>(
        branding.eventDateDetailPageContent.minutesBeforeEnablingTypeformSection * 60 * 1000
    )

    const { useMobileDesign } = useWindowDimensions()

    let sectionsOrder: EventDateDetailSection[] = useMobileDesign
        ? branding.eventDateDetailPageContent.mobileSectionsOrder
        : branding.eventDateDetailPageContent.desktopSectionsOrder

    const [time, setTime] = useState(
        moment(branding.eventDateDetailPageContent.minutesBeforeEnablingTypeformSection * 60 * 1000).format("mm:ss")
    )

    const [typeformButtonDisabled, setTypeformButtonDisabled] = useState<boolean>(false)

    // other
    const { channelId }: any = useParams()
    // const containerRef = useRef<HTMLDivElement>(null)

    /* #region  useEffects */

    useEffect(() => {
        if (
            branding.eventDateDetailPageContent.typeformSectionEnabled &&
            props.eventDate.typeformUrl &&
            props.eventDate.videoOnDemandUrl
        ) {
            const interval = setInterval(() => {
                if (typeformButtonDisabled) {
                    setTime(
                        moment(millisecondsLeft - 1000)
                            .utc()
                            .format("mm:ss")
                    )
                    setMillisecondsLeft(millisecondsLeft - 1000)
                }

                if (millisecondsLeft <= 1500) {
                    setTypeformButtonDisabled(false)
                }
            }, 1000)

            return () => clearInterval(interval)
        }
        // eslint-disable-next-line
    })

    useEffect(() => {
        if (!userAccessState.isLoaded(props.eventDate)) userAccessState.fetchUserAccessForSingleRestrictedArea(props.eventDate)
        setTypeformButtonDisabled(true)
        // eslint-disable-next-line
    }, [props.eventDate]) //eslint-disable-line

    useEffect(() => {
        if (
            branding.eventDateDetailPageContent.downloadsVisibleOnEventDateDetailPage &&
            props.eventDate &&
            props.eventDate.attachments &&
            props.eventDate.attachments.length > 0
        ) {
            const nonSliderMedia = filterMedia(props.eventDate.attachments, false)
            if (nonSliderMedia && nonSliderMedia.length) setEventDateMedia(nonSliderMedia)
        }
        // eslint-disable-next-line
    }, [props.eventDate])

    useEffect(() => {
        if (props.eventDate.organizationId) {
            loadExhibitorData({
                organizationid: props.eventDate.organizationId,
                hideNewsdata: false
            })
                .then((resp) => {
                    setCompany(resp.content)
                })
                .catch((e: { message: React.SetStateAction<string> }) => {
                    // Error logged in Backend Services
                })
        }
    }, [props.eventDate.organizationId])

    // redirecting user to the channel page when session starts (without reloading the page)
    useEffect(() => {
        if (
            !props.eventDate ||
            !props.eventDate.channel ||
            props.eventDate.type === "roundtable" ||
            props.isChannelPage ||
            !userAccessState.isUnlocked(props.eventDate)
        )
            return

        const now = getNow()
        const eventdateStart = moment(props.eventDate.startdatetime)
        const eventdateEnd = moment(props.eventDate.enddatetime)

        if (!isSoftOpeningPhase && !isLivePhase) return

        if (now.isAfter(eventdateEnd)) return

        if (now.isAfter(eventdateStart) && now.isBefore(eventdateEnd))
            window.open(`/channel/${props.eventDate.channel?.id || props.eventDate.id}`, "_self")

        if (now.isBefore(eventdateStart)) {
            const timeToLive = eventdateStart.diff(now)
            if (timeToLive < 1000 * 60 * 60 * 24) {
                const handle = setTimeout(() => {
                    window.open(`/channel/${props.eventDate.channel?.id || props.eventDate.id}`, "_self")
                }, timeToLive)
                return () => clearTimeout(handle)
            }
        }
    }, [userAccessState.isUnlocked(props.eventDate)]) // eslint-disable-line

    /* #endregion */

    const pointsBadgeData = (props.eventDate.categories as unknown as Category[])?.filter(
        (x) => x.id === branding.programSchedule.pointsBadgeCategoryAlias
    )[0]
    const organizationId = props.eventDate && props.eventDate.organizationId

    return (
        <EventDateDetailBodyRoot adjustHeight={props.adjustHeight}>
            <RecommendModal />
            <ContentScrollContainer containerWidth={"75%"} adjustForHeaderWith={"0"}>
                <LiveEventSection
                    isChannelPage={props.isChannelPage}
                    eventDate={props.eventDate}
                    streamingUrl={
                        props.isChannelPage && props.streamingUrl
                            ? props.streamingUrl
                            : props.eventDate.channel?.id || props.eventDate.id
                    }
                    isLive={props.eventDate ? props.eventDate.type !== "roundtable" && isEventDateLive(props.eventDate) : false}
                    organizationId={organizationId}
                    pageTitle={props.pageTitle || ""}
                    showGuestModal={setShowGuestModal}
                    useMobileDesign={useMobileDesign}
                />
                <div className="container-root" style={{ position: "relative", color: branding.mainInfoColor }}>
                    {sectionsOrder.map((item: EventDateDetailSection, index: number) => {
                        switch (item) {
                            case EventDateDetailSection.BASIC_INFO: {
                                return (
                                    <BasicInfoSection
                                        key={index}
                                        eventDate={props.eventDate}
                                        pointsBadgeData={pointsBadgeData}
                                        isChannelPage={props.isChannelPage}
                                        userAccessState={userAccessState}
                                        joinBreakoutDisabled={props.joinBreakoutDisabled}
                                        breakoutRoomParticipants={props.breakoutRoomParticipants}
                                        showRecommendModal={showRecommendModal}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.TYPEFORM: {
                                return (
                                    <TypeformSection
                                        key={index}
                                        eventDate={props.eventDate}
                                        typeformButtonDisabled={typeformButtonDisabled}
                                        time={time}
                                    />
                                )
                            }

                            case EventDateDetailSection.COMPANY_DETAILS: {
                                return (
                                    <CompanyDetailsSection
                                        key={index}
                                        organizationId={props.eventDate?.organizationId}
                                        company={company}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.DESCRIPTION_PERSONS: {
                                return (
                                    <DescriptionPersonsSection
                                        key={index}
                                        eventDate={props.eventDate}
                                        setShowRecommendOrganizationModal={props.setShowRecommendOrganizationModal}
                                        setLink={props.setLink}
                                        setTargetId={props.setTargetId}
                                        setType={props.setType}
                                    />
                                )
                            }

                            case EventDateDetailSection.EVENT_LAYOUT: {
                                return (
                                    <EventLayoutSection
                                        key={index}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.PRIVATE_EVENT_ACCESS_VIEW: {
                                return (
                                    <PrivateEventAccessViewSection
                                        key={index}
                                        isChannelPage={props.isChannelPage}
                                        eventDate={props.eventDate}
                                        setShowGuestModal={setShowGuestModal}
                                    />
                                )
                            }

                            case EventDateDetailSection.LINKS: {
                                return (
                                    <LinksSection
                                        key={index}
                                        organizationId={props.eventDate.organizationId}
                                        company={company}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.MEDIA: {
                                return (
                                    <MediaSection
                                        key={index}
                                        organizationId={props.eventDate.organizationId}
                                        company={company}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.NON_GALLERY_MEDIA: {
                                return <NonGalleryMediaSection key={index} eventDateMedia={eventDateMedia} />
                            }

                            case EventDateDetailSection.CATEGORIES: {
                                return (
                                    <CategoriesSection
                                        key={index}
                                        eventDate={props.eventDate}
                                        useMobileDesign={useMobileDesign}
                                    />
                                )
                            }

                            case EventDateDetailSection.COMING_UP: {
                                return (
                                    <ComingUpSection
                                        key={index}
                                        isChannelPage={props.isChannelPage}
                                        comingUpSectionVisible={props.comingUpSectionVisible}
                                        nextEventDate={props.nextEventDate}
                                        calculatedChange={props.calculatedChange}
                                    />
                                )
                            }

                            default:
                                return <div />
                        }
                    })}

                    <MobileVersionContainer>
                        <div style={{ marginBottom: "120px" }} />
                    </MobileVersionContainer>
                </div>
            </ContentScrollContainer>
            <ChatAreaContainer>
                <Chat eventDate={props.eventDate} channelAlias={channelId} hideQnA={!props.isChannelPage} />
            </ChatAreaContainer>
            {showGuestModal && <GuestModal close={() => setShowGuestModal(false)} />}
        </EventDateDetailBodyRoot>
    )
}
/* #endregion */

const ChatAreaContainer = styled.div`
    width: 25%;

    @media ${device.mobile} {
        display: none;
    }
`

const EventDateDetailPageContent: React.FunctionComponent = (props) => {
    let { eventDateId }: any = useParams()
    const [eventDate, setEventDate] = useState<EventDate>()
    const [error, setError] = useState("")
    const [isLoaded, setIsLoaded] = useState(false)
    const langState = useLanguageState()
    const strings = langState.getStrings()
    const lang = langState.getLanguage()
    const navi = useNavigationSource()
    const profileId = useLoggedInState().user()?.profileId
    const appState = useAppState()

    //Recommend modal
    const [targetId, setTargetId] = useState<string>("")
    const [organization, setOrganization] = useState<SimpleOrganization>()
    const [link, setLink] = useState<string>("")
    const [type, setType] = useState<ShareTargetType>()
    const [showRecommendOrganizationModal, setShowRecommendOrganizationModal] = useState(false)
    const { showPrivacyPolicyModal, PrivacyModal } = usePrivacyPolicyModal()
    //add banner state
    const { guestUserBannerRef, setGuestUserBannerRef } = GuestUserBannerSharedState()
    const { topBarRef, setTopBarRef } = TopBannerSharedState()
    const adjustHeight =
        (guestUserBannerRef && guestUserBannerRef.current ? guestUserBannerRef.current.clientHeight : 0) +
        (topBarRef && topBarRef.current ? topBarRef.current.clientHeight : 0) +
        65

    useEffect(() => {
        appState.setCurrentMobileBreadcrumb(strings.programPageContent.scheduleTabTitle)
        // eslint-disable-next-line
    }, [lang])

    useEffect(() => {
        let params = {
            id: eventDateId,
            rootCategories: branding.eventDateDetailPageContent.rootCategories as any,
            showCategoryHierarchy: branding.eventDateDetailPageContent.showCategoryHierarchy || (false as any)
        }
        if (!branding.eventDateDetailPageContent.rootCategories?.length) delete params.rootCategories

        loadEventDateDetails(params)
            .then((resp) => {
                setIsLoaded(true)
                setEventDate(resp.content)
                let organization = {
                    id: resp.content.organizationId ?? "",
                    name: resp.content.organizationName ?? "",
                    consentText: resp.content.organizationConsent.consentText,
                    consentTitle: resp.content.organizationConsent.title
                }
                setOrganization(organization)

                if (resp.content.organizationId && profileId) {
                    trackEventDateDetailsPageVisit(profileId!, resp.content.organizationId, navi.get(), resp.content.id)
                }
                navi.set("UNKNOWN")
            })
            .catch((e: { message: React.SetStateAction<string> }) => {
                setIsLoaded(true)
                setError(e.message)
            })
        // eslint-disable-next-line
    }, [eventDateId, error, profileId, lang])

    useEffect(() => {
        if (organization !== undefined && branding.configuration.showPrivacyModelForEventDates)
            showPrivacyPolicyModal(organization, () => {})

        // setShowPrivacyPolicyModal(organizationId !== undefined && branding.configuration.showPrivacyModelForEventDates === true)
    }, [organization]) //eslint-disable-line

    const breadcrumb: BreadcrumbItem[] = []
    if (eventDate) {
        const locations = calcBreadcrumbLocations(strings)
        breadcrumb.push(
            { to: locations[0] + "#schedule", name: strings.programPageContent.scheduleTabTitle },
            { to: locations[1], name: eventDate.name }
        )
    }

    let content: JSX.Element | null = null

    if (isLoaded && eventDate) {
        content = (
            <EventDateDetailBody
                eventDate={eventDate}
                pageTitle={eventDate.name}
                adjustHeight={adjustHeight}
                setShowRecommendOrganizationModal={setShowRecommendOrganizationModal}
                setLink={setLink}
                setTargetId={setTargetId}
                setType={setType}
            />
        )
    } else {
        content = <CenteredLoader />
    }

    return (
        <>
            <GuestUserBanner setRef={setGuestUserBannerRef} />
            <TopBar setRef={setTopBarRef} />
            <div style={{ borderBottom: branding.mainBorder ? branding.mainBorder : "1px solid #d9d9d9" }}>
                <Breadcrumb breadcrumb={breadcrumb} />
            </div>
            {content}
            {showRecommendOrganizationModal && (
                <RecommendModal
                    targetId={targetId}
                    type={type as ShareTargetType}
                    link={`https://${window.location.hostname}` + link}
                    sotUser={[]}
                    close={() => setShowRecommendOrganizationModal(false)}
                ></RecommendModal>
            )}
            <PrivacyModal />
        </>
    )
}

export default EventDateDetailPageContent
