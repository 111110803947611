import { createState, State, useState } from "@hookstate/core"
import { MeetingSessionStatus, VideoTileState, DataMessage } from "amazon-chime-sdk-js"
import ChimeSdkWrapper from "../ChimeSdkWrapper"
import ViewMode from "../enums/ViewMode"
import RosterType from "../types/RosterType"
import RosterAttendeeType, { rosterAttendeeTypeEquals } from "../types/RosterAttendeeType"
import {
    BackendServiceError,
    MeetingKind,
    startLive,
    ChannelResponse,
    stopLive,
    getChannelInfo,
    lockChannel,
    unlockChannel,
    StopReason,
    checkForTimerUpdate,
    CheckForTimerUpdateResponse
} from "../../backendServices/BackendServices"
import { useHistory } from "react-router-dom"
import { useLanguageState } from "../../globalStates/LanguageState"
import { useLoggedInState } from "../../globalStates/LoggedInUser"
import { useEffect } from "react"
import { defaultLogger as logger } from "../../globalStates/AppState"
import DeviceType from "../types/DeviceType"
import { useContactState } from "../../communicationArea/ContactState"
import branding from "../../branding/branding"
import { useAlertState } from "../../globalStates/AlertState"
import { CrsAlertType } from "../../ui/CrsAlert"
import { trackMeetingHeartbeatEvent } from "../../utils/GTMTracking"

const chimeSdk = new ChimeSdkWrapper()

enum DataMessageType {
    KICK = 1,
    BAN = 2,
    MUTE = 3,
    RAISEHAND = 4,
    CHANNEL_STATUS = 5,
    TIMELIMITCHANGED = 6,
    STOP_SCREENSHARE = 7
}

export enum ChannelStatus {
    PREPARING = 1,
    ON_AIR = 2,
    OFF_AIR = 3
}

export enum LocalVideoStatus {
    Disabled = 1,
    Loading = 2,
    Enabled = 3
}

export enum MeetingStatusCode {
    Loading = 1,
    Succeeded = 2,
    Disconnected = 3,
    Failed = 4,
    Full = 5,
    TimeUp = 6,
    Kicked = 7,
    Banned = 8,
    Ended = 9,
    GreenroomLive = 10
}

export interface MeetingStatus {
    meetingStatus: MeetingStatusCode
    errorMessage?: string
    jsonErrorMessage?: string
}

export interface ShallowVideoTileState {
    tileId: number | null
    isContent: boolean
    localTile: boolean
    boundAttendeeId: string | null
    active: boolean
}

interface StateValues {
    kind: MeetingKind
    name: string
    viewMode: ViewMode
    meetingStatus: MeetingStatus
    muted: boolean
    mutedByMod: boolean
    handRaised: boolean
    hasWebcam: boolean
    meetingSecondsLeft: number | null
    screensharing: boolean
    volume: number
    shareScreenTile: ShallowVideoTileState | null
    localTile: ShallowVideoTileState | null
    localVideoStatus: LocalVideoStatus
    roster: { [attendeeId: string]: RosterAttendeeType }
    remoteTiles: ShallowVideoTileState[]
    meetingChangeAccepted: boolean
    channelStatus?: ChannelStatus
    isLocked: boolean
}

const getStartValues = (): StateValues => {
    return {
        name: "",
        kind: "virtualCafe",
        muted: false,
        mutedByMod: false,
        handRaised: false,
        hasWebcam: false,
        meetingSecondsLeft: null,
        volume: 100,
        localVideoStatus: LocalVideoStatus.Disabled,
        shareScreenTile: null,
        screensharing: false,
        localTile: null,
        remoteTiles: [],
        viewMode: ViewMode.Room,
        meetingStatus: { meetingStatus: MeetingStatusCode.Loading },
        roster: {},
        meetingChangeAccepted: false,
        channelStatus: undefined,
        isLocked: false
    }
}
const state = createState<StateValues>(getStartValues())
let timeUpIntervallId: number | null
let heartbeatIntervall: number | undefined

export interface ChimeContext {
    getExternalMeetingId: () => string | null
    getName: () => string
    getKind: () => MeetingKind
    getViewMode: () => ViewMode
    getMeetingStatus: () => MeetingStatus
    getTimeLeft: () => number | null
    getMaxDuration: () => number | null
    isScreenShareEnabled: () => boolean
    isLocalScreenSharingStarted: () => boolean
    isMod: () => boolean
    getShareScreenTile: () => ShallowVideoTileState | null
    getLocalTile: () => ShallowVideoTileState | null
    getLocalVideoStatus: () => LocalVideoStatus
    getRemoteTiles: () => ShallowVideoTileState[]
    isMuted: () => boolean
    isMutedByMod: () => boolean
    setMutedByMod: (mutedByMod: boolean) => void
    isHandRaised: () => boolean
    getVolume: () => number
    setVolume: (volume: number) => void
    bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => void
    unbindVideoElement: (tileId: number) => void
    bindAudioElement: (audioElement: React.MutableRefObject<null>) => void
    unbindAudioElement: () => void
    toggleLocalVideoTile: () => Promise<void>
    realtimeMuteLocalAudio: () => void
    realtimeUnmuteLocalAudio: () => void
    chooseVideoInputDevice: (deviceId: string) => Promise<void>
    chooseAudioInputDevice: (deviceId: string) => Promise<void>
    chooseAudioOutputDevice: (deviceId: string) => Promise<void>
    stopContentShare: () => void
    startContentShareFromScreenCapture: (sourceId?: string | undefined, frameRate?: number | undefined) => Promise<void>
    leaveRoom: (meetingStatus?: MeetingStatus | undefined) => Promise<void>
    createRoom: (
        externalMeetingId: string,
        currentAudioInputDevice: DeviceType | null,
        currentAudioOutputDevice: DeviceType | null,
        currentVideoInputDevice: DeviceType | null
    ) => Promise<void>
    getLocalAttendeeId: () => string | null
    getRoster: () => {
        [attendeeId: string]: RosterAttendeeType
    }
    getAttendee: (attendeeId: string) => RosterAttendeeType
    getNumAttendees: () => number
    getMaxAttendees: () => number
    hasMaxAttendees: () => boolean
    createOrJoinMeeting: (name: string, kind?: MeetingKind) => void
    gotoCurrentMeeting: () => void
    modKick: (attendeeId: string, reason: string) => void
    modBan: (attendeeId: string, reason: string) => void
    modMute: (attendeeId: string) => void
    modStopContentShare: (attendeeId: string) => void
    raiseHand: (attendeeId: string, raiseHand: boolean) => void
    hasWebcam: () => boolean
    setIsMeetingChangeAccepted: (accepted: boolean) => void
    getIsMeetingChangeAccepted: () => boolean
    startLive: () => void
    stopLive: (reason?: StopReason) => Promise<boolean>
    lockChannel: (authorizedUsers: string[]) => Promise<boolean>
    unlockChannel: () => Promise<boolean>
    getChannelStatus: () => ChannelStatus | undefined
    isLocked: () => boolean
}

const useWrapState = (chime: State<StateValues>): ChimeContext => {
    const strings = useLanguageState().getStrings()
    const history = useHistory()
    const remoteTilesState = useState(chime.remoteTiles)
    const rosterState = useState(chime.roster)
    const meetingSecondsLeft = useState(chime.meetingSecondsLeft)
    const loggedInUser = useLoggedInState()
    const contactState = useContactState()
    const loggedIn = loggedInUser.isLoggedIn
    const alertState = useAlertState()

    useEffect(
        () => {
            if (!loggedIn && chime.name.get()) {
                leaveRoom()
            }
        },
        // eslint-disable-next-line
        [loggedIn]
    )

    const getShallowVideoTileState = (videoTileState: VideoTileState | ShallowVideoTileState): ShallowVideoTileState => {
        return {
            active: videoTileState.active,
            tileId: videoTileState.tileId,
            isContent: videoTileState.isContent,
            localTile: videoTileState.localTile,
            boundAttendeeId: videoTileState.boundAttendeeId
        }
    }

    const observer = {
        audioVideoDidStop: (_: MeetingSessionStatus): void => {
            if (chime.value.meetingStatus.meetingStatus === MeetingStatusCode.Succeeded)
                leaveRoom({ meetingStatus: MeetingStatusCode.Disconnected })
        },
        videoTileDidUpdate: (tileState: VideoTileState): void => {
            if (!tileState.boundAttendeeId || !tileState.tileId) {
                return
            }
            const newTileState = getShallowVideoTileState(tileState)

            if (newTileState.isContent) {
                chime.merge({ viewMode: ViewMode.ScreenShare, shareScreenTile: newTileState })
            } else if (newTileState.localTile) {
                chime.merge({ localTile: newTileState })
            } else if (tileState.boundAttendeeId && tileState.tileId && !newTileState.isContent && !newTileState.localTile) {
                const oldTiles = remoteTilesState.get()
                let newTiles: ShallowVideoTileState[] = []
                let contains = false
                for (let i = 0; i < oldTiles.length; i++) {
                    if (oldTiles[i].tileId === newTileState.tileId) {
                        contains = true
                        newTiles = newTiles.concat(newTileState)
                    } else {
                        newTiles = newTiles.concat(getShallowVideoTileState(oldTiles[i]))
                    }
                }
                if (!contains) {
                    newTiles = newTiles.concat(newTileState)
                }
                remoteTilesState.set(newTiles)
            }
        },
        videoTileWasRemoved: (tileId: number): void => {
            if (chime.value.shareScreenTile?.tileId === tileId) {
                chime.merge({ viewMode: ViewMode.Room })
            }
            const oldTiles = remoteTilesState.get()
            const newTiles = []
            let removed: boolean = false
            for (let i = 0; i < oldTiles.length; i++) {
                if (oldTiles[i].tileId !== tileId) {
                    newTiles.push(getShallowVideoTileState(oldTiles[i]))
                } else {
                    removed = true
                }
            }
            // Change only needed if we removed a tile
            if (removed) remoteTilesState.set(newTiles)
        }
    }

    const contentShareObserver = {
        contentShareDidStop: () => {
            chime.merge({ screensharing: false, viewMode: ViewMode.Room })
        }
    }

    const rosterUpdate = (newRoster: RosterType) => {
        /*// For testing of all the tiles
        newRoster["1"] = { name: "Max Dubiel", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["2"] = { name: "Hendrik Weißbrod", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["3"] = { name: "Alex Bork", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["4"] = { name: "Alex Merkle", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["5"] = { name: "Julian Tan", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["6"] = { name: "Gustavo Niewöhner", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["7"] = { name: "Michael Gust", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["8"] = { name: "Carsten Kirschner", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["9"] = { name: "Amila Handzic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["10"] = { name: "Jozo Skoko", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["11"] = { name: "Niloofar Rashvanloo", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["12"] = { name: "Johan Tomberg", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["13"] = { name: "Kristian Skobic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["14"] = { name: "Waldemar Tomber", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["15"] = { name: "Meris Gutosic", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["16"] = { name: "Haris Heric", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["17"] = { name: "Haris Heric1", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["18"] = { name: "Haris Heric2", muted: false, signalStrength: 0, volume: 0, handRaised: false }
        newRoster["19"] = { name: "Haris Heric3", muted: false, signalStrength: 0, volume: 0, handRaised: false } */

        let hasChanges = false
        const oldRoster = rosterState.value
        const newRosterKeys = Object.keys(newRoster)
        const oldRosterKeys = Object.keys(oldRoster)
        // If the old and new roster list are of different length -> update the view
        const difference = newRosterKeys
            .filter((x) => !oldRosterKeys.includes(x))
            .concat(oldRosterKeys.filter((x) => !newRosterKeys.includes(x)))
        if (difference.length > 0) {
            hasChanges = true
        } else {
            // check if there are changes between the new and old update that we are interested in -> update
            for (let key of newRosterKeys) {
                const newRosterEntry = newRoster[key]
                const oldRosterEntry = oldRoster[key]
                if (!rosterAttendeeTypeEquals(newRosterEntry, oldRosterEntry)) {
                    hasChanges = true
                    break
                }
            }
        }

        if (hasChanges) {
            rosterState.set(newRoster)
        }
    }

    const setMeetingStatus = (meetingStatus: MeetingStatus) => {
        chime.meetingStatus.merge(meetingStatus)
    }

    const mutedUpdate = (localMuted: boolean) => {
        chime.merge({ muted: localMuted })
    }

    const setVideoStatus = (videoStatus: LocalVideoStatus) => {
        chime.merge({ localVideoStatus: videoStatus })
    }

    const leaveRoom = async (meetingStatus?: MeetingStatus) => {
        chimeSdk.unsubscribeFromRosterUpdate(rosterUpdate)
        chimeSdk.audioVideo?.removeObserver(observer)
        chimeSdk.audioVideo?.removeContentShareObserver(contentShareObserver)
        chimeSdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(chimeSdk.attendeeId!)
        chimeSdk.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(chimeSdk.meetingId!)
        if (chimeSdk.meetingKind === "conferenceroom" && chimeSdk.userRole === "moderator") {
            const data = (await checkForTimerUpdate(chimeSdk.externalMeetingId!!)) as CheckForTimerUpdateResponse
            if (data.timerUpdated) {
                chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                    type: DataMessageType.TIMELIMITCHANGED,
                    meetingTimeLeft: data.newRemainingDuration,
                    meetingMaxDuration: data.maxMeetingLengthInSeconds
                })
            }
        }
        await chimeSdk.leaveRoom(meetingStatus)
        window.onbeforeunload = null
        stopHeartbeat()
        if (timeUpIntervallId) {
            clearTimeout(timeUpIntervallId)
            timeUpIntervallId = null
        }
        const values = getStartValues()
        values.meetingStatus = meetingStatus ? meetingStatus : { meetingStatus: MeetingStatusCode.Ended }
        chime.set(values)
    }

    const toggleLocalVideoTile = async () => {
        if (!chime.value.hasWebcam) return
        const deviceId = localStorage.getItem("virtualGuide-videoInput")
        if (chime.value.localVideoStatus === LocalVideoStatus.Disabled) {
            setVideoStatus(LocalVideoStatus.Loading)
            try {
                await chimeSdk.chooseVideoInputDevice(deviceId)
                chimeSdk.audioVideo?.startLocalVideoTile()
                setVideoStatus(LocalVideoStatus.Enabled)
            } catch (error) {
                // eslint-disable-next-line
                logger.error({
                    message: "Chime Context choose video input device failed",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
                alertState.show({
                    message: strings.globalStatePopupTexts.errorNoCameraPermission,
                    type: CrsAlertType.DANGER,
                    duration: 3000
                })
                setVideoStatus(LocalVideoStatus.Disabled)
            }
        } else if (chime.value.localVideoStatus === LocalVideoStatus.Enabled) {
            setVideoStatus(LocalVideoStatus.Loading)
            chimeSdk.audioVideo?.stopLocalVideoTile()
            setVideoStatus(LocalVideoStatus.Disabled)
        }
    }

    const receiveAttendeeDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType) return
        if (dataMessage.senderAttendeeId)
            switch (messageDataType) {
                case DataMessageType.MUTE:
                    chimeSdk.audioVideo?.realtimeMuteLocalAudio()
                    chime.merge({ muted: true, mutedByMod: true })
                    break
                case DataMessageType.KICK:
                    leaveRoom({ meetingStatus: MeetingStatusCode.Kicked, errorMessage: messageData.data })
                    break
                case DataMessageType.BAN:
                    leaveRoom({ meetingStatus: MeetingStatusCode.Banned, errorMessage: messageData.data })
                    break
                case DataMessageType.STOP_SCREENSHARE:
                    stopContentShare()
                    break
            }
    }

    const receiveMeetingDataMessage = (dataMessage: DataMessage) => {
        const messageData = dataMessage.json()
        const messageDataType: DataMessageType = messageData.type
        if (!messageDataType) return
        switch (messageDataType) {
            case DataMessageType.RAISEHAND:
                if (messageData.attendeeId) {
                    handleHandRaisedState(messageData.attendeeId, messageData.data)
                }
                break
            case DataMessageType.CHANNEL_STATUS:
                handleChannelStatusState(messageData.data)
                break
            case DataMessageType.TIMELIMITCHANGED:
                chimeSdk.meetingMaxDuration = messageData.meetingMaxDuration
                chimeSdk.meetingTimeLeft = messageData.meetingTimeLeft
                startMeetingTimer(messageData.meetingTimeLeft)
                break
        }
    }

    const handleHandRaisedState = (attendeeId: string, raiseHand: boolean) => {
        if (attendeeId === chimeSdk.localAttendeeId) chime.merge({ handRaised: raiseHand })
        else
            rosterState.set((prev) => {
                if (prev[attendeeId]) {
                    // If the roster is not loaded yet
                    prev[attendeeId].handRaised = raiseHand
                }
                return prev
            })
    }

    const stopContentShare = () => {
        chimeSdk.audioVideo?.stopContentShare()
        chime.merge({ screensharing: false, viewMode: ViewMode.Room })
    }

    const handleChannelStatusState = (channelStatus: ChannelStatus) => {
        chime.set((prev) => {
            prev.channelStatus = channelStatus
            return prev
        })
    }

    const handleLockedState = (isLocked: boolean) => {
        chime.set((prev) => {
            prev.isLocked = isLocked
            return prev
        })
    }

    const fetchChannelStatusForGreenRoom = (kind: MeetingKind, externalMeetingId: string) => {
        if (kind === "greenroom") {
            getChannelInfo(externalMeetingId).then((resp) => {
                if ((resp as BackendServiceError).httpStatus) {
                    const error = resp as BackendServiceError
                    logger.error({
                        message: "Green room " + externalMeetingId + " channel status could not be retrieved.",
                        errorMessage: error.httpStatusText
                    })
                } else {
                    const channelResponse = resp as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    handleLockedState(channelResponse.isLocked)
                }
            })
        }
    }

    const startMeetingTimer = (meetingTimeLeft: number | null) => {
        if (timeUpIntervallId) {
            clearInterval(timeUpIntervallId)
            timeUpIntervallId = null
        }
        if (meetingTimeLeft) {
            meetingSecondsLeft.set(meetingTimeLeft / 1000)
            timeUpIntervallId = window.setInterval(() => {
                const newSecondsLeft = meetingSecondsLeft.get()!! - 1
                if (newSecondsLeft <= 0) {
                    leaveRoom({ meetingStatus: MeetingStatusCode.TimeUp })
                } else {
                    meetingSecondsLeft.set(newSecondsLeft)
                }
            }, 1000)
        } else {
            meetingSecondsLeft.set(null)
        }
    }

    return {
        getExternalMeetingId: () => {
            return chimeSdk.externalMeetingId ? chimeSdk.externalMeetingId : null
        },
        getName: () => {
            return chime.name.get()
        },
        getKind: () => {
            return chime.kind.get()
        },
        getViewMode: () => {
            return chime.value.viewMode
        },
        getMeetingStatus: () => {
            return chime.value.meetingStatus
        },
        getTimeLeft: () => {
            return meetingSecondsLeft.value
        },
        getMaxDuration: () => {
            if (chime.kind.get() === "showroom") {
                return branding.showroomMeetingDuration
            }
            return chimeSdk.meetingMaxDuration
        },
        isScreenShareEnabled: () => {
            return chime.value.viewMode === ViewMode.ScreenShare
        },
        isLocalScreenSharingStarted: () => {
            return chime.value.screensharing
        },
        isMod: () => {
            return chimeSdk.userRole === "moderator"
        },
        getShareScreenTile: () => {
            return chime.value.shareScreenTile
        },
        getLocalTile: () => {
            return chime.value.localTile
        },
        getLocalVideoStatus: () => {
            return chime.value.localVideoStatus
        },
        getRemoteTiles: () => {
            return chime.value.remoteTiles
        },
        isMuted: () => {
            return chime.value.muted
        },
        isMutedByMod: () => {
            return chime.value.mutedByMod
        },
        setMutedByMod: (mutedByMod: boolean) => {
            chime.merge({ mutedByMod: mutedByMod })
        },
        isHandRaised: () => {
            return chime.value.handRaised
        },
        hasWebcam: () => {
            return chime.value.hasWebcam
        },
        getVolume: () => {
            return chime.value.volume
        },
        setVolume: (volume: number) => {
            chime.merge({ volume: volume })
        },
        bindVideoElement: (tileId: number, videoElement: HTMLVideoElement) => {
            chimeSdk.audioVideo?.bindVideoElement(tileId, videoElement)
        },
        unbindVideoElement: (tileId: number) => {
            chimeSdk.audioVideo?.unbindVideoElement(tileId)
        },
        bindAudioElement: (audioElement: React.MutableRefObject<null>) => {
            if (!audioElement || !audioElement.current) {
                logger.warn("ChimeContext AudioElement doesn't exist")
                return
            }
            chimeSdk.audioVideo?.bindAudioElement(audioElement.current!)
        },
        unbindAudioElement: () => {
            chimeSdk.audioVideo?.unbindAudioElement()
        },
        toggleLocalVideoTile: toggleLocalVideoTile,
        realtimeMuteLocalAudio: () => {
            chimeSdk.audioVideo?.realtimeMuteLocalAudio()
            chime.merge({ muted: true })
        },
        realtimeUnmuteLocalAudio: () => {
            chimeSdk.audioVideo?.realtimeUnmuteLocalAudio()
            chime.merge({ muted: false, mutedByMod: false })
        },
        chooseVideoInputDevice: (deviceId: string) => {
            return chimeSdk.chooseVideoInputDevice(deviceId)
        },
        chooseAudioInputDevice: (deviceId: string) => {
            return chimeSdk.chooseAudioInputDevice(deviceId)
        },
        chooseAudioOutputDevice: (deviceId: string) => {
            return chimeSdk.chooseAudioOutputDevice(deviceId)
        },
        stopContentShare: () => stopContentShare(),
        startContentShareFromScreenCapture: async (sourceId?: string | undefined, frameRate?: number | undefined) => {
            await chimeSdk.audioVideo?.startContentShareFromScreenCapture(sourceId, frameRate)
            chime.merge({ screensharing: true })
        },
        leaveRoom: leaveRoom,
        createRoom: async (
            externalMeetingId: string,
            currentAudioInputDevice: DeviceType | null,
            currentAudioOutputDevice: DeviceType | null,
            currentVideoInputDevice: DeviceType | null
        ) => {
            try {
                if (!loggedInUser.user()?.profileId) return
                if (
                    chime.name.get() !== externalMeetingId &&
                    chime.value.meetingStatus.meetingStatus === MeetingStatusCode.Succeeded
                ) {
                    await leaveRoom()
                }
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Loading })
                try {
                    await chimeSdk.createOrJoinRoom(loggedInUser.user()!.profileId, externalMeetingId, contactState)
                } catch (error) {
                    if ((error as BackendServiceError).httpStatus) {
                        // Custom error code from the backend.
                        if (error.httpStatus === 444) {
                            if (error.responseJson?.errorCode === "meetingFull") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.Full })
                                return
                            } else if (error.responseJson?.errorCode === "meetingTimeUp") {
                                setMeetingStatus({ meetingStatus: MeetingStatusCode.TimeUp })
                                return
                            }
                        } else if (error.httpStatus === 401) {
                            if (error.responseJson?.errorCode === "banned") {
                                setMeetingStatus({
                                    meetingStatus: MeetingStatusCode.Banned,
                                    errorMessage: error.responseJson?.errorMessage
                                })
                                return
                            } else if (error.responseJson?.errorCode === "channelIsLive") {
                                setMeetingStatus({
                                    meetingStatus: MeetingStatusCode.GreenroomLive,
                                    errorMessage: error.responseJson?.errorMessage
                                })
                                return
                            }
                        }
                        logger.error({ message: "ChimeContext Create or Join Room Failed", errorMessage: error.httpStatusText })
                        setMeetingStatus({
                            errorMessage: error.httpStatusText,
                            meetingStatus: MeetingStatusCode.Failed,
                            jsonErrorMessage: error.responseJson?.errorMessage
                        })
                        return
                    }
                }

                chimeSdk.audioVideo?.addObserver(observer)
                chimeSdk.audioVideo?.addContentShareObserver(contentShareObserver)
                if (currentAudioInputDevice) await chimeSdk.audioVideo?.chooseAudioInputDevice(currentAudioInputDevice.value)
                if (currentAudioOutputDevice) await chimeSdk.audioVideo?.chooseAudioOutputDevice(currentAudioOutputDevice.value)
                if (currentVideoInputDevice) {
                    chime.merge({ hasWebcam: true })
                    await chimeSdk.audioVideo?.chooseVideoInputDevice(currentVideoInputDevice.value)
                } else {
                    chime.merge({ hasWebcam: false })
                }
                await chimeSdk.joinRoom()
                chimeSdk.subscribeToRosterUpdate(rosterUpdate)
                chimeSdk.audioVideo?.realtimeSubscribeToMuteAndUnmuteLocalAudio(mutedUpdate)
                // Topic is validated with regex {a-zA-Z-0-9-_}{1,36} (e.g. meeting/attendee id are valid)
                chimeSdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(chimeSdk.attendeeId!, receiveAttendeeDataMessage)
                chimeSdk.audioVideo?.realtimeSubscribeToReceiveDataMessage(chimeSdk.meetingId!, receiveMeetingDataMessage)

                chime.merge({ name: externalMeetingId.substr(3), kind: getMeetingKindFromExternalMeetingId(externalMeetingId) })
                fetchChannelStatusForGreenRoom(chime.kind.get(), chime.name.get())
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Succeeded })
                toggleLocalVideoTile()

                startHeartbeat(externalMeetingId)
                startMeetingTimer(chimeSdk.meetingTimeLeft)
                if (chimeSdk.timeLimitChanged) {
                    const delay = 5000
                    // 5000 milliseconds wait, because we do not know yet how we can detect that the realtimeSend is ready to really send. Without this delay, the message will not go out currently
                    setTimeout(() => {
                        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                            type: DataMessageType.TIMELIMITCHANGED,
                            meetingTimeLeft: chimeSdk.meetingTimeLeft! - delay,
                            meetingMaxDuration: chimeSdk.meetingMaxDuration
                        })
                    }, delay)
                }

                // Recommend using "onbeforeunload" over "addEventListener"
                window.onbeforeunload = async (event: BeforeUnloadEvent) => {
                    // Prevent the window from closing immediately
                    // eslint-disable-next-line
                    event.returnValue = true
                }
            } catch (error) {
                // eslint-disable-next-line
                logger.error({
                    message: "Chime Context create room failed",
                    errorMessage: error.message,
                    errorStack: error.stack
                })
                setMeetingStatus({ meetingStatus: MeetingStatusCode.Failed, errorMessage: error.message })
            }
        },
        getLocalAttendeeId: () => {
            return chimeSdk.localAttendeeId
        },
        getRoster: () => {
            return rosterState.value
        },
        getAttendee: (attendeeId: string) => {
            return rosterState.value[attendeeId]
        },
        getNumAttendees: () => {
            return Object.keys(rosterState.get()).length
        },
        getMaxAttendees: () => {
            return chimeSdk.maxAttendees
        },
        hasMaxAttendees: () => {
            return Object.keys(rosterState.get()).length >= chimeSdk.maxAttendees
        },
        createOrJoinMeeting(name: string, kind?: MeetingKind) {
            if (kind) history.push(getUrlForMeeting(name, kind))
            else history.push(getUrlForMeetingFromExternalMeetingId(name))
        },
        gotoCurrentMeeting() {
            if (chimeSdk.externalMeetingId) history.push(getUrlForMeetingFromExternalMeetingId(chimeSdk.externalMeetingId))
        },
        modKick(attendeeId: string, reason: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.KICK, data: reason })
        },
        modBan(attendeeId: string, reason: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.BAN, data: reason })
        },
        modMute(attendeeId: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.MUTE })
        },
        modStopContentShare(attendeeId: string) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(attendeeId, { type: DataMessageType.STOP_SCREENSHARE })
        },
        raiseHand(attendeeId: string, raiseHand: boolean) {
            chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                type: DataMessageType.RAISEHAND,
                attendeeId: attendeeId,
                data: raiseHand
            })
            // Setting this values extra, because the sender does not receive his broadcasted message
            handleHandRaisedState(attendeeId, raiseHand)
        },
        setIsMeetingChangeAccepted: (accepted: boolean) => {
            chime.meetingChangeAccepted.merge(accepted)
        },
        getIsMeetingChangeAccepted: () => {
            return chime.meetingChangeAccepted.value
        },
        startLive() {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                handleChannelStatusState(ChannelStatus.PREPARING)
                chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                    type: DataMessageType.CHANNEL_STATUS,
                    data: ChannelStatus.PREPARING
                })
                startLive(channelId).then((response) => {
                    if ((response as BackendServiceError).httpStatus) {
                        handleChannelStatusState(ChannelStatus.OFF_AIR)
                        chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                            type: DataMessageType.CHANNEL_STATUS,
                            data: ChannelStatus.OFF_AIR
                        })
                    } else {
                        const channelResponse = response as ChannelResponse
                        const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                        const estimatedTimeUntilLive = branding.greenroomGoLiveFollowupDelaySec * 1000 // TODO use time to live value returned from backend on going live instead
                        setTimeout(() => {
                            handleChannelStatusState(newChannelStatus)
                            chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                                type: DataMessageType.CHANNEL_STATUS,
                                data: newChannelStatus
                            })
                        }, estimatedTimeUntilLive)
                    }
                })
            }
        },
        async stopLive(reason: StopReason = "default") {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await stopLive(channelId, reason ?? "default")
                if ((response as BackendServiceError).httpStatus) {
                    handleChannelStatusState(ChannelStatus.ON_AIR)
                    chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                        type: DataMessageType.CHANNEL_STATUS,
                        data: ChannelStatus.ON_AIR
                    })
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    const newChannelStatus = channelResponse.isLive ? ChannelStatus.ON_AIR : ChannelStatus.OFF_AIR
                    handleChannelStatusState(newChannelStatus)
                    chimeSdk.audioVideo?.realtimeSendDataMessage(chimeSdk.meetingId!, {
                        type: DataMessageType.CHANNEL_STATUS,
                        data: newChannelStatus
                    })
                    return true
                }
            }
            return false
        },
        async lockChannel(authorizedUsers: string[]) {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await lockChannel(channelId, authorizedUsers)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        async unlockChannel() {
            const channelId = chimeSdk.externalMeetingId?.substr(3)
            if (channelId) {
                const response = await unlockChannel(channelId)
                if ((response as BackendServiceError).httpStatus) {
                    return false
                } else {
                    const channelResponse = response as ChannelResponse
                    handleLockedState(channelResponse.isLocked)
                    return true
                }
            }
            return false
        },
        getChannelStatus() {
            return chime.value.channelStatus
        },
        isLocked() {
            return chime.value.isLocked
        }
    }
}

export const useChimeContext = () => useWrapState(useState(state))

export function getUrlForMeeting(name: string, kind: MeetingKind) {
    return getUrlForMeetingFromExternalMeetingId(getExternalMeetingId(name, kind))
}

function getUrlForMeetingFromExternalMeetingId(externalMeetingId: string) {
    return `/meeting/${externalMeetingId}/createorjoin`
}

export function getExternalMeetingId(name: string, kind: MeetingKind) {
    return getMeetingKindPrefix(kind) + name
}

export function getMeetingKindPrefix(kind: MeetingKind) {
    switch (kind) {
        case "call":
            return "cl_"
        case "showroom":
            return "sr_"
        case "virtualCafe":
            return "vc_"
        case "calenderEntry":
            return "ce_"
        case "greenroom":
            return "gr_"
        case "roundtable":
            return "rt_"
        case "breakout":
            return "br_"
        case "conferenceroom":
            return "cr_"
        default:
            return ""
    }
}

export function getMeetingKindFromExternalMeetingId(externalMeetingId: string) {
    const prefix = externalMeetingId.substr(0, externalMeetingId.indexOf("_"))
    let kind: MeetingKind = "virtualCafe"
    switch (prefix) {
        case "cl":
            kind = "call"
            break
        case "sr":
            kind = "showroom"
            break
        case "vc":
            kind = "virtualCafe"
            break
        case "ce":
            kind = "calenderEntry"
            break
        case "gr":
            kind = "greenroom"
            break
        case "rt":
            kind = "roundtable"
            break
        case "br":
            kind = "breakout"
            break
        case "cr":
            kind = "conferenceroom"
            break
    }
    return kind
}

const startHeartbeat = (externalMeetingId: string) => {
    const meetingKind = getMeetingKindFromExternalMeetingId(externalMeetingId)
    let hearbeatEventCount: number = 0

    heartbeatIntervall = window.setInterval(() => {
        hearbeatEventCount = hearbeatEventCount + branding.gtmFairHeartbeatEventMinutes
        trackMeetingHeartbeatEvent(meetingKind, hearbeatEventCount)
    }, branding.gtmFairHeartbeatEventMinutes * 60 * 1000)
}

const stopHeartbeat = () => {
    window.clearInterval(heartbeatIntervall)
}
